// Importación de componentes de Material-UI para la paginación y la disposición en pila
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

// Importación de React y hooks necesarios
import React, { useContext, useEffect } from "react";

// Importación de interfaces para tipado de metadatos
import { IMeta } from "../../Interface/ApiInterface";

// Importación de contextos y funciones de validación
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { validateIdPeriod } from "../../context/validacionesFiltros";

// Importación de estilos personalizados para la paginación
import { ContainPagination } from "../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";

// Importación de hooks personalizados para manejar colaboradores
import { useCollaboratorRedux } from "../../redux/Collaborators";

// import { styled } from '@mui/material/styles'; // Comentado: Posible importación para estilos personalizados

// Definición de la interfaz para las propiedades del componente PaginationTableCollabs
interface IPagination {
  table?: string; // Nombre de la tabla a paginar
  Meta?: IMeta; // Metadatos de la paginación, como total de páginas
  evaluation_period_id?: any; // ID del período de evaluación
  setCurrentPage?: any; // Función para establecer la página actual
  setQueryPeticionWithFilters?: any; // Función para establecer la consulta con filtros aplicados
}

// Componente PaginationTableCollabs que maneja la paginación para colaboradores
const PaginationTableCollabs = ({
  table,
  Meta,
  evaluation_period_id,
  setCurrentPage,
  setQueryPeticionWithFilters,
}: IPagination) => {
  // Uso del hook personalizado para acceder a filtros, usuario y función para obtener colaboradores
  const { Filters, User, handleGetCollaborators } = useCollaboratorRedux();

  // Uso del contexto de evaluación para manejar el estado de carga de la paginación
  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  // Obtención de la página almacenada en localStorage
  const pageStorage = localStorage.getItem("pageLocalStorage");
  const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

  const { page: pageStore } = pageStorageParsed; // Extracción de la página actual desde el almacenamiento

  // useEffect para inicializar la página de paginación en localStorage si no existe
  useEffect(() => {
    if (!pageStore) {
      const pagePagination = { page: 1 }; // Establece la página inicial en 1

      localStorage.setItem("pageLocalStorage", JSON.stringify(pagePagination)); // Guarda la página inicial en localStorage
    }
  }, []); // Se ejecuta solo una vez al montar el componente

  /**
   * Función que maneja el cambio de página en la paginación
   * @param {React.ChangeEvent<unknown>} event - Evento de cambio de página
   * @param {number} page - Número de la página seleccionada
   */
  const handleChangePaginate = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault(); // Previene el comportamiento por defecto del evento
    let query = `?`; // Inicializa la cadena de consulta

    switch (table) { // Determina la lógica de paginación según la tabla seleccionada
      case "Collaboratos":
        if (Filters) { // Verifica si existen filtros aplicados
          const pagePagination = { page: page }; // Crea un objeto con la página actual

          // Guarda la página actual en localStorage
          localStorage.setItem(
            "pageLocalStorage",
            JSON.stringify(pagePagination)
          );
          const pageStorage = localStorage.getItem("pageLocalStorage"); // Obtiene la página almacenada

          const queryParts: string[] = []; // Arreglo para almacenar partes de la consulta

          // Construcción de la consulta basada en los filtros aplicados
          for (const key in Filters) {
            if (Filters[key] !== undefined && Filters[key] !== "") {
              // Si el filtro es de tipo porcentaje gráfico, lo ajusta
              if (
                key === "Mas95Porcent" ||
                key === "Menos50Porcent" ||
                key === "TienePoliticPorcent" ||
                key === "SinEvaluacionPorcent"
              ) {
                queryParts.push(`GraphicFilter.${key}=${true}`);
              } else {
                queryParts.push(`${key}=${Filters[key]}`); // Agrega el filtro normal
              }
            }
          }

          let queryFilters = queryParts.join("&"); // Une los filtros con '&'

          const periodo = validateIdPeriod(evaluation_period_id); // Valida el ID del período de evaluación

          // Verifica si hay una página almacenada en localStorage
          if (pageStorage !== null) {
            const pageStorageParsed = JSON.parse(pageStorage);

            const { page: pageStore } = pageStorageParsed; // Obtiene la página almacenada

            // Construye la consulta completa con filtros, usuario, rol y paginación
            query += `${queryFilters}&NombreColaborador=${
              User.nombre
            }&RolColaborador=${
              User.role === "Auditor" ? "Admin" : User.role
            }&IdPeriod=${periodo}&PageSize=10&PageNumber=${pageStore}`;

            // Establece la consulta con filtros en el estado
            setQueryPeticionWithFilters(
              `${queryFilters}&NombreColaborador=${
                User.nombre
              }&RolColaborador=${
                User.role === "Auditor" ? "Admin" : User.role
              }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
            );

            handleGetCollaborators(query); // Realiza la petición para obtener colaboradores con la consulta construida
            setLoadingPagination(true); // Establece el estado de carga de la paginación
          } else {
            // Si no hay página almacenada, usa la página seleccionada
            query += `${queryFilters}&NombreColaborador=${
              User.nombre
            }&RolColaborador=${
              User.role === "Auditor" ? "Admin" : User.role
            }&IdPeriod=${periodo}&PageSize=10&PageNumber=${page}`;

            // Establece la consulta con filtros en el estado
            setQueryPeticionWithFilters(
              `${queryFilters}&NombreColaborador=${
                User.nombre
              }&RolColaborador=${
                User.role === "Auditor" ? "Admin" : User.role
              }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
            );

            handleGetCollaborators(query); // Realiza la petición para obtener colaboradores con la consulta construida
            setLoadingPagination(true); // Establece el estado de carga de la paginación
          }
        }

        break;

      // case "OtraTabla":
      //   // Lógica para otra tabla si es necesario
      //   break;

      default:
        return; // Sal del switch si la tabla no coincide con ningún caso
    }
  };

  return (
    <ContainPagination> {/* Contenedor estilizado para la paginación */}
      <Stack spacing={2}> {/* Componente de pila de Material-UI con espacio entre elementos */}
        <Pagination
          onChange={(e, page) => { // Maneja el cambio de página
            handleChangePaginate(e, page); // Llama a la función para manejar la paginación
            if (table === "Collaboratos") { // Si la tabla es "Collaboratos", establece el estado de carga
              setLoadingPagination(true);
            }
          }}
          count={Meta?.totalPages} // Número total de páginas basado en los metadatos
          color="primary" // Color primario para los componentes de paginación
          page={
            pageStore
              ? pageStore // Usa la página almacenada si existe
              : Meta?.currentPage
              ? Meta?.currentPage // Usa la página actual de los metadatos si existe
              : 1 // Por defecto, la página es 1
          }
        />
      </Stack>
    </ContainPagination>
  ); // Finaliza el renderizado del componente
};

export default PaginationTableCollabs; // Exporta el componente PaginationTableCollabs para su uso en otras partes de la aplicación
