import React, { useContext, useEffect, useState } from "react";
import {
  FiltersDashboardContext,
  FiltersDashboardType,
} from "../../../../context/ManageFiltersDashboard/FiltersDashboard";
import {
  validateIdPeriod,
  validateSemestre,
} from "../../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { getDataChartsPie } from "../../../../services/dataSelects.service";
import {
  ButtonFilterDashboard,
  FilterWrapper,
  SelectLabelDashboard,
  SelectPeriodDashboard,
} from "./FilterDashboardStyles";
import { useLoader } from "../../../../context/LoaderContext";

interface NewFilterDashboardProps {
  setSemestre: React.Dispatch<React.SetStateAction<string>>;
  handleSelectPeriod: (period: string) => void;
  setEvaluation_period_id: React.Dispatch<React.SetStateAction<string>>;
  setDataGraph: React.Dispatch<React.SetStateAction<any[]>>;
  dataGraph: any[];
  setIsSelectLocalidadUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectLocalidadUpdated: boolean;
  propLocation: string;
  propDirector: string;
  setPropLocation: React.Dispatch<React.SetStateAction<string>>;
  setPropDirector: React.Dispatch<React.SetStateAction<string>>;
  setDataRestante: React.Dispatch<React.SetStateAction<any[]>>;
  fetchData: (userName: string, periodId: string) => Promise<void>;
}

const titleDonuts = [
  "Calificación promedio de todos los trabajadores",
  "Colaboradores con calificación superior al 95%",
  "Colaboradores con calificación Inferior al 50%",
  "Colaboradores que han presentado su evaluación de políticas",
  "Colaboradores con evaluación sin concluir",
  "Colaboradores que se han dado de baja",
];

const NewFilterDashboard: React.FC<NewFilterDashboardProps> = ({
  setSemestre,
  handleSelectPeriod,
  setEvaluation_period_id,
  setDataGraph,
  dataGraph,
  setIsSelectLocalidadUpdated,
  isSelectLocalidadUpdated,
  propLocation,
  propDirector,
  setPropLocation,
  setPropDirector,
  setDataRestante,
  fetchData,
}) => {
  //** Context
  const {
    onChangeOptionYear,
    onChangeOptionSemestre,
    selectedOptionPeriod,
    selectedOptionYear,
    years,
    semesterOptions,
    getYearsOptions,
    setSelectedOptionYear,
    setSelectedOptionPeriod,
    getPeriods,
    locations,
    directions,
    getOptionsDirections,
    getOptionisLocations,
    getOnlyYears,
    getOnlyPeriods,
  } = useContext(FiltersDashboardContext) as FiltersDashboardType;

  const { incrementRequests, decrementRequests } = useLoader();
  const { User, evaluationAttribute } = useCollaboratorRedux();

  const [loaderFilters, setLoaderFilters] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  /**
   * handlePaintData: llama a getDataChartsPie con un número en vez de string
   */
  const handlePaintData = async (periodValidate: string) => {
    try {
      incrementRequests();
      const numericPeriod = Number(periodValidate); // <-- conversión

      let requestData;
      if (propLocation !== "default" && propDirector !== "default") {
        requestData = await getDataChartsPie(
          numericPeriod, // numeric
          User.claTrab,
          propLocation,
          propDirector
        );
      } else {
        requestData = await getDataChartsPie(numericPeriod, User.claTrab);
      }

      const result = requestData.data.map((data: any, index: number) => ({
        title: titleDonuts[index],
        value: data.compleado,
      }));
      setDataGraph(result);

      const rest = requestData.data.map((data: any, index: number) => ({
        title: titleDonuts[index],
        value: data.restante,
      }));
      setDataRestante(rest);

    } catch (error) {
      console.error("Error fetching data for donut chart:", error);
    } finally {
      decrementRequests();
      setIsSelectLocalidadUpdated(true);
    }
  };

  /**
   * handleSearchPeriod: actualiza localStorage y llama fetchData + handlePaintData
   */
  const handleSearchPeriod = () => {
    const periodId = localStorage.getItem("periodId");
    if (!selectedOptionPeriod && periodId) {
      const periodIdParsed = JSON.parse(periodId);
      if (periodIdParsed.valuePeriod) {
        setSelectedOptionPeriod(periodIdParsed.valuePeriod);
      }
    }

    const periodValidate = validateIdPeriod(selectedOptionPeriod);
    const semestreVal = validateSemestre(selectedOptionPeriod);
    setSemestre(semestreVal);

    localStorage.setItem(
      "periodId",
      JSON.stringify({ valuePeriod: selectedOptionPeriod })
    );

    const obj = {
      year: selectedOptionYear,
      periodo: periodValidate,
      propLocation: propLocation || "default",
      propDirector: propDirector || "default",
    };
    localStorage.setItem("principalFilters", JSON.stringify(obj));

    setEvaluation_period_id(periodValidate);
    handleSelectPeriod(periodValidate);

    fetchData(User.nombre, periodValidate).then(() => {
      handlePaintData(periodValidate);
    });
  };

  /**
   * handleCleanPeriod: Reset al periodo default del User
   */
  const handleCleanPeriod = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    fetchData(User.nombre, User.periodInfo.idPeriod);
    setSelectedOptionYear(User?.periodInfo?.year);
    getPeriods(User?.periodInfo?.year, true);

    setDataGraph([{ title: "", value: "" }]);
    setIsSelectLocalidadUpdated(false);

    setPropLocation("default");
    setPropDirector("default");

    setEvaluation_period_id(
      `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
    );

    const obj = {
      year: User?.periodInfo?.year,
      periodo: User?.periodInfo?.idPeriod,
      propLocation: "default",
      propDirector: "default",
    };
    localStorage.setItem("principalFilters", JSON.stringify(obj));

    const periodIdData = {
      valuePeriod: `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`,
    };
    localStorage.setItem("periodId", JSON.stringify(periodIdData));
  };

  /**
   * Efecto #1: Al montar
   */
  useEffect(() => {
    setLoaderFilters(false);
    getOnlyYears();

    const filtrosAlmacenados = localStorage.getItem("principalFilters");
    const periodId = localStorage.getItem("periodId");

    if (filtrosAlmacenados && periodId) {
      const filtros = JSON.parse(filtrosAlmacenados);
      const periodParsed = JSON.parse(periodId);

      const { valuePeriod } = periodParsed;
      const { year, periodo } = filtros;

      if (year && periodo && valuePeriod) {
        setSelectedOptionYear(year);
        getOnlyPeriods(year);
        fetchData(User.nombre, periodo);
        handleSelectPeriod(periodo);
        setSemestre(periodo);

        setSelectedOptionPeriod(valuePeriod);
        setEvaluation_period_id(valuePeriod);
      }
    } else {
      getYearsOptions();
    }

    setTimeout(() => {
      setLoaderFilters(true);
    }, 1000);
  }, []);

  /**
   * Efecto #2: al cambiar year, period, loc/direct => autoSearch
   */
  useEffect(() => {
    if (selectedOptionYear && selectedOptionPeriod && !isInitialLoad) {
      handleSearchPeriod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionYear, selectedOptionPeriod, propLocation, propDirector]);

  /**
   * Efecto #3: set defaults la primera vez
   */
  useEffect(() => {
    if (years.length > 0 && isInitialLoad) {
      const savedYear = localStorage.getItem("selectedYear");
      let defaultYear: number;

      if (savedYear) {
        defaultYear = parseInt(savedYear, 10);
      } else {
        const sortedYears = [...years].sort((a, b) => b.year - a.year);
        defaultYear = sortedYears[0]?.year;
      }

      setSelectedOptionYear(defaultYear);
      onChangeOptionYear({ target: { value: defaultYear } });

      if (semesterOptions.length > 0) {
        const firstPeriod = semesterOptions[0].evaluationPeriodId;
        const semNumber = semesterOptions[0].evaluationPeriodNumberOfYear;
        const combined = `${firstPeriod},${semNumber}`;
        setSelectedOptionPeriod(combined);
        onChangeOptionSemestre({ target: { value: combined } });
      }
      setIsInitialLoad(false);
    }
  }, [years, semesterOptions, isInitialLoad]);

  /**
   * Efecto #4: guarda el año en localStorage
   */
  useEffect(() => {
    if (selectedOptionYear) {
      localStorage.setItem("selectedYear", selectedOptionYear.toString());
    }
  }, [selectedOptionYear]);

  /**
   * Efecto #5: guarda el periodo en localStorage
   */
  useEffect(() => {
    if (selectedOptionPeriod) {
      localStorage.setItem("selectedPeriod", selectedOptionPeriod.toString());
    }
  }, [selectedOptionPeriod]);

  return (
    <div style={{ paddingBottom: "24px", borderBottom: "2px solid #F0F0F0" }}>
      <FilterWrapper>
        <div className="labelBoxTab">Filtros</div>
        <div className="containerSelectsAndBtns">
          {loaderFilters ? (
            <div className="onlySelects">
              <SelectLabelDashboard>
                <div className="containerTxt">Año</div>
                <SelectPeriodDashboard
                  value={selectedOptionYear}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    onChangeOptionYear(e);
                  }}
                  name="year"
                >
                  {years.map(({ year }: any) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </SelectPeriodDashboard>
              </SelectLabelDashboard>

              <SelectLabelDashboard id="semester">
                <div className="containerTxt">Semestre</div>
                <SelectPeriodDashboard
                  value={selectedOptionPeriod}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    onChangeOptionSemestre(e)
                  }
                  name="semester"
                >
                  {semesterOptions.map((periodName: any) => (
                    <option
                      key={periodName.evaluationPeriodId}
                      value={`${periodName.evaluationPeriodId},${periodName.evaluationPeriodNumberOfYear}`}
                    >
                      {periodName.evaluationPeriodNumberOfYear}
                    </option>
                  ))}
                </SelectPeriodDashboard>
              </SelectLabelDashboard>

              {evaluationAttribute.kpis_evaluation_politics === 1 &&
                evaluationAttribute.kpis_evaluation === 1 && (
                  <>
                    <SelectLabelDashboard>
                      <div className="containerTxt">Localidad</div>
                      <SelectPeriodDashboard
                        value={propLocation}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setPropLocation(e.target.value)
                        }
                        name="localidad"
                      >
                        <option style={{ color: "black" }} value="default">
                          Selecciona
                        </option>
                        {locations.map((data: any) => (
                          <option
                            style={{ color: "black" }}
                            key={data}
                            value={data}
                          >
                            {data}
                          </option>
                        ))}
                      </SelectPeriodDashboard>
                    </SelectLabelDashboard>

                    <SelectLabelDashboard>
                      <div className="containerTxt">Director</div>
                      <SelectPeriodDashboard
                        value={propDirector}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setPropDirector(e.target.value)
                        }
                        name="director"
                      >
                        <option style={{ color: "black" }} value="default">
                          Selecciona
                        </option>
                        {directions.map((data: any) => (
                          <option
                            style={{ color: "black" }}
                            key={data}
                            value={data}
                          >
                            {data}
                          </option>
                        ))}
                      </SelectPeriodDashboard>
                    </SelectLabelDashboard>
                  </>
                )}
            </div>
          ) : (
            <>Cargando</>
          )}
          <div className="onlyBtns">
            <ButtonFilterDashboard
              onClick={handleCleanPeriod}
              colorBg="#C9F2FF"
              colorFont="#3B63A2"
            >
              Limpiar
            </ButtonFilterDashboard>
            <ButtonFilterDashboard onClick={handleSearchPeriod} colorFont="#FFF">
              Buscar
            </ButtonFilterDashboard>
          </div>
        </div>
      </FilterWrapper>
    </div>
  );
};

export default NewFilterDashboard;
