import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useContext } from "react";
import { IMeta } from "../../Interface/ApiInterface";
import { EvaluationContext, EvaluationContextType } from "../../context/EvaluationContext";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import { ContainPagination } from "../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useObjetives } from "../../redux/Objetives";
import { usePeriods } from "../../redux/Periods";
import { usePolitics } from "../../redux/Politics";
import { useQuestions } from "../../redux/Questionnaires";
// import { styled } from '@mui/material/styles';

interface IPagination {
  table?: string;
  Meta?: IMeta;
  evaluation_period_id?: any;
  setCurrentPage?: any;
  setQueryPeticionWithFilters?: any;
  setQueryFilter?: any;
}
// const useStyles = makeStyles((theme: any) => ({
//   customPagination: {
//     '& .MuiPaginationItem-root': {
//       color: 'red', // Cambia el color de las flechas según tus preferencias
//     },
//   },
// }));
const PaginationTable = ({
  table,
  Meta,
  evaluation_period_id,
  setCurrentPage,
  setQueryPeticionWithFilters,
  setQueryFilter
}: IPagination) => {
  // Uso del hook personalizado para acceder al estado de Redux relacionado con colaboradores
  const { Filters, User, handleGetCollaborators } = useCollaboratorRedux();

  // Uso de hooks personalizados para manejar objetivos generales e individuales
  const {
    FilterGeneral,
    FilterIndividual,
    handleGetAllGeneralObjetives,
    handleGetAllIndividualObjetives,
  } = useObjetives();

  // Uso de hook personalizado para manejar periodos
  const { handleGetAllPeriods } = usePeriods();

  // Uso de hook personalizado para manejar cuestionarios
  const { handleGetAllQuestions } = useQuestions();

  // Uso de hook personalizado para manejar políticas
  const { handleGetAllPoliticsFilter, FilterPolitic } = usePolitics();

  // Acceso al contexto de evaluaciones para manejar el estado de carga de la paginación
  const { setLoadingPagination } = useContext(EvaluationContext) as EvaluationContextType;

  // console.log("Se cambian los filtros -> ", Filters); // Comentado: Depuración de filtros
  // console.log(User); // Comentado: Depuración del usuario
  // console.log({evaluationAttribute}); // Comentado: Depuración de atributos de evaluación

  const handleChangePaginate = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault(); // Previene el comportamiento por defecto del evento
    let query = `?`; // Inicializa la consulta para colaboradores
    let queryObjetivosIndividuales = `?`; // Inicializa la consulta para objetivos individuales
    let query2 = `?`; // Inicializa la consulta para políticas

    switch (table) { // Maneja diferentes tablas según el valor de 'table'
      case "Collaboratos":
        if (Filters) { // Verifica si hay filtros aplicados
          const queryParts: string[] = []; // Arreglo para almacenar partes de la consulta

          // Itera sobre los filtros y construye la consulta
          for (const key in Filters) {
            if (Filters[key] !== undefined && Filters[key] !== "") {
              if (key === "Mas95Porcent" || key === "Menos50Porcent" || key === "TienePoliticPorcent" || key === "SinEvaluacionPorcent") {
                queryParts.push(`GraphicFilter.${key}=${true}`);
              } else {
                queryParts.push(`${key}=${Filters[key]}`);
              }
            }
          }

          let queryFilters = queryParts.join('&'); // Une las partes de la consulta con '&'

          // console.log("Esto se enviará ->", queryFilters); // Comentado: Depuración de la consulta filtrada
          const periodo = validateIdPeriod(evaluation_period_id); // Valida el ID del periodo de evaluación

          // Construye la consulta completa con filtros y paginación
          query += `${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=${page}`;

          // console.log("query petición", `${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=1`); // Comentado: Depuración de la consulta completa

          // Establece la consulta con filtros en el estado
          setQueryPeticionWithFilters(`${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=1`);

          handleGetCollaborators(query); // Realiza la petición para obtener colaboradores con la consulta construida
          setLoadingPagination(true); // Establece el estado de carga de la paginación
        }
        break;

      case "Periods":
        query += `PageSize=10&PageNumber=${page}`; // Construye la consulta para periodos
        handleGetAllPeriods(query); // Realiza la petición para obtener periodos
        break;

      case "Cuestionario":
        query += `PageSize=10&PageNumber=${page}`; // Construye la consulta para cuestionarios
        handleGetAllQuestions(query); // Realiza la petición para obtener cuestionarios
        break;

      case "Generales":
        const periodoG = validateIdPeriod(evaluation_period_id); // Valida el ID del periodo de evaluación para objetivos generales

        // Construye la consulta para objetivos generales según los filtros aplicados
        if (FilterGeneral.verbFilterGeneral !== undefined) {
          query += `&GeneralObjetivesInfinitiveVerb=${FilterGeneral.verbFilterGeneral}`;
        }
        if (FilterGeneral.KPIFilterGeneral !== undefined) {
          query += `&GeneralObjetivesKPI=${FilterGeneral.KPIFilterGeneral}`;
        }
        if (FilterGeneral.mathFilterGeneral !== undefined) {
          query += `&GeneralObjetviesMathExpression=${FilterGeneral.mathFilterGeneral}`;
        }
        if (FilterGeneral.prepositionFilterGeneral !== undefined) {
          query += `&GeneralObjetivesPreposition=${FilterGeneral.prepositionFilterGeneral}`;
        }
        if (FilterGeneral.indicatorFilterGeneral !== undefined) {
          query += `&GeneralObjetivesQuantityIndicator=${FilterGeneral.indicatorFilterGeneral}`;
        }
        if (FilterGeneral.adverbFilterGeneral !== undefined) {
          query += `&GeneralObjetivesAdverb=${FilterGeneral.adverbFilterGeneral}`;
        }
        if (FilterGeneral.dateFilterGeneral !== undefined) {
          query += `&GeneralObjetivesDate=${FilterGeneral.dateFilterGeneral}`;
        }
        if (FilterGeneral.yearFilterGeneral !== undefined) {
          query += `&GeneralObjetivesYear=${FilterGeneral.yearFilterGeneral}`;
        }
        if (FilterGeneral.semesterFilterGeneral !== undefined) {
          query += `&GeneralObjetivesSemester=${FilterGeneral.semesterFilterGeneral}`;
        }
        if (FilterGeneral.locationFilterGeneral !== undefined) {
          query += `&Locations=${FilterGeneral.locationFilterGeneral}`;
        }
        if (FilterGeneral.departmentFilterGeneral !== undefined) {
          query += `&Department=${FilterGeneral.departmentFilterGeneral}`;
        }
        if (FilterGeneral.areaFilterGeneral !== undefined) {
          query += `&Area=${FilterGeneral.areaFilterGeneral}`;
        }
        if (FilterGeneral.companyFilterGeneral !== undefined) {
          query += `&Company=${FilterGeneral.companyFilterGeneral}`;
        }

        // Agrega el ID del periodo y la paginación a la consulta
        query += `&IdPeriod=${periodoG}&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${page}`;
        handleGetAllGeneralObjetives(query); // Realiza la petición para obtener objetivos generales
        break;

      case "Individuales":
        const periodoI = validateIdPeriod(evaluation_period_id); // Valida el ID del periodo de evaluación para objetivos individuales

        // Construye la consulta para objetivos individuales según los filtros aplicados
        if (FilterIndividual.verbFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesInfinitiveVerb=${FilterIndividual.verbFilterIndividual}`;
        }
        if (FilterIndividual.KPIFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesKPI=${FilterIndividual.KPIFilterIndividual}`;
        }
        if (FilterIndividual.mathFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetviesMathExpression=${FilterIndividual.mathFilterIndividual}`;
        }
        if (FilterIndividual.prepositionFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesPreposition=${FilterIndividual.prepositionFilterIndividual}`;
        }
        if (FilterIndividual.indicatorFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesQuantityIndicator=${FilterIndividual.indicatorFilterIndividual}`;
        }
        if (FilterIndividual.adverbFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesAdverb=${FilterIndividual.adverbFilterIndividual}`;
        }
        if (FilterIndividual.dateFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesDate=${FilterIndividual.dateFilterIndividual}`;
        }
        if (FilterIndividual.yearFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesYear=${FilterIndividual.yearFilterIndividual}`;
        }
        if (FilterIndividual.semesterFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesSemester=${FilterIndividual.semesterFilterIndividual}`;
        }

        // Agrega el ID del periodo y la paginación a la consulta
        queryObjetivosIndividuales += `&IdPeriod=${periodoI}&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${page}`;
        handleGetAllIndividualObjetives(queryObjetivosIndividuales); // Realiza la petición para obtener objetivos individuales
        break;

      case "Politicas":
        const periodoP = validateIdPeriod(evaluation_period_id); // Valida el ID del periodo de evaluación para políticas

        // Construye la consulta para políticas según los filtros aplicados
        if (FilterPolitic.nombre !== undefined && FilterPolitic.nombre) {
          if (query2 !== "") {
            query2 += `Nombre=${FilterPolitic.nombre}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Nombre=${FilterPolitic.nombre}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.puesto !== undefined && FilterPolitic.puesto) {
          if (query2 !== "") {
            query2 += `Puesto=${FilterPolitic.puesto}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Puesto=${FilterPolitic.puesto}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.area !== undefined && FilterPolitic.area) {
          if (query2 !== "") {
            query2 += `Area=${FilterPolitic.area}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Area=${FilterPolitic.area}`;
            setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.departamento !== undefined &&
          FilterPolitic.departamento
        ) {
          if (query2 !== "") {
            query2 += `Departamento=${FilterPolitic.departamento.trim()}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Departamento=${FilterPolitic.departamento.trim()}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.fechaNac !== undefined && FilterPolitic.fechaNac) {
          if (query2 !== "") {
            query2 += `FechaNac=${FilterPolitic.fechaNac}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&FechaNac=${FilterPolitic.fechaNac}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.compania !== undefined && FilterPolitic.compania) {
          if (query2 !== "") {
            query2 += `Compania=${FilterPolitic.compania}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Compania=${FilterPolitic.compania}`;
            setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.clasificacion !== undefined &&
          FilterPolitic.clasificacion
        ) {
          if (query2 !== "") {
            query2 += `Clasificacion=${FilterPolitic.clasificacion}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Clasificacion=${FilterPolitic.clasificacion}`;
            setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.estatusPolicies !== undefined &&
          FilterPolitic.estatusPolicies
        ) {
          if (query2 !== "") {
            query2 += `EstatusPolicies=${FilterPolitic.estatusPolicies}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&EstatusPolicies=${FilterPolitic.estatusPolicies}`;
            setQueryFilter(query2);
          }
        }

        // Agrega el ID del periodo y la paginación a la consulta
        query2 += `IdPeriod=${periodoP}&PageSize=10&PageNumber=${page}`;
        setQueryFilter(query2); // Establece la consulta filtrada en el estado
        handleGetAllPoliticsFilter(query2); // Realiza la petición para obtener políticas con la consulta construida
        break;

      default:
        return; // Sal del switch si la tabla no coincide con ninguno de los casos
    }
  };

  return (
    <ContainPagination> {/* Contenedor estilizado para la paginación */}
      <Stack spacing={2}> {/* Componente de pila de Material-UI con espacio entre elementos */}
        <Pagination
          onChange={(e, page) => { // Maneja el cambio de página
            handleChangePaginate(e, page); // Llama a la función para manejar la paginación
            if (table === "Collaboratos") { // Si la tabla es "Collaboratos", establece el estado de carga
              setLoadingPagination(true);
            }
          }}
          count={Meta?.totalPages} // Número total de páginas basado en los metadatos
          color="primary" // Color primario para los componentes de paginación
          page={Meta?.currentPage ?? 1} // Página actual, por defecto 1 si no está definida
        />
      </Stack>
    </ContainPagination>
  ); // Finaliza el renderizado del componente
};

export default PaginationTable; // Exporta el componente PaginationTable para su uso en otras partes de la aplicación
