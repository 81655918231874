// Importación de styled-components para estilizar componentes de React
import styled from "styled-components";

// Definición de tipos para las propiedades del componente
type PropTypeBgTres = {
  activeBorder?: any; // Propiedad opcional para activar el borde
};

// Componente estilizado para representar una tarjeta de objeto actual
export const CardCurrentObj = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  background-color: #f8f8f8; /* Color de fondo gris claro */
  /* color: #ffffff; */ /* Color de texto blanco (comentado) */
  /* border-radius: 4px; */ /* Bordes redondeados de 4px (comentado) */
  padding: 14px 8px; /* Padding de 14px arriba/abajo y 8px izquierda/derecha */
  display: flex; /* Utiliza flexbox para la disposición de los elementos */

  margin-bottom: 2px; /* Margen inferior de 2px */
  align-items: center; /* Alinea verticalmente los elementos al centro */
  justify-content: center; /* Alinea horizontalmente los elementos al centro */
  border: ${(props) => (props.activeBorder ? "1px solid red" : "none")}; /* Aplica un borde rojo si activeBorder es verdadero */

  /* Estilos para la primera columna interna */
  .first {
    width: 8%; /* Ancho del 8% */
    display: flex; /* Utiliza flexbox */
    align-items: center; /* Alinea verticalmente al centro */
    justify-content: center; /* Alinea horizontalmente al centro */
    /* border: 1px solid red; */ /* Borde rojo (comentado) para depuración */
  }

  /* Estilos para la segunda columna interna */
  .second {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Estilos para la tercera columna interna */
  .third {
    width: 37%; /* Ancho del 37% */
    display: flex;
    align-items: center;
    margin-left: 12px; /* Margen izquierdo de 12px */
    /* justify-content: center; */ /* Justificación al centro (comentado) */
    padding: 0px 10px; /* Padding horizontal de 10px */
    /* border: 1px solid white; */ /* Borde blanco (comentado) */
  }

  /* Estilos para la cuarta columna interna */
  .fourth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 60px; /* Altura fija de 60px */
    margin: 0px auto; /* Márgenes automáticos a los lados para centrar horizontalmente */
    /* border: 1px solid red; */ /* Borde rojo (comentado) */

    /* Estilos para elementos anidados dentro de .fourth */
    .cmXlkM .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100%; /* Ancho completo del elemento */
      /* outline: none; */ /* Sin contorno (comentado) */
    }

    /* Estilos para elementos de formulario anidados dentro de .fourth */
    .css-1v1n4gf-MuiFormControl-root-MuiTextField-root {
      width: 100%; /* Ancho completo del elemento */
      margin: 0px auto; /* Márgenes automáticos a los lados para centrar */
      /* border: 1px solid red; */ /* Borde rojo (comentado) */
      display: flex; /* Utiliza flexbox */
      align-items: center; /* Alinea verticalmente al centro */
      justify-content: center; /* Alinea horizontalmente al centro */
      align-content: center; /* Alinea el contenido al centro */
    }
  }

  /* Estilos para la quinta columna interna */
  .fifth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0px auto; /* Márgenes automáticos a los lados para centrar */

    /* Padding comentado */
    /* padding: 0px 20px; */
    /* padding-left: 10px; */

    /* Estilos para elementos anidados dentro de .fifth */
    .cmXlkM .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100%; /* Ancho completo del elemento */
      /* outline: none; */ /* Sin contorno (comentado) */
    }

    /* Estilos para elementos de formulario anidados dentro de .fifth */
    .css-1v1n4gf-MuiFormControl-root-MuiTextField-root {
      width: 100%; /* Ancho completo del elemento */
      margin: 0px auto; /* Márgenes automáticos a los lados para centrar */
      /* border: 1px solid red; */ /* Borde rojo (comentado) */
      display: flex; /* Utiliza flexbox */
      align-items: center; /* Alinea verticalmente al centro */
      justify-content: center; /* Alinea horizontalmente al centro */
      align-content: center; /* Alinea el contenido al centro */
    }

    /* Bordes comentados para depuración */
    /* border: 1px solid red; */
    /* border: 1px solid red; */
  }

  /* Estilos para la sexta columna interna */
  .sixth {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Estilos para la séptima columna interna */
  .seventh {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
