// src/components/organisms/EvaluationForm/components/FiltroPeriod.tsx

import React, { useContext, useEffect, useRef, useState, ChangeEvent } from "react";

/** AJUSTE #1: rutas corregidas (modifica si tu estructura es distinta) */
import { getCurrentYearAndSemesterObj } from "../../../../hooks/Evaluation/useSemesterYearNow";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { validateSemestre, validateIdPeriod } from "../../../../context/validacionesFiltros";

/** Si NO usas getPeriodYearsSemesters, getPeriodsByYear en este archivo, eliminalos
    Si sí los necesitas, ajusta la ruta:
import {
  getPeriodYearsSemesters,
  getPeriodsByYear
} from "../../../../services/dataSelects.service";
*/

// Resto de importaciones
import { EvaluationContext, EvaluationContextType } from "../../../../context/EvaluationContext";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";

import { useEvaluationRedux } from "../../../../redux/Evaluations";
import {
  BtnPeriodClean,
  BtnPeriodSearch,
  ContainerFiltros,
  HeaderFiltrosBody,
} from "../styles/FiltroPeriod.styles";
import SelectCustom from "./SelectCustom";
import { useLoader } from "../../../../context/LoaderContext";

/** Interfaz (opcional) si no necesitas children, hazla vacía */
interface FiltroPeriodProps {}

interface YearOption {
  year: number;
}
interface PeriodName {
  evaluationPeriodId: number;
  evaluationPeriodNumberOfYear: number;
}

// Ejemplo de obtención “año/semestre actual”
const { semestre, year } = getCurrentYearAndSemesterObj();

const FiltroPeriod: React.FC<FiltroPeriodProps> = () => {
  // Context principal de Evaluación
  const { changePage } = useContext(EvaluationContext) as EvaluationContextType;

  // Context de “AutoEvaluation”
  const {
    onChangeOptionYear,
    onChangeOptionSemestre,
    selectedOptionPeriod,
    selectedOptionYear,
    years,
    evaluationPeriodName,
    getYearsOptions,
    setSelectedOptionYear,
    periodAutoEvaluation,
    handleValidatePeriod,
    setSelectedOptionPeriod,
    getPeriods,
    setRealizedSearch,
    realizedSearch,
    setPeriodoDataInfo,
    idActualPeriod,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;

  // Redux
  const { User } = useCollaboratorRedux();
  const { handleGetEvaluation, Evaluation } = useEvaluationRedux();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { incrementRequests, decrementRequests } = useLoader();
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  /** handleSearchPeriod: se llama al pulsar “Buscar” */
  const handleSearchPeriod = async () => {
    if (!selectedOptionPeriod || !selectedOptionYear) return;
    incrementRequests();
    try {
      const infoPeriodo = `Semestre ${String(validateSemestre(selectedOptionPeriod))} / ${selectedOptionYear}`;
      setPeriodoDataInfo(infoPeriodo);

      const numericPeriod = Number(periodAutoEvaluation);
      await handleGetEvaluation(
        Evaluation?.employeeEvaluationEvaluatorUser?.claTrab,
        User?.claTrab,
        numericPeriod
      );

      handleValidatePeriod(periodAutoEvaluation);
      setRealizedSearch(realizedSearch + 1);
    } catch (error) {
      console.log("Error en handleSearchPeriod: ", error);
    } finally {
      decrementRequests();
    }
  };

  /** handleCleanPeriod: vuelve al periodo “por defecto” del usuario */
  const handleCleanPeriod = async () => {
    incrementRequests();
    try {
      const infoPeriodo = `Semestre ${String(validateSemestre(selectedOptionPeriod))} / ${selectedOptionYear}`;
      setPeriodoDataInfo(infoPeriodo);

      await handleGetEvaluation(
        Evaluation?.employeeEvaluationEvaluatorUser?.claTrab,
        User?.claTrab,
        idActualPeriod
      );

      // Forzamos al año actual
      setSelectedOptionYear(year);
      getPeriods(year, false);

      setRealizedSearch(realizedSearch + 1);
    } catch (error) {
      console.log("Error al limpiar periodo: ", error);
    } finally {
      decrementRequests();
    }
  };

  // ----------------------------------------------------------------
  // Funciones directas (evitamos simular un evento):
  // ----------------------------------------------------------------

  const handleYearChangeValue = (yearValue: number) => {
    setSelectedOptionYear(yearValue);
    getPeriods(yearValue, false);
  };

  const handleSemesterChangeValue = (semesterValue: string) => {
    setSelectedOptionPeriod(semesterValue);
    const periodo = validateIdPeriod(semesterValue);
    // setPeriodAutoEvaluation(periodo) si lo necesitas
  };

  // ----------------------------------------------------------------

  /** Efecto #1: Al montar => pedimos la lista de años, definimos año por defecto */
  useEffect(() => {
    getYearsOptions();
    setSelectedOptionYear(year);

    if (semestre && year) {
      const infoPeriodo = `Semestre ${String(semestre)} / ${year}`;
      setPeriodoDataInfo(infoPeriodo);
    }
  }, [changePage]);

  /** Efecto #2: Guardar en localStorage el selectedOptionPeriod */
  useEffect(() => {
    const periodId = { valuePeriod: selectedOptionPeriod || "" };
    localStorage.setItem("periodId", JSON.stringify(periodId));
  }, [selectedOptionPeriod]);

  /**
   * Efecto #3: la primera vez con “año más reciente” y “semestre más reciente”
   */
  useEffect(() => {
    if (years.length > 0 && evaluationPeriodName.length > 0 && isInitialLoad) {
      // Año más reciente
      const latestYear = Math.max(...years.map((y: YearOption) => y.year));
      handleYearChangeValue(latestYear);

      // Semestre más reciente
      const latestPeriod = evaluationPeriodName.reduce(
        (prev: PeriodName, curr: PeriodName) =>
          curr.evaluationPeriodNumberOfYear > prev.evaluationPeriodNumberOfYear
            ? curr
            : prev,
        evaluationPeriodName[0]
      );

      if (latestPeriod?.evaluationPeriodId) {
        handleSemesterChangeValue(
          `${latestPeriod.evaluationPeriodId},${latestPeriod.evaluationPeriodNumberOfYear}`
        );
      }

      setIsInitialLoad(false);
    } else if (years.length > 0 && isInitialLoad) {
      // Caso: no hay periodos
      const mostRecentYear = Math.max(...years.map((y: YearOption) => y.year));
      handleYearChangeValue(mostRecentYear);
      setIsInitialLoad(false);
    }
  }, [years, evaluationPeriodName, isInitialLoad]);

  /**
   * Efecto #4: si hay data en Evaluation.evaluationPeriod => solo al inicio
   * (para que no aplaste cambios manuales en años anteriores)
   */
  useEffect(() => {
    if (Evaluation?.evaluationPeriod && isInitialLoad) {
      const {
        evaluationPeriodId,
        evaluationPeriodNumberOfYear,
        evaluationPeriodYear,
      } = Evaluation.evaluationPeriod;

      console.log("Efecto #4 => Sincronizo con evaluationPeriodYear:", evaluationPeriodYear);
      setSelectedOptionYear(evaluationPeriodYear);
      handleSemesterChangeValue(`${evaluationPeriodId},${evaluationPeriodNumberOfYear}`);
    } else {
      console.log("Efecto #4 => Se omite (no es initialLoad o no hay evaluationPeriod)");
    }
  }, [Evaluation, isInitialLoad]);

  /**
   * Efecto #5: al cambiar selectedOptionPeriod => pulsar “Buscar” (opcional)
   */
  useEffect(() => {
    if (!isInitialLoad && searchButtonRef.current && selectedOptionPeriod && selectedOptionYear) {
      searchButtonRef.current.click();
    }
  }, [selectedOptionPeriod, selectedOptionYear, isInitialLoad]);

  return (
    <ContainerFiltros>
      <HeaderFiltrosBody>
        <div className="titlePeriod">Filtros</div>
        <div className="bodyPeriod">
          <div className="groupSelects">
            <SelectCustom
              valueSelect={selectedOptionYear}
              nameSelect="year"
              nameLabel="Año"
              hadleChange={(e: ChangeEvent<HTMLSelectElement>) => {
                onChangeOptionYear(e);
              }}
            >
              {years.map((obj: any) => (
                <option key={obj.year} value={obj.year}>
                  {obj.year}
                </option>
              ))}
            </SelectCustom>

            <SelectCustom
              valueSelect={selectedOptionPeriod}
              nameSelect="semester"
              nameLabel="Semestre"
              hadleChange={(e: ChangeEvent<HTMLSelectElement>) => {
                onChangeOptionSemestre(e);
              }}
              disabled={evaluationPeriodName.length === 0}
            >
              {evaluationPeriodName.map((periodName: PeriodName) => (
                <option
                  key={periodName.evaluationPeriodId}
                  value={`${periodName.evaluationPeriodId},${periodName.evaluationPeriodNumberOfYear}`}
                >
                  {periodName.evaluationPeriodNumberOfYear}
                </option>
              ))}
            </SelectCustom>
          </div>

          <div className="groupBtns">
            <BtnPeriodClean onClick={handleCleanPeriod}>
              Limpiar
            </BtnPeriodClean>
            <BtnPeriodSearch ref={searchButtonRef} onClick={handleSearchPeriod}>
              Buscar
            </BtnPeriodSearch>
          </div>
        </div>
      </HeaderFiltrosBody>
    </ContainerFiltros>
  );
};

export default FiltroPeriod;
