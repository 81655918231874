// Importación de componentes y librerías necesarios
import { TextField } from "@mui/material"; // Componente de campo de texto de Material-UI
import React, { useContext, useEffect, useState } from "react"; // Importaciones de React y hooks
import { Form } from "react-bootstrap"; // Componente de formulario de React Bootstrap
import { NumericFormat, NumericFormatProps } from "react-number-format"; // Formateo numérico
import styled from "styled-components"; // Estilizado de componentes
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation"; // Contexto para cambiar el periodo de autoevaluación
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext"; // Contexto de evaluación
import { useUrlPermisos } from "../../../hooks/Evaluation"; // Hook personalizado para permisos de URL
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow"; // Hook personalizado para obtener semestre y año actuales
import {
  useAbleQualification,
  useAbleQualificationGlobal,
  useAbleQualificationGlobalPlanta,
} from "../../../hooks/Kpis"; // Hooks personalizados para calificaciones
import { useCollaboratorRedux } from "../../../redux/Collaborators"; // Hook para acceder al estado de colaboradores en Redux
import { useEvaluationRedux } from "../../../redux/Evaluations"; // Hook para acceder al estado de evaluaciones en Redux
import { CardCurrentObj } from "./NewStyles"; // Componente estilizado para la tarjeta
import {
  getClassNameBasedOnPercentage,
  porcentajeAlcanceDefault,
  porcentajeDeAlcanceGenerales,
  regexValidateNumber,
  reglasKPIs,
  reglasKPIsDefault,
  suma,
} from "./utils"; // Utilidades para cálculos y validaciones

// Definición del componente CardTableKpis
const CardTableKpis = ({
  item,
  index,
  handleChange,
  activeOrBlockFirm,
  preguntas,
  loader,
  setSumTotal,
  sumTotal,
  evaluacion,
  sumaDeMetricas,
  setSumaDeMetricas
}: any) => {
  // ** Acceso al estado de Redux
  const { Evaluation } = useEvaluationRedux(); // Obtener evaluación desde Redux
  const { evaluationAttribute } = useCollaboratorRedux(); // Obtener atributos de evaluación desde Redux
  const { isActualDate } = useSemesterYearNow(); // Verificar si es la fecha actual

  // ** Uso de Hooks personalizados
  const { urlPermisos } = useUrlPermisos(); // Obtener permisos de URL
  const { ableQualification } = useAbleQualification(); // Verificar si la calificación está habilitada
  const { ableQualificationGlobal } = useAbleQualificationGlobal(); // Verificar calificación global
  const { ableQualificationGlobalPlanta } = useAbleQualificationGlobalPlanta(); // Verificar calificación global para planta

  // ** Uso de Contextos
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType; // Obtener estado de input desde el contexto de evaluación

  // ** Contexto para cambio de periodo
  const {
    totalConocimientoEmpresa,
    changePeriodEvaluation,
    totalSumaMetricas,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType; // Obtener datos del contexto de cambio de periodo

  // ** Estados locales
  const [loadInput, setLoadInput] = useState(true); // Estado para controlar la carga del input
  const [loadInputRender, setLoadInputRender] = useState(true); // Estado para controlar la renderización del input

  // ** useEffect para manejar la carga del input cuando cambia el item
  useEffect(() => {
    setLoadInput(false); // Desactivar carga
    setTimeout(() => {
      setLoadInput(true); // Activar carga después de 1.5 segundos
    }, 1500);
  }, [item]);

  // ** useEffect para manejar la renderización inicial del input
  useEffect(() => {
    setLoadInputRender(false); // Desactivar renderización
    setTimeout(() => {
      setLoadInputRender(true); // Activar renderización después de 1.5 segundos
    }, 1500);
  }, []);

  /**
   * Función para calcular las sumas totales basadas en el tipo de objetivo
   * @param valor - Objeto con los detalles del objetivo
   * @returns Total calculado o 0 si el resultado es NaN
   */
  const changeSumasTotales = (valor: any) => {
    let total: number = 0; // Inicializa total como 0

    // Condición para objetivos de tipo "D" que incluyen "laboral, humano,"
    if (
      valor.employeeObjetivesType === "D" &&
      valor.employeeEvaluationObjetivesDescription.includes("laboral, humano,")
    ) {
      const porcentajeAlcance = porcentajeAlcanceDefault("Desempenio", sumaDeMetricas); // Calcula el porcentaje de alcance para Desempeño
      total = reglasKPIsDefault(
        valor?.employeeEvaluationObjetivesWorth,
        porcentajeAlcance,
        valor?.employeeObjetivesType,
        valor?.employeeEvaluationObjetivesReal
      ); // Aplica reglas KPIs por defecto

    // Condición para objetivos de tipo "D" que incluyen "Conocimiento"
    } else if (
      valor.employeeObjetivesType === "D" &&
      valor.employeeEvaluationObjetivesDescription.includes("Conocimiento")
    ) {
      if (totalConocimientoEmpresa) { // Verifica si hay total de conocimiento de la empresa
        const porcentajeAlcance = porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa); // Calcula el porcentaje de alcance para Empresa
        total = reglasKPIsDefault(
          valor?.employeeEvaluationObjetivesWorth,
          porcentajeAlcance,
          valor?.employeeObjetivesType,
          valor?.employeeEvaluationObjetivesReal
        ); // Aplica reglas KPIs por defecto
      }

    // Condición para objetivos que no son de tipo "D"
    } else if (valor?.employeeObjetivesType !== "D") {
      const porcentajeAlcance = porcentajeAlcanceDefault(
        "Generales",
        valor?.employeeEvaluationObjetivesReal,
        valor?.employeeEvaluationObjetivesGoal
      ); // Calcula el porcentaje de alcance para Generales
      total = reglasKPIs(
        valor?.employeeEvaluationObjetivesWorth,
        porcentajeAlcance,
        valor?.employeeObjetivesType,
        valor?.employeeEvaluationObjetivesReal
      ); // Aplica reglas KPIs
    }

    return isNaN(total) ? 0 : total; // Asegura que nunca se devuelva NaN
  };

  /**
   * Función para actualizar las sumas totales en el estado
   */
  const changeValues = () => {
    const total = changeSumasTotales(item); // Calcula el total

    setSumTotal((prevState: any) => {
      // Crea una copia profunda del estado anterior
      const newEval = prevState.map((item: any) => ({ ...item }));

      // Verifica que el índice esté dentro del rango
      if (index >= 0 && index < newEval.length) {
        // Actualiza el valor del elemento en el índice especificado
        newEval[index].valor = total;
      } else {
        console.error('Índice fuera de rango'); // Log de error si el índice está fuera de rango
      }

      return newEval; // Retorna el nuevo estado
    });
  };

  // ** useEffect para actualizar sumTotal cuando cambia la evaluación
  useEffect(() => {
    setTimeout(() => {
      setSumTotal(
        [...evaluacion?.employeeEvaluationObjetivesCurrents?.map((item: any) => ({ valor: changeSumasTotales(item) }))]
      );
    }, 2000);
  }, [evaluacion]);

  // ** useEffect para calcular la suma de métricas cuando cambia el item
  useEffect(() => {
    const sumarMetricas = () => {
      const sumaDeMet =
        (suma(
          preguntas?.map(
            (item: any) => item?.evaluatorEvaluationResponseQuestion
          )
        ) /
          (Evaluation.employeEvaluationQuestions.length * 5)) *
        100; // Calcula el porcentaje de métricas
      return sumaDeMet;
    };
    setSumaDeMetricas(sumarMetricas()); // Actualiza la suma de métricas
  }, [item]);

  return (
    // Componente estilizado que representa una fila de la tabla de KPIs
    <CardCurrentObj activeBorder={activeOrBlockFirm}>
      {/* Primera columna: Número del periodo de evaluación */}
      <div className="first">
        {Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear}
      </div>

      {/* Segunda columna: Porcentaje de valor del objetivo según el tipo */}
      <div className="second">
        {item?.employeeObjetivesType === "G" &&
          `${item?.employeeEvaluationObjetivesWorth}%`}

        {item?.employeeObjetivesType === "I" && (
          <>{item?.employeeEvaluationObjetivesWorth}%</>
        )}

        {item?.employeeObjetivesType === "D" && (
          <>{item?.employeeEvaluationObjetivesWorth}%</>
        )}
      </div>

      {/* Tercera columna: Descripción del objetivo */}
      <div className="third">
        {item?.employeeEvaluationObjetivesDescription !== null
          ? item?.employeeEvaluationObjetivesDescription
          : ""}
      </div>

      {/* Cuarta columna: Meta del objetivo */}
      <div className="fourth">
        {item?.employeeObjetivesType === "G" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
        {item?.employeeObjetivesType === "I" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
        {item.employeeObjetivesType === "D" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
      </div>

      {/* Quinta columna: Campo de calificación */}
      <div className="fifth">
        {/* Condición para tipos que no son "D" ni "G" */}
        {item.employeeObjetivesType !== "D" &&
          item.employeeObjetivesType !== "G" && (
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      // Valida que el valor ingresado sea un número o esté vacío
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true); // Maneja el cambio
                        changeValues(); // Actualiza las sumas totales
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any, // Componente personalizado para formatear números
                    }}
                    disabled={ableQualification} // Deshabilita el campo si no está habilitado
                  />
                </ContainerInput>
              )}
            </>
          )}

        {/* Condición para tipo "G" que no incluye "planta" */}
        {item.employeeObjetivesType === "G" &&
          !item?.employeeEvaluationObjetivesDescription?.includes("planta") && (
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true);
                        changeValues();
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled={ableQualificationGlobal} // Deshabilita si la calificación global está deshabilitada
                  />
                </ContainerInput>
              )}
            </>
          )}

        {/* Condición para tipo "G" que incluye "planta" */}
        {item.employeeObjetivesType === "G" &&
          item?.employeeEvaluationObjetivesDescription?.includes("planta") && (
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true);
                        changeValues();
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled={ableQualificationGlobalPlanta} // Deshabilita si la calificación global para planta está deshabilitada
                  />
                </ContainerInput>
              )}
            </>
          )}
      </div>

      {/* Sexta columna: Porcentaje de alcance */}
      <div className="sixth">
        {item?.employeeObjetivesType === "G" && (
          <>
            {porcentajeDeAlcanceGenerales(
              item?.employeeEvaluationObjetivesGoal,
              item?.employeeEvaluationObjetivesReal
            )}
            %
          </>
        )}
        {item.employeeObjetivesType === "I" && (
          <>
            {porcentajeDeAlcanceGenerales(
              +item?.employeeEvaluationObjetivesGoal,
              +item?.employeeEvaluationObjetivesReal
            )}
            %
          </>
        )}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Conocimiento"
          ) && (
            <>
              {totalConocimientoEmpresa ? (
                <>
                  {porcentajeAlcanceDefault(
                    "Empresa",
                    totalConocimientoEmpresa
                  )}
                  %{" "}
                </>
              ) : (
                <> 0%</>
              )}
            </>
          )}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "laboral, humano,"
          ) && <>{porcentajeAlcanceDefault("Desempenio", sumaDeMetricas)}%</>}
      </div>

      {/* Séptima columna: Resultado de la evaluación */}
      <div className="seventh">
        {/* Campo de resultado para tipos que no son "D" */}
        {item?.employeeObjetivesType !== "D" && (
          <Form.Control
            name="employeeEvaluationObjetivesReal"
            className={`text-align-center ${getClassNameBasedOnPercentage(
              porcentajeAlcanceDefault(
                "Generales",
                item?.employeeEvaluationObjetivesReal,
                item?.employeeEvaluationObjetivesGoal
              )
            )}`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={reglasKPIs(
              item?.employeeEvaluationObjetivesWorth,
              porcentajeAlcanceDefault(
                "Generales",
                item?.employeeEvaluationObjetivesReal,
                item?.employeeEvaluationObjetivesGoal
              ),
              item?.employeeObjetivesType,
              item?.employeeEvaluationObjetivesReal
            )}
            disabled={true} // Campo deshabilitado para solo lectura
          />
        )}

        {/* Campo de resultado para tipo "D" con descripción "Conocimiento" */}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Conocimiento"
          ) && (
            <>
              {totalConocimientoEmpresa ? (
                <>
                  {loadInputRender && (
                    <Form.Control
                      name="employeeEvaluationObjetivesReal"
                      className={`text-align-center ${getClassNameBasedOnPercentage(
                        porcentajeAlcanceDefault(
                          "Empresa",
                          totalConocimientoEmpresa
                        )
                      )}`}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={reglasKPIsDefault(
                        item?.employeeEvaluationObjetivesWorth,
                        porcentajeAlcanceDefault(
                          "Empresa",
                          totalConocimientoEmpresa
                        ),
                        item?.employeeObjetivesType,
                        item?.employeeEvaluationObjetivesReal
                      )}
                      disabled={true} // Campo deshabilitado para solo lectura
                    />
                  )}
                </>
              ) : (
                <Form.Control
                  name="employeeEvaluationObjetivesReal"
                  className={`text-align-center ${getClassNameBasedOnPercentage(
                    50
                  )}`}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={0}
                  disabled={true} // Campo deshabilitado con valor 0
                />
              )}
            </>
          )}

        {/* Campo de resultado para tipo "D" con descripción "laboral, humano," */}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "laboral, humano,"
          ) && (
            <>
              <Form.Control
                name="employeeEvaluationObjetivesReal"
                className={`text-align-center ${getClassNameBasedOnPercentage(
                  porcentajeAlcanceDefault("Desempenio", sumaDeMetricas)
                )}`}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={reglasKPIsDefault(
                  item?.employeeEvaluationObjetivesWorth,
                  porcentajeAlcanceDefault("Desempenio", sumaDeMetricas),
                  item?.employeeObjetivesType,
                  item?.employeeEvaluationObjetivesReal
                )}
                disabled={true} // Campo deshabilitado para solo lectura
              />
            </>
          )}
      </div>
    </CardCurrentObj>
  );
};

export default CardTableKpis; // Exporta el componente para uso externo

// Estilizado del contenedor de input usando styled-components
const ContainerInput = styled.div`
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;

// Interfaz para las props personalizadas del componente NumericFormatCustom
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// Componente personalizado para formatear números con react-number-format
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref} // Referencia al input
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value, // Actualiza el valor en el evento onChange
            },
          });
        }}
        allowNegative={false} // No permite valores negativos
        decimalScale={2} // Escala decimal de 2
        thousandSeparator // Usa separador de miles
        valueIsNumericString // El valor es una cadena numérica
        prefix="" // Sin prefijo
        suffix={"%"} // Sufijo de porcentaje
        isAllowed={(values) => {
          const { floatValue } = values;
          // Limita el valor máximo a 120
          if (floatValue) {
            return floatValue <= 120;
          }
          return true;
        }}
      />
    );
  }
);
