import styled from "styled-components"; // Importa la librería styled-components para crear estilos CSS.
// import Close from "../../../assets/icons/CloseModal.svg"; // Comentado: Icono de cierre no utilizado.
import { useContext, useEffect, useState } from "react"; // Importa hooks de React: useContext, useEffect y useState.
import AlertModalIcon from "../../../assets/infoYellow.svg"; // Importa el icono de alerta amarillo.
import {
  EvaluationContext, // Importa el contexto EvaluationContext.
  EvaluationContextType, // Tipo del contexto EvaluationContext.
} from "../../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../../redux/Collaborators"; // Hook personalizado para manejar el estado de colaboradores en Redux.
import { putSend } from "../../../services/sendEvaluation.service"; // Servicio para enviar cambios en la evaluación.
import { ModalAlertWrapper } from "../ModalActions/ModalEditStyles"; // Importa estilos del contenedor del modal.

// Define un objeto de estados con sus respectivos nombres.
const ids: Record<number, string> = {
  1: "Definición de objetivos",
  2: "Nuevo ingreso",
  3: "Pendiente evaluar",
  4: "Pendiente de liberar",
  6: "Pendiente de firmar",
  7: "Completada",
};

// Componente ModalChangeEstatus para cambiar el estatus de un colaborador.
const ModalChangeEstatus = ({
  item, // Datos del colaborador actual.
  isModalVisible, // Estado de visibilidad del modal.
  setIsModalVisible, // Función para cambiar la visibilidad del modal.
  selectedOption, // Opcion seleccionada para el nuevo estatus.
  setRoleUser, // Función para cambiar el rol del usuario.
}: any) => {
  //** Redux: Accede al estado y funciones de Redux.
  const { User, handleGetCollaborators } = useCollaboratorRedux(); // Obtiene datos del usuario y la función para actualizar colaboradores.
  const { setLoadingPagination } = useContext(
    EvaluationContext // Accede a setLoadingPagination del contexto EvaluationContext.
  ) as EvaluationContextType;

  // Estado para almacenar filtros aplicados en la tabla.
  const [filters, setFilters] = useState<any | null>({
    claTrab: "",
    nombre: "",
    puesto: "",
    Localidad: "",
    area: "",
    departamento: "",
    fechaNac: "",
    IdEstatusEvaluacion: "",
    Clasificacion: "",
    gerente: "",
    director: "",
    compania: "",
    JerarquiaColaborador: "",
    EstatusAutoEvaluacion: "",
  });

  // useEffect: Al cargar, recupera los filtros guardados localmente.
  useEffect(() => {
    const filtrosAlmacenados = localStorage.getItem("tableFilters"); // Recupera los filtros desde localStorage.

    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados); // Parsea los filtros.
      setFilters(filtros); // Actualiza el estado de los filtros.
    }
    // Comentado: Opcionalmente podía limpiar o inicializar otros datos.
    // getDataSelectStatus();
    // handleClear();
  }, []);

  // Función para obtener la clave correspondiente a un valor del objeto "ids".
  function obtenerClavePorValor(valor: string): number | null {
    for (const clave in ids) { // Itera sobre las claves del objeto ids.
      if (ids.hasOwnProperty(clave)) { // Verifica que la clave sea propia del objeto.
        if (ids[clave] === valor) { // Compara el valor buscado.
          return Number(clave); // Retorna la clave numérica si coincide.
        }
      }
    }
    return null; // Retorna null si no encuentra coincidencias.
  }

  // Función para enviar los cambios de estatus.
  const handleSent = () => {
    const filtrosAlmacenados = localStorage.getItem("principalFilters"); // Recupera filtros principales.
    const pageStorage = localStorage.getItem("pageLocalStorage"); // Recupera la información de paginación.
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}"); // Parsea los datos de la paginación.

    const { page: pageStore } = pageStorageParsed; // Obtiene el número de página.

    if (selectedOption !== undefined) { // Verifica que la opción seleccionada sea válida.
      setRoleUser(selectedOption); // Actualiza el rol del usuario.

      const claveEncontrada: number | null =
        obtenerClavePorValor(selectedOption); // Obtiene la clave para el estatus seleccionado.

      if (claveEncontrada) {
        putSend(
          Number(item.employeeEvaluation.employeeEvaluationId), // ID de la evaluación.
          claveEncontrada // Nueva clave del estatus.
        )
          .then((response) => {
            setIsModalVisible(!isModalVisible); // Cierra el modal tras el envío exitoso.
          })
          .catch((error) => {
            // Comentado: Manejo de errores.
            // console.error("Error:", error);
          });
      }
      if (filtrosAlmacenados) { // Si hay filtros guardados, construye una nueva consulta.
        const filtros = JSON.parse(filtrosAlmacenados);
        const { year, periodo, propLocation, propDirector } = filtros; // Desestructura filtros relevantes.

        let query = `?`;
        Object.keys(filters).forEach((key) => {
          if (
            filters[key] !== undefined &&
            filters[key] !== "" &&
            filters[key] !== null &&
            filters[key] !== "Elige una opción"
          ) {
            query += `&${key}=${filters[key]}`; // Agrega cada filtro a la consulta.
          }
        });

        handleGetCollaborators( // Llama a la función para actualizar la lista de colaboradores.
          `${query}&NombreColaborador=${User.nombre}&RolColaborador=${
            User.role === "Auditor" ? "Admin" : User.role
          }&IdPeriod=${periodo}&PageNumber=${pageStore}&PageSize=10`
        );
        setLoadingPagination(true); // Activa el loader de paginación.
      }
    } else {
      // alert("Error"); // Comentado: Alerta de error.
    }
  };

  return (
    <ModalAlertWrapper display={isModalVisible}> {/* Contenedor principal del modal. */}
      <div className="modalWrapper"> {/* Contenedor del contenido del modal. */}
        <TitleContainer> {/* Encabezado del modal con icono y título. */}
          <img src={AlertModalIcon} className="image" alt="AlertModalIcon" />
          <TitleText className="titleText">Cambiar estatus </TitleText>
        </TitleContainer>

        <ContainerModalText> {/* Contenedor del texto descriptivo. */}
          <div className="textContainer">
            Al confirmar, cambiarás el estatus actual del colaborador [
            {item.claTrab} - {item.nombre}] {/* Muestra los datos del colaborador. */}
          </div>
          <div className="textContainer">¿Deseas continuar?</div> {/* Pregunta de confirmación. */}
        </ContainerModalText>
        <FooterModal> {/* Pie del modal con botones de acción. */}
          <ButtonModalCancel
            onClick={() => {
              setIsModalVisible(false); // Cierra el modal sin guardar cambios.
            }}
          >
            Cancelar
          </ButtonModalCancel>
          <ButtonModalSave onClick={handleSent}> {/* Guarda los cambios y ejecuta handleSent. */}
            Continuar
          </ButtonModalSave>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default ModalChangeEstatus; // Exporta el componente ModalChangeEstatus.

// Estilo del input de texto.
export const InputObj = styled.textarea`
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  resize: none;
`;

// Contenedor del título.
export const TitleContainer = styled.div`
  display: flex;
  width:100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

// Estilo del texto del título.
export const TitleText = styled.p`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  margin: 0;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
`;

// Contenedor del texto descriptivo.
export const ContainerModalText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .textContainer {
    font-size: 16px;
    color: #333335;
    font-weight: 400;
  }
`;

// Pie del modal con botones.
export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
`;

// Botón de guardar.
export const ButtonModalSave = styled.div`
  background-color: #c9f2ff;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3b63a2;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`;

// Botón de cancelar.
export const ButtonModalCancel = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #FFF;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;
