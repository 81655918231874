/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
// Desactiva las reglas de ESLint para evitar advertencias sobre expresiones no utilizadas y dependencias de hooks

// Importación de styled-components para crear componentes estilizados en React
import styled from "styled-components";

/**
 * Estilos para la barra lateral principal.
 * Utiliza la etiqueta <aside> con estilos específicos para diferentes tamaños de pantalla.
 */
export const SideBarStyles = styled.aside`
  /* display: block; */ /* Comentado: Podría usarse para forzar la visualización */
  /* width: 16rem; */ /* Comentado: Ancho fijo de la barra lateral */
  height: 100%; /* Altura completa del contenedor padre */
  padding: 24px; /* Espaciado interno de 24px en todos los lados */
  /* border-top-right-radius: 21px;
  border-bottom-right-radius: 21px; */ /* Comentado: Bordes redondeados superiores e inferiores */
  position: fixed; /* Posicionamiento fijo para que permanezca visible al hacer scroll */
  margin-top: 70px; /* Margen superior de 70px */
  padding-top: 50px; /* Espaciado interno superior de 50px */
  /* border: 1px solid red; */ /* Comentado: Borde rojo para depuración */
  background-color: #f0f0f0; /* Color de fondo gris claro */

  /* Media query para pantallas con ancho máximo de 800px */
  @media screen and (max-width: 800px) {
    display: block; /* Muestra la barra lateral */
    padding: 4rem 26px; /* Ajusta el padding para pantallas más pequeñas */
  }

  /* Media query para dispositivos móviles entre 320px y 768px */
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: auto; /* Ajusta la altura automáticamente */
    padding: 0.5rem; /* Reduce el padding */
    display: none; /* Oculta la barra lateral en dispositivos móviles */
  }
`;

/**
 * Estilos para la barra lateral en escritorio.
 * Utiliza la etiqueta <aside> y se muestra solo en pantallas más grandes.
 */
export const DesktopSideBar = styled.aside`
  display: none; /* Oculta la barra lateral por defecto */
  width: 227px !important; /* Ancho fijo de 227px, usando !important para sobrescribir otros estilos */
  height: 697px; /* Altura fija de 697px */
  padding: 4rem 26px; /* Espaciado interno de 4rem arriba/abajo y 26px a los lados */
  border-top-right-radius: 21px; /* Bordes redondeados superiores derechos */
  border-bottom-right-radius: 21px; /* Bordes redondeados inferiores derechos */
  /* background-color: #f0f0f0; */ /* Comentado: Color de fondo, podría ser heredado del contenedor principal */

  /* Media query para pantallas con ancho mínimo de 768px */
  @media screen and (min-width: 768px) {
    display: block; /* Muestra la barra lateral en pantallas más grandes */
  }
`;

/**
 * Contenedor para el título de cada sección en la barra lateral.
 * Utiliza Flexbox para alinear los elementos internamente.
 */
export const EvaluationTitleContainer = styled.div`
  display: flex; /* Utiliza Flexbox para la disposición de los elementos */
  align-items: center; /* Centra los elementos verticalmente */
  padding: 10px 16px; /* Espaciado interno de 10px arriba/abajo y 16px a los lados */
  gap: 12px; /* Espacio de 12px entre elementos hijos */
  margin-bottom: 10px; /* Margen inferior de 10px */
  align-content: center; /* Alinea el contenido verticalmente al centro */
  /* height: 44px; */ /* Comentado: Altura fija del contenedor */
  text-align: center; /* Centra el texto */
  border-radius: 8px; /* Bordes redondeados con radio de 8px */
  font-family: "Plus Jakarta Sans"; /* Familia de fuentes personalizada */

  /* Media query para dispositivos móviles entre 320px y 768px */
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 19vh; /* Ancho relativo a la altura de la ventana */
    margin-bottom: 8px; /* Reduce el margen inferior a 8px */
  }
`;

/**
 * Título para cada sección en la barra lateral.
 * Utiliza la etiqueta <h2> con estilos personalizados.
 */
export const EvaluationTitle = styled.h2`
  font-weight: 400; /* Peso de fuente normal */
  font-size: 14px; /* Tamaño de fuente de 14px */
  color: #333335; /* Color de texto gris oscuro */
  color: var(--primary-true-blue, #3b63a2); /* Color de texto azul primario, con fallback */
  font-feature-settings: "clig" off, "liga" off; /* Desactiva ciertas ligaduras tipográficas */
  font-family: Plus Jakarta Sans; /* Familia de fuentes personalizada */
  font-size: 15.788px; /* Tamaño de fuente ajustado */
  font-style: normal; /* Estilo de fuente normal */

  line-height: 140%; /* Altura de línea del 140%, equivalente a 22.104px */
`;

/**
 * Divisor estilizado para separar secciones.
 * Utiliza la etiqueta <div> con un borde sólido.
 */
export const BorderDivision = styled.div`
  border: 2px solid #939396; /* Borde sólido de 2px con color gris */
`;

/**
 * Lista de evaluaciones.
 * Utiliza la etiqueta <ul> y remueve los estilos predeterminados de lista.
 */
export const EvaluationList = styled.ul`
  padding: 0; /* Elimina el padding predeterminado */
  list-style: none; /* Elimina los puntos de lista */
  margin-left: 5px; /* Margen izquierdo de 5px */
`;

/**
 * Elemento de lista para cada evaluación.
 * Utiliza la etiqueta <li> con estilos flexibles.
 */
export const EvaluationListItem = styled.li`
  display: flex; /* Utiliza Flexbox para la disposición interna */
  align-items: center; /* Centra los elementos verticalmente */

  /* Estilos para los elementos <span> dentro de cada <li> */
  span {
    font-weight: 500; /* Peso de fuente medio */
    font-size: 10px; /* Tamaño de fuente de 10px */
  }
`;
