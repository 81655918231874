import { useContext } from "react";
import editObj from "../../../assets/icons/EditObj.svg"; // Icono de edición
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext"; // Contexto para evaluación
import { useEditFutureObjetive } from "../../../hooks/Evaluation"; // Hook para habilitar edición de objetivos futuros
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow"; // Hook para validar fechas actuales
import {
  EditContainer,
  EditObjectiveContainer,
} from "../Dashboard/stylesDashboard/AdminStyles"; // Estilos importados

// Componente EditObjetive
// Permite mostrar y activar la edición de un objetivo específico
const EditObjetive = ({
  setEditObjective, // Función para habilitar el modo de edición
  index,            // Índice del elemento actual en el array de datos
  data,             // Array que contiene la información de los objetivos
  setIndexEdit,     // Función para actualizar el índice del objetivo a editar
}: any) => {

  // ** Contexto: Obtener permisos globales desde EvaluationContext
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  // ** Hook personalizado: Verificar si la edición de objetivos futuros está habilitada
  const { editeFutureObjetive } = useEditFutureObjetive();

  // ** Hook personalizado: Validar si la fecha actual es válida para edición
  const { isActualDate } = useSemesterYearNow();

  // Función para manejar el clic en el ícono de edición
  const handleClick = () => {
    setEditObjective(true); // Activar el modo de edición
    setIndexEdit(index);    // Establecer el índice del objetivo seleccionado
  };

  return (
    <EditContainer>
      {/* Contenedor del texto del objetivo */}
      <EditObjectiveContainer>
        {data.length > 0 && data[index]?.descripcion?.label} {/* Mostrar la descripción si hay datos */}
      </EditObjectiveContainer>

      {/* Renderización condicional del icono de edición */}
      {/* El bloque comentado podría habilitar la edición solo si la fecha es válida */}
      {/* {isActualDate && ( */}
        <div style={{ cursor: "pointer" }} onClick={() => handleClick()}>
          <img
            src={editObj}          // Icono de edición
            alt="editObjetive"     // Texto alternativo para accesibilidad
            style={{ height: "12px", width: "12px" }} // Dimensiones del ícono
          />
        </div>
      {/* )} */}
    </EditContainer>
  );
};

export default EditObjetive;
