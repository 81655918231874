// Importaciones necesarias desde React y otras librerías
import { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { instructions } from "../../../DataFake/EvaluationFormsContent";
import {
  HumanFactorResponseInterface,
  IEvaluationResults,
  IOportunities,
  IQuestionInterface,
  IStrenghs,
  JobPerformanceResponseInterface,
  SkillResponseInterface,
} from "../../../Interface/ApiInterface";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useUrlPermisos } from "../../../hooks/Evaluation";
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow";
import { useAbleFortalezasDebilidades } from "../../../hooks/FortalezasDebilidades";
import { useAbleInputSelectMetricas } from "../../../hooks/Metricas";
import { ContainerDescriptionAreas } from "../../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import EditStrengthsAndOpportunities from "../../../pages/Admin/components/EditStrengthsAndOpportunities";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import {
  AutoEvaluacionContainer,
  CommentContainer,
  ContainerSelect,
  EvaluatedCommentsContainer,
  EvaluationInstructionsHeading,
  EvaluationInstructionsList,
  FormContainer,
  FormTitle,
  NewTitleContainer,
  NumberItem,
  PlusResult,
  QuestionList,
  QuestionListItem,
  Results,
  ResultsContainer,
  SelectContainer,
  SelectOption,
  TextItem,
  TitleWrapper,
} from "./EvaluationFormsStyles";
import {
  EditStrongestModal,
  InfoLaboralDevelopmentModal,
  InfoLaboralDevelopmentModalFactorHumano,
  InfoLaboralDevelopmentModalHabilidades,
  OportunitiesModal,
  SaveEditModal,
  StrongestModal,
} from "./EvaluationModals/EvaluationModals";

// Función auxiliar para sumar los elementos de un arreglo de números
const suma = (array: number[]) => {
  let suma = 0;
  for (let i = 0; i < array?.length; i++) {
    suma += array[i];
  }
  return suma;
};

/**
 * Componente que muestra las instrucciones para la evaluación
 */
export const EvaluationInstructions = () => {
  return (
    <Container>
      <EvaluationInstructionsHeading>
        <span></span>
        <strong>Evalue del 1 al 5 las siguientes métricas</strong>
        <Button variant="secondary">Guardar Evaluación</Button>
      </EvaluationInstructionsHeading>
      <EvaluationInstructionsList>
        {instructions.map((instruction) => (
          <li>
            <strong>{instruction}</strong>
          </li>
        ))}
      </EvaluationInstructionsList>
    </Container>
  );
};

/**
 * Componente del formulario de desempeño laboral
 * @param props - Propiedades que incluye resultados, índices, funciones para actualizar el estado, etc.
 */
export const JobPerformanceForm = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setAddJobPerformanceEvaluado,
  addJobPerformanceEvaluado,
  changePeriodEvaluation,
}: IQuestionInterface<JobPerformanceResponseInterface[]>) => {
  // Uso de Redux para acceder a los atributos de evaluación y al usuario actual
  const { evaluationAttribute } = useCollaboratorRedux();
  const { User } = useCollaboratorRedux();

  // Uso del contexto de Evaluación para determinar si se puede ingresar información
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  // Hooks personalizados para permisos y fechas
  const { urlPermisos } = useUrlPermisos();

  /**
   * Maneja el cambio en el resultado de una pregunta de evaluación
   * @param e - Evento de cambio
   * @param id - ID de la pregunta de evaluación
   * @param user - Indica si el cambio lo realiza el evaluado
   */
  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();

    if (user) {
      // Actualiza la respuesta del evaluado
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationJobPerformance: results,
      });

      // Calcula la suma de las respuestas del evaluado
      setAddJobPerformanceEvaluado(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      // Actualiza la respuesta del evaluador
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationJobPerformance: results,
      });

      // Calcula la suma de las respuestas del evaluador
      setAdd(
        suma(
          results.map(
            (item: JobPerformanceResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  // Efecto que recalcula las sumas cuando cambian los resultados o el periodo de evaluación
  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );

    setAddJobPerformanceEvaluado(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  // Uso de hooks personalizados para validar permisos en esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();

  const { Evaluation } = useEvaluationRedux();
  const { isActualDate } = useSemesterYearNow();

  return (
    <FormContainer>
      {/* Título del formulario de desempeño laboral */}
      <FormTitle>
        <TitleWrapper>
          <strong>1.- Desempeño laboral</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModal />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      {/* Encabezados para evaluado y evaluador */}
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      {/* Lista de preguntas */}
      <QuestionList>
        {results?.map((item: JobPerformanceResponseInterface, aux: number) => {
          return (
            <QuestionListItem key={item.question.idQuestion}>
              {/* Texto de la pregunta */}
              <TextItem>
                {index + (aux + 1)} .- {item?.question.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna para la respuesta del evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    style={{ fontSize: "14px" }}
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna para la respuesta del evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    style={{ fontSize: "14px" }}
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    colorBtn
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {/* Condicional para mostrar la suma total dependiendo del rol y permisos del usuario */}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addJobPerformanceEvaluado}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

/**
 * Componente del formulario de Factor Humano / Actitud
 * @param props - Propiedades que incluye resultados, índices, funciones para actualizar el estado, etc.
 */
export const HumanFactoAttitude = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setEvaluator,
  evaluator,
  addHumanFactorEvaluador,
  setAddHumanFactorEvaluador,
}: IQuestionInterface<HumanFactorResponseInterface[]>) => {
  // Uso de Redux para acceder al usuario actual
  const { User } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();

  // Uso del contexto para manejar el cambio de periodo de evaluación
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  /**
   * Maneja el cambio en el resultado de una pregunta de evaluación
   * @param e - Evento de cambio
   * @param id - ID de la pregunta de evaluación
   * @param user - Indica si el cambio lo realiza el evaluado
   */
  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();
    if (user) {
      // Actualiza la respuesta del evaluado
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationHumanFactor: results,
      });
      // Calcula la suma de las respuestas del evaluado
      setAddHumanFactorEvaluador(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      // Actualiza la respuesta del evaluador
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationHumanFactor: results,
      });
      // Calcula la suma de las respuestas del evaluador
      setAdd(
        suma(
          results.map(
            (item: HumanFactorResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  // Efecto que recalcula las sumas cuando cambian los resultados o el periodo de evaluación
  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: HumanFactorResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );
    setAddHumanFactorEvaluador(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  // Uso de Redux para acceder a los atributos de evaluación y al estado de evaluación
  const { evaluationAttribute } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  // Uso de hooks personalizados para validar permisos en esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();
  const { isActualDate } = useSemesterYearNow();

  return (
    <FormContainer className="flex-item">
      {/* Título del formulario de Factor Humano / Actitud */}
      <FormTitle>
        <TitleWrapper>
          <strong>2.- Factor Humano / Actitud</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModalFactorHumano />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      {/* Encabezados para evaluado y evaluador */}
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      {/* Lista de preguntas */}
      <QuestionList>
        {results?.map((item: HumanFactorResponseInterface, aux: number) => {
          return (
            <QuestionListItem key={item.question.idQuestion}>
              {/* Texto de la pregunta */}
              <TextItem>
                {index + (aux + 1)} .- {item?.question.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna para la respuesta del evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}

                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}

                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna para la respuesta del evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {/* Condicional para mostrar la suma total dependiendo del rol y permisos del usuario */}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">
                {/* {changePeriodEvaluation ? addHumanFactorEvaluador : "hola"} */}
                {add}
              </span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addHumanFactorEvaluador}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

/**
 * Componente de la lista de habilidades
 * @param props - Propiedades que incluye resultados, índices, funciones para actualizar el estado, etc.
 */
export const SkillsList = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setEvaluator,
  evaluator,
  addSkillsEvaluado,
  setAddSkillsEvaluador,
}: IQuestionInterface<SkillResponseInterface[]>) => {
  // Uso de Redux para acceder al usuario actual
  const { User } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();
  const { Evaluation } = useEvaluationRedux();

  // Uso de hooks personalizados para validar permisos en esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();

  // Uso del contexto para manejar el cambio de periodo de evaluación
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  /**
   * Maneja el cambio en el resultado de una pregunta de evaluación
   * @param e - Evento de cambio
   * @param id - ID de la pregunta de evaluación
   * @param user - Indica si el cambio lo realiza el evaluado
   */
  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();
    if (user) {
      // Actualiza la respuesta del evaluado
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationSkills: results,
      });

      // Calcula la suma de las respuestas del evaluado
      setAddSkillsEvaluador(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      // Actualiza la respuesta del evaluador
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      // Actualiza el estado de la evaluación con las nuevas respuestas
      setEvaluation({
        ...evaluation,
        employeeEvaluationSkills: results,
      });
      // Calcula la suma de las respuestas del evaluador
      setAdd(
        suma(
          results.map(
            (item: SkillResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  // Efecto que recalcula las sumas cuando cambian los resultados o el periodo de evaluación
  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: SkillResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );

    setAddSkillsEvaluador(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  // Uso de Redux para acceder a los atributos de evaluación
  const { evaluationAttribute } = useCollaboratorRedux();

  // Uso del contexto para determinar si se puede ingresar información
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { isActualDate } = useSemesterYearNow();

  return (
    <FormContainer className="flex-item">
      {/* Título del formulario de habilidades */}
      <FormTitle>
        <TitleWrapper>
          <strong>3.- Habilidades</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModalHabilidades />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      {/* Encabezados para evaluado y evaluador */}
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      {/* Lista de preguntas */}
      <QuestionList>
        {results?.map((item: SkillResponseInterface, aux: number) => {
          return (
            <QuestionListItem key={item.question.idQuestion}>
              {/* Texto de la pregunta */}
              <TextItem>
                {index + (aux + 1)} .- {item?.question?.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna para la respuesta del evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna para la respuesta del evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {/* Opciones del 1 al 5, seleccionando la que corresponda */}
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {/* Condicional para mostrar la suma total dependiendo del rol y permisos del usuario */}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span style={{ marginRight: "4.5rem" }}>Suma</span>
              <div style={{ marginRight: "1.5rem" }} className="sumaClass">
                {add}
              </div>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addSkillsEvaluado}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

/**
 * Componente que muestra los resultados de la evaluación
 * @param props - Propiedades que incluye total, políticas de empresa, sumas, y el objeto de evaluación
 */
export const EvaluationResults = ({
  total,
  politicasDeEmpresa,
  addTotalEvalaudo,
  evaluation,
}: IEvaluationResults) => {
  const { urlPermisos } = useUrlPermisos();
  const { User } = useCollaboratorRedux();

  // Uso del contexto para manejar cambios de periodo y otras sumas
  const {
    totalConocimientoEmpresa,
    setTotalConocimientoEmpresa,
    changePeriodEvaluation,
    setTotalSumaMetricas,
    totalSumaMetricas,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;

  /**
   * Función auxiliar para calcular la calificación final
   * @param sum - Suma total de las métricas
   * @param length - Número de métricas
   * @returns Calificación final como cadena con dos decimales
   */
  const finalQualification = (sum: number, length: number): string => {
    return (sum / length).toFixed(2);
  };

  // Efecto para calcular y establecer las sumas totales y el conocimiento de políticas
  useEffect(() => {
    if (evaluation.catStatusEvaluationId === 7) {
      // Calcula la suma total de las métricas del evaluador
      let add = suma(
        evaluation.employeEvaluationQuestions?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      );

      setTotalSumaMetricas(add);

      // Establece el conocimiento de las políticas de la empresa
      if (evaluation?.employeeEvaluationPolitics) {
        setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
      }
    } else {
      if (total) {
        setTotalSumaMetricas(total);
      }
      if (evaluation.employeeEvaluationPolitics) {
        setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
      }
    }
  }, [changePeriodEvaluation, evaluation?.employeeEvaluationPolitics, total]);

  // Efecto para establecer el conocimiento de las políticas de la empresa al montar el componente
  useEffect(() => {
    setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
  }, []);

  return (
    <ResultsContainer>
      {/* Condicional para mostrar la suma total dependiendo del rol y permisos del usuario */}
      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        !urlPermisos && (
          <Results>
            <span>Suma Total</span>
            <div className="totalRightSpacing">{total}</div>
          </Results>
        )}

      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        urlPermisos && (
          <Results>
            <span>Suma Total</span>
            <div className="totalRightSpacing">
              {evaluation.catStatusEvaluationId === 7
                ? suma(
                    evaluation.employeEvaluationQuestions?.map(
                      (item: JobPerformanceResponseInterface) =>
                        item?.evaluatorEvaluationResponseQuestion
                    )
                  )
                : suma(
                    evaluation.employeEvaluationQuestions?.map(
                      (item: JobPerformanceResponseInterface) =>
                        item?.employeEvaluationResponseQuestion
                    )
                  )}
            </div>
          </Results>
        )}

      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        urlPermisos && (
          <Results>
            <span>Calificación final</span>
            <div className="finalQualification">
              {evaluation.catStatusEvaluationId === 7
                ? finalQualification(
                    suma(
                      evaluation.employeEvaluationQuestions?.map(
                        (item: JobPerformanceResponseInterface) =>
                          item?.evaluatorEvaluationResponseQuestion
                      )
                    ),
                    evaluation?.employeEvaluationQuestions?.length
                  )
                : finalQualification(
                    suma(
                      evaluation.employeEvaluationQuestions?.map(
                        (item: JobPerformanceResponseInterface) =>
                          item?.employeEvaluationResponseQuestion
                      )
                    ),
                    evaluation?.employeEvaluationQuestions?.length
                  )}
            </div>
          </Results>
        )}
      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        !urlPermisos && (
          <Results>
            <span>Calificación final</span>
            <div className="finalQualification">
              {finalQualification(total, evaluation?.employeEvaluationQuestions?.length)}
            </div>
          </Results>
        )}

      {/* Resultado del conocimiento de las políticas de la empresa */}
      <Results>
        <span>Conocimiento de las políticas de la empresa</span>
        <div className="finalQualification">{politicasDeEmpresa}</div>
      </Results>
    </ResultsContainer>
  );
};

/**
 * Componente que muestra los comentarios del evaluado
 * @param props - Propiedades que incluye título, oportunidades, manejadores de cambio y selección
 */
export const EvaluatedComents = ({
  title,
  Opportunities,
  handleChange,
  oportunitySelect,
}: IOportunities) => {
  const { evaluationAttribute } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  /**
   * Obtiene el ID de una oportunidad basada en el texto del área
   * @param obj - Objeto que contiene el texto del área de oportunidad
   * @returns ID de la oportunidad
   */
  const getId = (obj: any) => {
    const id = Opportunities?.find(
      (item) =>
        item?.employeeEvaluationWorkersOpportunitieAreaText ===
        obj?.employeeEvaluationWorkersOpportunitieAreaText
    )?.employeeEvaluationWorkersOpportunitieId;
    return id;
  };

  // Estados locales para manejar la edición de objetivos y alertas
  const [showEditObjetive, setShowEditObjetive] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [idSelect, setIdSelect] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveDatas, setIsSaveDatas] = useState({});
  const [isErased, setIsErased] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [value, setValue] = useState(textModal ?? "");

  /**
   * Maneja la actualización de datos al editar un objetivo
   * @param e - Evento de cambio
   * @param type - Tipo de cambio
   * @param index - Índice del objetivo a editar
   */
  const handleUpdated = (e: any, type: any, index: number) => {
    const datas = {
      e: e,
      type: type,
      index: idSelect,
    };
    setIsSaveDatas(datas);
  };

  return (
    <EvaluatedCommentsContainer>
      <>
        {/* Título de la sección de comentarios */}
        <NewTitleContainer>
          <p className="titleStyle">{title}</p>
          <div className="iconContainer">
            {evaluationAttribute.show_modal_help_tooltips && (
              <OportunitiesModal />
            )}
          </div>
        </NewTitleContainer>
        {/* Primer comentario */}
        <CommentContainer>
          <NumberItem>1.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={0}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        {/* Segundo comentario */}
        <CommentContainer>
          <NumberItem>2.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={1}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        {/* Tercer comentario */}
        <CommentContainer>
          <NumberItem>3.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={2}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
      </>
      {/* Modal para editar objetivos */}
      <EditStrongestModal
        show={showEditObjetive}
        onHide={setShowEditObjetive}
        handleChangeDos={handleUpdated}
        indexEdit={indexEdit}
        editText={"oportunidades"}
        StrenghsOrOpportunities={Opportunities}
        // isDisabled={urlPermisos || ableInput}
        formValues={oportunitySelect && getId(oportunitySelect[idSelect])}
        formData={oportunitySelect}
        idSelect={idSelect}
        isStrengh={false}
        setShowAlert={setShowAlert}
        isErased={isErased}
        setIsErased={setIsErased}
        textModal={textModal}
        setTextModal={setTextModal}
        value={value}
        setValue={setValue}
      />
      {/* Modal para guardar cambios */}
      <SaveEditModal
        show={showAlert}
        onHide={setShowAlert}
        isSaveDatas={isSaveDatas}
        setIsSaveDatas={setIsSaveDatas}
        handleChange={handleChange}
        onHide2={setShowEditObjetive}
        isErased={isErased}
        setTextModal={setTextModal}
        isStrengh={false}
        setValue={setValue}
      />
    </EvaluatedCommentsContainer>
  );
};

/**
 * Componente que muestra los comentarios del evaluador
 * @param props - Propiedades que incluye título, fortalezas, manejadores de cambio y selección
 */
export const EvaluatorComents = ({
  title,
  Strenghs,
  handleChange,
  strenghsSelect,
}: IStrenghs) => {
  const { evaluationAttribute } = useCollaboratorRedux();

  // Uso de hooks personalizados para permisos y fechas
  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  // Uso del contexto para determinar si se puede ingresar información y permisos para fortalezas y debilidades
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { enablePermissionStrAndOp } = useAbleFortalezasDebilidades();

  /**
   * Obtiene el ID de una fortaleza basada en el texto de la pregunta
   * @param obj - Objeto que contiene el texto de la pregunta de la fortaleza
   * @returns ID de la fortaleza
   */
  const getId: any = (obj: any) => {
    const id = Strenghs?.find(
      (item) =>
        item?.employeeEvaluationWorkersStrengthsQuestionText ===
        obj?.employeeEvaluationWorkersStrengthsQuestionText
    )?.employeeEvaluationWorkersStrengthsId;
    return id;
  };

  // Estados locales para manejar la edición de objetivos y alertas
  const [showEditObjetive, setShowEditObjetive] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [idSelect, setIdSelect] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveDatas, setIsSaveDatas] = useState({});
  const [isErased, setIsErased] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [value, setValue] = useState(textModal ?? "");

  /**
   * Maneja la actualización de datos al editar una fortaleza
   * @param e - Evento de cambio
   * @param type - Tipo de cambio
   * @param index - Índice de la fortaleza a editar
   */
  const handleUpdated = (e: any, type: any, index: number) => {
    const datas = {
      e: e,
      type: type,
      index: idSelect,
    };
    setIsSaveDatas(datas);
  };

  return (
    <EvaluatedCommentsContainer>
      <>
        {/* Título de la sección de comentarios */}
        <NewTitleContainer>
          <p className="titleStyle">{title} </p>
          <div className="iconContainer">
            {evaluationAttribute.show_modal_help_tooltips === 1 && (
              <StrongestModal />
            )}
          </div>
        </NewTitleContainer>
        {/* Primer comentario */}
        <CommentContainer>
          <NumberItem>1.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={0}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                textModal={textModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        {/* Segundo comentario */}
        <CommentContainer>
          <NumberItem>2.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={1}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        {/* Tercer comentario */}
        <CommentContainer>
          <NumberItem>3.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={2}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
      </>
      {/* Modal para editar fortalezas */}
      <EditStrongestModal
        show={showEditObjetive}
        onHide={setShowEditObjetive}
        handleChangeDos={handleUpdated}
        indexEdit={indexEdit}
        editText={"fortalezas"}
        StrenghsOrOpportunities={Strenghs}
        isDisabled={urlPermisos || ableInput}
        formValues={strenghsSelect && getId(strenghsSelect[idSelect])}
        formData={strenghsSelect}
        idSelect={idSelect}
        isStrengh={true}
        setShowAlert={setShowAlert}
        isErased={isErased}
        setIsErased={setIsErased}
        textModal={textModal}
        setTextModal={setTextModal}
        value={value}
        setValue={setValue}
      />
      {/* Modal para guardar cambios */}
      <SaveEditModal
        show={showAlert}
        onHide={setShowAlert}
        isSaveDatas={isSaveDatas}
        setIsSaveDatas={setIsSaveDatas}
        handleChange={handleChange}
        onHide2={setShowEditObjetive}
        isErased={isErased}
        setTextModal={setTextModal}
        isStrengh={true}
        setValue={setValue}
      />
    </EvaluatedCommentsContainer>
  );
};
