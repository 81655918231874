// Importación de tipos y componentes de Material-UI
import {
  OutlinedInputProps,    // Tipos para las propiedades del input delineado
  TextField,             // Componente TextField de Material-UI
  TextFieldProps,        // Tipos para las propiedades del TextField
  styled as styles,      // Función styled renombrada como 'styles' para evitar conflictos
} from "@mui/material";

// Importación de React y hooks necesarios
import * as React from "react";

// Importación de componentes y tipos de react-number-format para formatear números
import { NumericFormat, NumericFormatProps } from "react-number-format";

// Importación de styled-components para crear componentes estilizados
import styled from "styled-components";

// Definición de una expresión regular para validar números
const regexValidateNumber = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

/**
 * Componente TextField personalizado con estilos específicos.
 * Utiliza Material-UI y styled-components para aplicar estilos avanzados.
 */
const RedditTextField = styles((props: TextFieldProps) => (
  <TextField
    // Deshabilita la línea subrayada del input
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props} // Propiedades adicionales pasadas al TextField
  />
))(({ theme }) => ({
  // Estilos específicos para la raíz del input con variante 'filled'
  "& .MuiFilledInput-root": {
    overflow: "hidden",               // Oculta el desbordamiento del contenido
    borderRadius: 4,                  // Aplica un radio de borde de 4px
    "&:hover": {
      backgroundColor: "transparent", // Cambia el color de fondo al pasar el cursor
    },
    "&.Mui-focused": {
      backgroundColor: "transparent", // Mantiene el fondo transparente cuando está enfocado
      boxShadow: `$#fff 0 0 0 0.2rem`, // Aplica una sombra alrededor del input
      borderColor: "#fff",            // Cambia el color del borde a blanco cuando está enfocado
    },
  },
}));

/**
 * Interface para las propiedades personalizadas del componente NumericFormatCustom.
 */
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void; // Función para manejar cambios en el input
  name: string; // Nombre del input
}

/**
 * Componente personalizado que formatea la entrada numérica.
 * Utiliza react-number-format para aplicar formatos y validaciones específicas.
 */
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props; // Desestructuración de las propiedades

    return (
      <NumericFormat
        {...other} // Propiedades adicionales pasadas al NumericFormat
        getInputRef={ref} // Referencia al input para controlar el enfoque
        onValueChange={(values) => {
          // Llama a la función onChange con el nuevo valor formateado
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={0}               // Limita a cero decimales
        thousandSeparator               // Añade separadores de miles
        valueIsNumericString           // El valor es una cadena numérica
        allowNegative={false}           // No permite valores negativos
        prefix=""                       // Sin prefijo
        suffix={"%"}                    // Añade un sufijo de porcentaje
        isAllowed={(values) => {
          const { floatValue, formattedValue } = values;

          // Previene que el valor formateado comience con '0' si tiene más de un carácter
          if (formattedValue.startsWith('0') && formattedValue.length > 1) {
            return false;
          }

          // Permite valores hasta 100
          if (floatValue) {
            return floatValue <= 100;
          }
          return true;
        }}
      />
    );
  }
);

/**
 * Componente principal que utiliza los inputs formateados.
 * Permite ingresar valores numéricos con formato personalizado y validaciones.
 */
export default function FormattedInputsFuture({
  handleChange, // Función para manejar cambios en el input
  value,        // Valor actual del input
  disable,      // Booleano para deshabilitar el input
  index,        // Índice del elemento en una lista (si aplica)
  name,         // Nombre del input
}: any) {
  return (
    <ContainerInput>
      {/* Campo de texto con formato numérico personalizado */}
      <TextField
        defaultValue={value} // Valor por defecto del input
        onChange={(e) => {
          // Valida el valor ingresado utilizando una expresión regular
          if (
            regexValidateNumber.test(e.target.value) ||
            e.target.value === ""
          ) {
            handleChange(index, name, e.target.value); // Llama a la función de manejo de cambio si la validación pasa
          }
        }}
        name={name} // Nombre del input
        InputProps={{
          inputComponent: NumericFormatCustom as any, // Asigna el componente de formato personalizado
        }}
        disabled={disable} // Deshabilita el input si es necesario
      />
    </ContainerInput>
  );
}

/**
 * Contenedor estilizado para el input.
 * Aplica estilos específicos a los elementos internos de Material-UI para una apariencia personalizada.
 */
const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  /* Estilos para la raíz del input de Material-UI */
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;                              // Ancho del 85%
    margin: 0 auto;                          // Centra el input horizontalmente
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos para el control de formulario de Material-UI */
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;                           // Utiliza flexbox para la disposición de los elementos hijos
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
    align-items: center;                     // Alinea verticalmente al centro
    justify-content: center;                 // Alinea horizontalmente al centro
    outline: none;                           // Sin contorno al enfocar
    align-content: center;                   // Alinea el contenido al centro
  }

  /* Elimina el contorno al enfocar el input */
  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos base para la raíz del input de Material-UI */
  .MuiInputBase-root {
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }
`;
