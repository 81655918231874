/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck

// Importación de hooks y componentes necesarios
import { useState } from "react"; // Hook para manejar el estado local en componentes funcionales
import { Container, Modal } from "react-bootstrap"; // Componentes de React Bootstrap para diseño y modales
import { validateIdPeriod } from "../../../../context/validacionesFiltros"; // Función para validar el ID del periodo
import { FliterByTiemRow } from "../../../../pages/Admin/Dashboard/stylesDashboard/AdminStyles"; // Estilos personalizados para filas de filtro
import {
  ContainModal,
  StyleInputs,
} from "../../../../pages/Admin/Objectives/ObjetivesStyles"; // Estilos personalizados para el modal y los inputs
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../pages/Admin/Politics/PoliticsStyles"; // Estilos personalizados para botones
import { useCollaboratorRedux } from "../../../../redux/Collaborators"; // Hook personalizado para acceder al estado de colaboradores en Redux
import { postInterviewServices } from "../../../../services/evaluation.service"; // Servicio para realizar la petición de agendar entrevista
import { Date } from "../../../common/InputsByType"; // Componente de entrada de fecha personalizado
import { TitleModal } from "../styleModals"; // Estilo personalizado para el título del modal

/**
 * Componente para agendar una entrevista
 *
 * @param {Object} props - Propiedades del componente
 * @param {boolean} props.showBookInterview - Controla la visibilidad del modal
 * @param {string} props.filterSearch - Filtro de búsqueda aplicado
 * @param {function} props.onHide - Función para ocultar el modal
 * @param {Object} props.evaluation - Datos de la evaluación
 * @param {number} props.evaluation_period_id - ID del periodo de evaluación
 * @param {function} props.setError - Función para establecer el estado de error
 * @param {function} props.setSuccess - Función para establecer el estado de éxito
 * @param {function} props.setMessage - Función para establecer el mensaje de estado
 */
function BookingInterview({
  showBookInterview,
  filterSearch,
  onHide,
  evaluation,
  evaluation_period_id,
  setError,
  setSuccess,
  setMessage,
}: any) {
  // Estado local para manejar los valores de los inputs
  const [inputValues, setInputValues] = useState({});
  // Estado local para manejar la fecha de la entrevista
  const [interviewDate, setInterviewDate] = useState("");

  // Acceso al estado de Redux para colaboradores
  const { User, handleGetCollaborators, Meta } = useCollaboratorRedux();

  /**
   * Manejador de cambios en el campo de fecha
   *
   * @param {Object} e - Evento de cambio
   */
  const onChangeTextField = (e: any) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario
    setInterviewDate(e.target.value); // Actualiza el estado de la fecha de entrevista
  };

  // useEffect comentado que podría usarse para depuración o futuras funcionalidades
  // useEffect(() => {
  //   console.log("aquiiiiii ", Meta.currentPage);
  // }, [Meta]);

  /**
   * Manejador de envío del formulario para agendar la entrevista
   *
   * @param {Object} e - Evento de envío
   */
  const onSubmit = async (e: any) => {
    // Realiza la petición para agendar la entrevista
    await postInterviewServices(evaluation.employeeEvaluationId, interviewDate)
      .then((res) => {
        setSuccess(true); // Establece el estado de éxito
        setMessage("Se ha agendado la entrevista correctamente."); // Establece el mensaje de éxito
      })
      .catch((err) => {
        setError(true); // Establece el estado de error
        setMessage("Se ha agendado la entrevista correctamente."); // Establece el mensaje de error (posiblemente un error en el mensaje)
      });

    // Valida el ID del periodo de evaluación
    const periodo = validateIdPeriod(evaluation_period_id);

    // Actualiza la lista de colaboradores según los filtros aplicados
    handleGetCollaborators(
      filterSearch === "" || filterSearch === null
        ? `?NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=${Meta.currentPage}`
        : filterSearch
    );
    // Ejemplo de cómo podría ser una query string comentada:
    // `?IdPeriod=${evaluation.evaluationPeriod.evaluationPeriodId}&&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${Meta?.currentPage}`

    setSuccess(true); // Establece el estado de éxito nuevamente
    onHide(); // Oculta el modal
  };

  // console.log("Se setea el modal!!!"); // Log comentado para depuración

  return (
    // Modal de React Bootstrap que se muestra u oculta según la prop showBookInterview
    <Modal show={showBookInterview} size="lg" onHide={onHide} backdrop="static">
      <ContainModal>
        {/* Encabezado del modal con botón de cerrar */}
        <Modal.Header closeButton>
          <TitleModal style={{ width: "250px" }}>
            Selecciona la fecha de entrevista
          </TitleModal>
        </Modal.Header>
        {/* Cuerpo del modal */}
        <Modal.Body className="show-grid">
          <Container>
            <FliterByTiemRow>
              <StyleInputs>
                {/* Componente personalizado para seleccionar la fecha */}
                <Date
                  id="date"
                  title="Fecha"
                  name="date"
                  onChange={onChangeTextField} // Manejador de cambios
                  value={inputValues} // Valor actual del input
                />
              </StyleInputs>
              {/* Contenedor para los botones de acción */}
              <div
                style={{ marginBottom: "1rem", display: "flex", gap: "8px" }}
              >
                {/* Botón primario para agendar la cita */}
                <PrimaryButton onClick={onSubmit}>Agendar cita</PrimaryButton>
                {/* Botón secundario para cancelar y cerrar el modal */}
                <SecondaryButton onClick={onHide}>Cancelar</SecondaryButton>
              </div>
            </FliterByTiemRow>
          </Container>
        </Modal.Body>
      </ContainModal>
      {/* Modales comentados para mostrar estados de éxito o error */}
      {/* {success ? (
          <ModalStatusSuccessful
            handleClick={setSuccess}
            modalStatus={success}
            messageShow
            message={message}
          />
        ) : null}
        {error ? (
          <ModalStatusTargetError
            handleClick={setError}
            modalStatus={error}
            messageShow
            message={message}
          />
        ) : null} */}
    </Modal>
  );
}

export default BookingInterview; // Exporta el componente para su uso en otras partes de la aplicación
