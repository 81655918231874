import styled from "styled-components";

// Styles for a fixed header with a top margin
export const fixedHeader = styled.div`
  margin-top: 2%;
`;

// Container for the evaluated header with flexbox layout
export const EvaluatedHeaderContainer = styled.div`
  display: flex;
  /* gap: 12rem; */
  align-items: center;
  justify-content: space-between;
  height: 40px;
  /* margin-bottom: 24px; */

  .containerToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Responsive design for smaller screens
  @media (min-width: 320px) and (max-width: 1023px) {
    display: block;
  }
`;

// Styles for the header title with specific font settings
export const HeaderTitle = styled.h4`
  color: var(--primary-black, #000);
  font-feature-settings: "clig" off, "liga" off;

  /* H3 */
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  width: max-content;
  /* border: 1px solid; */
  /* width: 100%; */
  margin: 0px;
  /* line-height: 120%; 38.4px */
  // Responsive font size for smaller screens
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 17px;
  }
`;
// Container for buttons with flexbox layout and responsive design
export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  // Responsive layout for smaller screens
  @media (min-width: 320px) and (max-width: 1023px) {
    display: grid;
  }
`;
// Container for subtitles with margin adjustments for smaller screens
export const ContainSubTitle = styled.div`
  margin: 2% 0;
  // Margin adjustments for smaller screens
  @media screen and (max-width: 1023px) {
    margin: 0% 3%;
  }
`;
// Container for header evaluation buttons with flexbox layout
export const ContainButonsHeadrEva = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  gap: 14px;
`;
