/*
Deshabilita ciertas reglas de ESLint para este archivo.
- array-callback-return: Permite el uso de callbacks en arrays sin retornar explícitamente un valor.
- @typescript-eslint/no-unused-expressions: Permite expresiones que no retornan un valor.
- eqeqeq: Permite el uso de igualdad débil (== y !=) en lugar de estricta (=== y !==).
- react-hooks/exhaustive-deps: Deshabilita la verificación exhaustiva de dependencias en hooks de React.
*/
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

// Importaciones de hooks y componentes necesarios
import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { scopeTableData } from "../../../DataFake/tableDataFake";
import { ICurrentsObjetives } from "../../../Interface/ApiInterface.js";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import {
  BackTbale,
  TableDosWrapper,
  TableHeadingDos,
} from "./KpisTablesStyles";
import "./kpisStyle.css";

// ** Context
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../../context/EvaluacionContext/NewEvaluationContext";
import CardTableKpis from "./CardTableKpis";
import FooterTableKpis from "./FooterTableKpis";
import { porcentajeAlcanceDefault, porcentajeDeAlcanceGenerales, reglasKPIs, reglasKPIsDefault, suma } from "./utils";
import { ChangePeriodContext, ChangePeriodContextType } from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";

/**
* Componente principal que renderiza la tabla de KPIs (Indicadores Clave de Desempeño).
* @param {Object} props - Propiedades recibidas por el componente.
* @param {Function} props.addTotal - Función para agregar totales.
* @param {Object} props.evaluacion - Objeto que contiene la evaluación actual.
* @param {Function} props.setEvaluacion - Función para actualizar la evaluación.
* @param {Boolean} props.politicsValidation - Validación de políticas.
* @param {Array} props.preguntas - Array de preguntas relacionadas con la evaluación.
* @param {Boolean} props.loadInfo - Estado de carga de información.
* @param {Array} props.sumTotal - Array que contiene la suma total de los KPIs.
* @param {Function} props.setSumTotal - Función para actualizar la suma total.
*/
export const TableOne = ({
  addTotal,
  evaluacion,
  setEvaluacion,
  politicsValidation,
  preguntas,
  loadInfo,
  sumTotal,
  setSumTotal
}: any) => {
  // ** Redux: Obtener datos de la evaluación desde el store de Redux
  const { Evaluation } = useEvaluationRedux();

  // ** Context Cambio de periodo: Obtener estados relacionados con el cambio de periodo de evaluación
  const {
    totalConocimientoEmpresa,
    changePeriodEvaluation,
    totalSumaMetricas,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;

  // ** Estados locales
  const [loader, setLoader] = useState(false); // Estado para controlar el loader

  /**
  * Función para calcular el resultado basado en el total agregado.
  * @param {number} addTotal - Total agregado.
  * @returns {number} Resultado calculado.
  */
  const calculateRes = (addTotal: number) => {
    if (addTotal > 80) {
      return 120;
    }
    if (addTotal >= 60 && addTotal <= 80) {
      return 100;
    }
    if (addTotal >= 40 && addTotal <= 59) {
      return 80;
    }
    return 0;
  };

  // useEffect que actualiza la evaluación cuando cambia el objeto Evaluation desde Redux
  useEffect(() => {
    setEvaluacion(Evaluation);
  }, [Evaluation]);

  // ** Context: Obtener funciones y estados desde NewEvaluationContext
  const { setValidateFinishEv } = useContext(
    NewEvaluationContext
  ) as NewEvaluationContextType;

  // ** Context Validations: Obtener funciones y estados para validaciones desde NewEvaluationContext
  const {
    setValidateEmptyInput,
    objetivesCurrents,
    setObjetivesCurrents,
    setUserName,
    userName,
  } = useContext(NewEvaluationContext) as NewEvaluationContextType;

  /**
  * Función para establecer el resultado de un objetivo específico.
  * @param {ICurrentsObjetives} item - Objetivo actual.
  * @param {any} porcentaje - Porcentaje de alcance.
  * @param {any} index - Índice del objetivo en el array.
  */
  const setResultItem = (
    item: ICurrentsObjetives,
    porcentaje: any,
    index: any
  ) => {
    let result = 0;
    if (+porcentaje <= 79) {
      result = 0;
    }
    if (+porcentaje >= 80 && +porcentaje <= 99) {
      result = 0.8 * +item.employeeEvaluationObjetivesWorth;
    }
    if (+porcentaje >= 100 && +porcentaje < 120) {
      result = 1 * +item.employeeEvaluationObjetivesWorth;
    }
    if (+porcentaje >= 120) {
      result = 1.2 * +item.employeeEvaluationObjetivesWorth;
    }

    setEvaluacion((prevState: any) => {
      const newEval = { ...prevState };
      newEval.employeeEvaluationObjetivesCurrents[
        index
      ].employeeEvaluationObjetivesResult = result;
      return newEval;
    });
  };

  // useEffect que valida los objetivos actuales cada vez que cambian
  useEffect(() => {
    const handleValidationCurrentObjs = () => {
      if (
        objetivesCurrents?.includes(null) ||
        objetivesCurrents?.includes(undefined) ||
        objetivesCurrents?.includes("")
      ) {
        // Si alguno de los objetivos actuales es nulo, indefinido o una cadena vacía, marca como no válido
        setValidateEmptyInput(false);
      } else {
        // Si todos los objetivos actuales están completos, marca como válido
        setValidateEmptyInput(false);
      }
    };

    handleValidationCurrentObjs();
  }, [objetivesCurrents]);

  /**
  * Función para manejar los cambios en los inputs de la tabla de KPIs.
  * @param {Object} e - Evento del cambio.
  * @param {number} index - Índice del objetivo en el array.
  * @param {Object} item - Objetivo actual.
  * @param {boolean} [isDif] - Indicador de diferencia.
  */
  const handleChange = (e: any, index: number, item: any, isDif?: boolean) => {
    // e.preventDefault(); // Previene el comportamiento por defecto del evento (comentado)
    // console.log("Entra esto ->", e, index, item);

    const { value, name } = e.target;
    //! Validar currents objectives

    // Si no hay evaluación o no hay objetivos actuales, salir de la función
    if (!evaluacion || !evaluacion.employeeEvaluationObjetivesCurrents) return;

    // Calcula el porcentaje de alcance basado en los valores actuales
    const porcentaje = porcentajeDeAlcanceGenerales(
      +value,
      +item.employeeEvaluationObjetivesReal
    );

    // Si el nombre del input es "employeeEvaluationObjetivesGoal", actualizar el porcentaje y el resultado
    if (name === "employeeEvaluationObjetivesGoal") {
      setResultItem(item, porcentaje, index);
      setEvaluacion((prevState: any) => {
        const newEval = { ...prevState };
        newEval.employeeEvaluationObjetivesCurrents[
          index
        ].employeeEvaluationObjetivesPercentageReach = porcentaje;
        return newEval;
      });
    }

    // Caso específico para objetivos de tipo "D" con descripción relacionada a conocimiento de políticas
    if (
      name === "employeeEvaluationObjetivesWorth" &&
      item.employeeObjetivesType === "D" &&
      item.employeeEvaluationObjetivesDescription.includes(
        "Conocimiento de las políticas de la empresa"
      )
    ) {
      const porcentaje = 0.24 * +evaluacion.employeeEvaluationPolitics;
      setEvaluacion((prevState: any) => {
        const newEval = { ...prevState };
        const result = porcentaje * value;
        newEval.employeeEvaluationObjetivesCurrents[
          index
        ].employeeEvaluationObjetivesPercentageReach = porcentaje * 100;
        newEval.employeeEvaluationObjetivesCurrents[
          index
        ].employeeEvaluationObjetivesResult = result;
        return newEval;
      });
    }

    // Caso específico para objetivos de tipo "D" con descripción relacionada a desempeño laboral, humano, actitud y habilidades
    if (
      name === "employeeEvaluationObjetivesWorth" &&
      item.employeeObjetivesType === "D" &&
      item.employeeEvaluationObjetivesDescription.includes(
        "Desempeño laboral, humano, actitud y habilidades"
      )
    ) {
      const res = calculateRes(addTotal);

      setEvaluacion((prevState: any) => {
        const newEval = { ...prevState };
        const result = (res * item.employeeEvaluationObjetivesWorth) / 100;

        newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = res;
        newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesResult = result;
        return newEval;
      });
    }

    // Actualiza el valor del objetivo en la evaluación
    setEvaluacion((prevState: any) => {
      const newEval = { ...prevState };
      newEval.employeeEvaluationObjetivesCurrents[index][name] = +value;
      return newEval;
    });

    // Si `isDif` es verdadero, maneja cambios adicionales
    if (isDif) {
      if (e.target.name === "employeeEvaluationObjetivesReal") {
        const valuesEdit = objetivesCurrents.map((obj: any, i: number) => {
          return i === index ? String(e.target.value) : obj;
        });

        // console.log("Entra el valor!!", valuesEdit);
        setObjetivesCurrents(valuesEdit);

        const porcentaje = porcentajeDeAlcanceGenerales(
          item.employeeEvaluationObjetivesGoal,
          String(e.target.value)
        );

        // console.log("Entra esto y retornamos esto -> ", porcentaje);

        if (porcentaje !== Infinity) {
          // console.log("Entramos a este caso!");
          setResultItem(item, porcentaje, index);
          setEvaluacion((prevState: any) => {
            const newEval = { ...prevState };
            newEval.employeeEvaluationObjetivesCurrents[
              index
            ].employeeEvaluationObjetivesPercentageReach = porcentaje;
            return newEval;
          });
        } else {
          // console.log("Entramos a este caso!");

          setEvaluacion((prevState: any) => {
            const newEval = { ...prevState };
            newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = 0;
            return newEval;
          });
        }
      }
    }
  };

  // useEffect que actualiza ciertos campos de la evaluación cuando cambian los objetivos actuales
  useEffect(() => {
    if (evaluacion && evaluacion.employeeEvaluationObjetivesCurrents) {
      evaluacion?.employeeEvaluationObjetivesCurrents?.map(
        (item: ICurrentsObjetives, index: number) => {
          // Caso específico para objetivos de tipo "D" con descripción relacionada a conocimiento de políticas
          if (
            item.employeeObjetivesType === "D" &&
            item.employeeEvaluationObjetivesDescription.includes(
              "Conocimiento de las políticas de la empresa"
            )
          ) {
            const porcentaje = 0.24 * +evaluacion.employeeEvaluationPolitics;
            setEvaluacion((prevState: any) => {
              const newEval = { ...evaluacion };
              const result = porcentaje * item.employeeEvaluationObjetivesWorth;

              newEval.employeeEvaluationObjetivesCurrents[
                index
              ].employeeEvaluationObjetivesPercentageReach = porcentaje * 100;
              newEval.employeeEvaluationObjetivesCurrents[
                index
              ].employeeEvaluationObjetivesResult = result;
              return newEval;
            });
          }

          // Caso específico para objetivos de tipo "D" con descripción relacionada a desempeño laboral, humano, actitud y habilidades
          if (
            item.employeeObjetivesType === "D" &&
            item.employeeEvaluationObjetivesDescription.includes(
              "Desempeño laboral, humano, actitud y habilidades"
            )
          ) {
            const res = calculateRes(addTotal);

            setEvaluacion((prevState: any) => {
              const newEval = { ...prevState };
              const result = (res * item.employeeEvaluationObjetivesWorth) / 100;

              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = res;
              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesResult = result;
              return newEval;
            });
          }

          // Caso para objetivos de tipo "G" o "I"
          if (item.employeeObjetivesType === "G" || item.employeeObjetivesType === "I") {
            setEvaluacion((prevState: any) => {
              const newEval = { ...prevState };
              const resultCurrent = item.employeeEvaluationObjetivesPercentageReach;

               if (!newEval.employeeEvaluationObjetivesCurrents
                   || !newEval.employeeEvaluationObjetivesCurrents[index]) {
                   console.warn("No hay 'employeeEvaluationObjetivesCurrents[index]' para asignar employeeEvaluationObjetivesPercentageReach", {
                     index,
                     item,
                     newEval
                   });
                   return prevState; // Evita que crashe la app
                 }

              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = resultCurrent;
              return newEval;
            });
          }
        }
      );
    }
  }, [evaluacion.employeeEvaluationObjetivesCurrents]);

  // useEffect similar al anterior, probablemente redundante (puede ser optimizado)
  useEffect(() => {
    if (evaluacion && evaluacion.employeeEvaluationObjetivesCurrents) {
      evaluacion?.employeeEvaluationObjetivesCurrents?.map(
        (item: ICurrentsObjetives, index: number) => {
          // Caso específico para objetivos de tipo "D" con descripción relacionada a conocimiento de políticas
          if (
            item.employeeObjetivesType === "D" &&
            item.employeeEvaluationObjetivesDescription.includes(
              "Conocimiento de las políticas de la empresa"
            )
          ) {
            const porcentaje = 0.24 * +evaluacion.employeeEvaluationPolitics;
            setEvaluacion((prevState: any) => {
              const newEval = { ...prevState };
              const result = porcentaje * item.employeeEvaluationObjetivesWorth;
              const alcance = porcentaje * 100;

              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = alcance;
              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesResult = result;
              return newEval;
            });
          }

          // Caso específico para objetivos de tipo "D" con descripción relacionada a desempeño laboral, humano, actitud y habilidades
          if (
            item.employeeObjetivesType === "D" &&
            item.employeeEvaluationObjetivesDescription.includes(
              "Desempeño laboral, humano, actitud y habilidades"
            )
          ) {
            const res = calculateRes(addTotal);

            setEvaluacion((prevState: any) => {
              const newEval = { ...prevState };
              const result = (res * item.employeeEvaluationObjetivesWorth) / 100;

              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = res;
              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesResult = result;
              return newEval;
            });
          }

          // Caso para objetivos de tipo "G" o "I"
          if (item.employeeObjetivesType === "G" || item.employeeObjetivesType === "I") {
            setEvaluacion((prevState: any) => {
              const newEval = { ...prevState };
              const resultCurrent = item.employeeEvaluationObjetivesPercentageReach;

              newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = resultCurrent;
              return newEval;
            });
          }
        }
      );
    }
  }, [evaluacion?.employeeEvaluationObjetivesCurrents]);

  // useEffect adicional que parece duplicar la lógica anterior, podría ser optimizado
  useEffect(() => {
    if (!evaluacion || !evaluacion.employeeEvaluationObjetivesCurrents) return;

    // Recorre los objetivos actuales para actualizar ciertos campos basados en condiciones específicas
    evaluacion.employeeEvaluationObjetivesCurrents?.map(
      (item: ICurrentsObjetives, index: number) => {
        // Caso específico para objetivos de tipo "D" con descripción relacionada a conocimiento de políticas
        if (
          item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Conocimiento de las políticas de la empresa"
          )
        ) {
          const porcentaje = 0.24 * +evaluacion.employeeEvaluationPolitics;
          setEvaluacion((prevState: any) => {
            const newEval = { ...prevState };
            const result = porcentaje * item.employeeEvaluationObjetivesWorth;
            newEval.employeeEvaluationObjetivesCurrents[
              index
            ].employeeEvaluationObjetivesPercentageReach = porcentaje * 100;
            newEval.employeeEvaluationObjetivesCurrents[
              index
            ].employeeEvaluationObjetivesResult = result;
            return newEval;
          });
        }

        // Caso específico para objetivos de tipo "D" con descripción relacionada a desempeño laboral, humano, actitud y habilidades
        if (
          item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Desempeño laboral, humano, actitud y habilidades"
          )
        ) {
          const res = calculateRes(addTotal);

          setEvaluacion((prevState: any) => {
            const newEval = { ...prevState };
            const result = (res * item.employeeEvaluationObjetivesWorth) / 100;

            newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = res;
            newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesResult = result;
            return newEval;
          });
        }

        // Caso para objetivos de tipo "G" o "I"
        if (item.employeeObjetivesType === "G" || item.employeeObjetivesType === "I") {
          setEvaluacion((prevState: any) => {
            const newEval = { ...prevState };
            const resultCurrent = item.employeeEvaluationObjetivesPercentageReach;

            newEval.employeeEvaluationObjetivesCurrents[index].employeeEvaluationObjetivesPercentageReach = resultCurrent;
            return newEval;
          });
        }
      }
    );
  }, [addTotal]);

  /**
  * Función para validar que la suma de los porcentajes de los objetivos sea 100%.
  * Si no es así, marca la evaluación como no finalizada.
  */
  const validateSuma = () => {
    if (evaluacion?.employeeEvaluationObjetivesCurrents) {
      if (
        suma(
          evaluacion?.employeeEvaluationObjetivesCurrents?.map(
            (item: ICurrentsObjetives) => +item.employeeEvaluationObjetivesWorth
          )
        ) === 100
      ) {
        setValidateFinishEv(false); // La suma es 100%, evaluación puede finalizarse
      } else {
        setValidateFinishEv(true); // La suma no es 100%, evaluación no puede finalizarse
      }
    } else {
      setValidateFinishEv(false); // No hay objetivos actuales, no se puede finalizar la evaluación
    }
  };

  // useEffect que ejecuta la validación de la suma cada vez que cambia la evaluación
  useEffect(() => {
    validateSuma();
  }, [evaluacion]);

  // useEffect que inicializa los objetivos actuales y controla el loader basado en el nombre del usuario
  useEffect(() => {
    if (Evaluation?.employeeEvaluationObjetivesCurrents) {
      const valuesEdit = Evaluation.employeeEvaluationObjetivesCurrents.map(
        (obj: any) =>
          obj.employeeObjetivesType !== "D"
            ? obj.employeeEvaluationObjetivesReal
            : 0
      );

      // console.log("Estos datos se cargarán",  Evaluation.employeeEvaluationEvaluatorUser.nombre,userName !== Evaluation.employeeEvaluationEvaluatorUser.nombre, userName);

      setObjetivesCurrents(valuesEdit);

      // Si el nombre de usuario actual no coincide con el evaluador, muestra el loader
      if (userName !== Evaluation.employeeEvaluationEvaluatorUser.nombre) {
        setLoader(true);

        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    }
  }, [Evaluation]);

  // console.log("Estos datos se cargarán",Evaluation.employeeEvaluationObjetivesCurrents);

  // useEffect que actualiza el nombre de usuario cuando cambia la evaluación
  useEffect(() => {
    if (Evaluation?.employeeEvaluationEvaluatorUser?.nombre) {
      setUserName(Evaluation.employeeEvaluationEvaluatorUser.nombre);
    }
  }, [Evaluation]);

  // useEffect que controla el loader al montar el componente
  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  // Estado local para almacenar la suma de métricas
  const [sumaDeMetricas, setSumaDeMetricas] = useState<number | null>();

  /**
  * useEffect que calcula la suma de métricas cada vez que cambia la evaluación.
  */
  useEffect(() => {
    const sumarMetricas = () => {
      const sumaDeMet =
        (suma(
          preguntas?.map(
            (item: any) => item?.evaluatorEvaluationResponseQuestion
          )
        ) /
          (Evaluation?.employeEvaluationQuestions?.length * 5)) *
        100;
      return sumaDeMet;
    };
    setSumaDeMetricas(sumarMetricas());
  }, [evaluacion]);

  return (
    <>
      {/* Renderiza la tabla solo si no está cargando */}
      {!loader && (
        <TableDosWrapper>
          <>
            {/* Encabezado de la tabla */}
            <TableHeadingDos>
              <div className="first">
                {/* Muestra el semestre y año de evaluación */}
                Sem{Evaluation?.period?.evaluationPeriodNumberOfYear}
                {`.`}
                {Evaluation?.period?.evaluationPeriodYear}
              </div>
              <div className="second">Valor</div>
              <div className="third">Descripción de objetivos</div>
              <div className="fourth">Meta</div>
              <div className="fifth">Calificación</div>
              <div className="sixth">% Alcance</div>
              <div className="seventh">Resultado</div>
            </TableHeadingDos>

            {/* Renderiza cada objetivo actual utilizando el componente CardTableKpis */}
            {(Evaluation?.employeeEvaluationObjetivesCurrents
              && Evaluation?.employeeEvaluationPolitics !== undefined
              && Array.isArray(evaluacion.employeeEvaluationObjetivesCurrents)
              && sumaDeMetricas !== undefined)
              ? Evaluation?.employeeEvaluationObjetivesCurrents?.map(
                (item: any, index: number) => (
                  <CardTableKpis
                    activeOrBlockFirm={
                      objetivesCurrents[index] === "" ||
                      objetivesCurrents[index] === null ||
                      objetivesCurrents[index] < 1
                    }
                    item={item}
                    index={index}
                    handleChange={handleChange}
                    preguntas={preguntas}
                    loader={loader}
                    setSumTotal={setSumTotal}
                    sumTotal={sumTotal}
                    evaluacion={evaluacion}
                    totalConocimientoEmpresa={Evaluation?.employeeEvaluationPolitics}
                    sumaDeMetricas={sumaDeMetricas}
                    setSumaDeMetricas={setSumaDeMetricas}
                  />
                )
              )
              : null
            }

            {/* Footer de la tabla que muestra la suma total */}
            <FooterTableKpis
              politicsValidation={politicsValidation}
              evaluacion={evaluacion}
              objetivesCurrents={objetivesCurrents}
              sumTotal={sumTotal}
            />
          </>
        </TableDosWrapper>
      )}
    </>
  );
};

/**
* Componente que renderiza una tabla adicional relacionada con el alcance de los KPIs.
*/
export const TableTrhee = () => {
  return (
    <>
      <Table style={BackTbale}>
        <thead>
          <tr>
            <th>Alcance</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* Recorre los datos de alcance y los muestra en filas de la tabla */}
          {scopeTableData.map((data) => (
            <tr key={data.id}>
              <td style={{ color: data.value }}>
                <strong>{data.scope}</strong>
              </td>
              <td>{data.scopeDesc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
