import { useContext } from "react";
import editObj from "../../../assets/icons/EditObj.svg"; // Icono de edición
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext"; // Contexto para evaluaciones
import { useEditFutureObjetive } from "../../../hooks/Evaluation"; // Hook personalizado para edición
import { useCollaboratorRedux } from "../../../redux/Collaborators"; // Hook de Redux para colaboradores
import { useEvaluationRedux } from "../../../redux/Evaluations"; // Hook de Redux para evaluaciones
import {
  EditContainer,
  EditObjectiveContainer,
} from "../Dashboard/stylesDashboard/AdminStyles"; // Estilos importados

// Componente EditObjetiveFutures
// Permite mostrar y activar la edición de objetivos futuros
const EditObjetiveFutures = ({
  setEditObjective, // Función para activar el modo de edición
  index,            // Índice del elemento actual en el array de datos
  data,             // Array de datos que contienen los objetivos
  setIndexEdit,     // Función para establecer el índice en edición
}: any) => {
  // ** Redux: Obtener datos del estado global de colaboradores
  const { evaluationAttribute, User } = useCollaboratorRedux();

  // ** Contexto: Obtener permisos o estados para habilitar inputs
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  // ** Hook personalizado: Verificar si la edición de objetivos futuros está habilitada
  const { editeFutureObjetive } = useEditFutureObjetive();

  // ** Redux: Obtener estado de evaluaciones
  const { Evaluation } = useEvaluationRedux();

  // Función para manejar el clic en el ícono de edición
  const handleClick = () => {
    setEditObjective(true); // Activar modo de edición
    setIndexEdit(index);    // Actualizar el índice del objetivo a editar
  };

  return (
    <EditContainer>
      {/* Contenedor que muestra la descripción del objetivo futuro */}
      <EditObjectiveContainer>
        {data.length > 0 && data[index]?.descripcion?.label} {/* Mostrar texto si hay datos */}
      </EditObjectiveContainer>

      {/* Mostrar el icono de edición si la edición no está habilitada */}
      {!editeFutureObjetive && (
        <div style={{ cursor: "pointer" }} onClick={() => handleClick()}>
          <img
            src={editObj}          // Icono de edición
            alt="editObjetive"     // Texto alternativo para accesibilidad
            style={{ height: "12px", width: "12px" }} // Tamaño del icono
          />
        </div>
      )}
    </EditContainer>
  );
};

export default EditObjetiveFutures;
