import styled from "styled-components";

type PropTypeBgTres = {
  showAlert?: any; // Tipo de propiedad para mostrar alerta
};

export const CardsBox = styled.div`
  padding: 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
`; // Estilo para el contenedor de las tarjetas

export const CardFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`; // Estilo para el contenedor flexible de las tarjetas, cambia a fila en pantallas grandes

export const CardRow = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`; // Estilo para la fila de tarjetas, se muestra como flex en pantallas grandes

export const CardCol = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
    padding-left: 3rem;
  }
`; // Estilo para las columnas de las tarjetas, cambia el ancho en pantallas grandes

export const ColabInfoTitle = styled.h6`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #3b63a2;
  margin-bottom: 2rem;
`; // Estilo para el título de la información del colaborador

export const CardTitle = styled.h6`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
`; // Estilo para el título de la tarjeta

export const CardTitleLarge = styled.h6`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
`; // Estilo para el título grande de la tarjeta

//!! ---- New Styles ----

export const NewCardContainerRight = styled.div`
  border-top: 2px solid #f7f7f7;
  width: 96%;
  padding-top: 16px;

  .bodyCard {
    display: flex;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    border: none;
  }
`; // Estilo para el nuevo contenedor de tarjeta a la derecha

export const NewCardContainerLeft = styled.div`
  border-top: 2px solid #f7f7f7;
  width: 96%;
  padding-top: 16px;

  .bodyCard {
    display: flex;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
   border: none;
  }
`; // Estilo para el nuevo contenedor de tarjeta a la izquierda

export const CardColBorderR = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 50%;
  border-right: 2px solid #f7f7f7;

  @media screen and (max-width: 768px) {
   border: none;
  }
`; // Estilo para la columna de la tarjeta con borde derecho

export const CardColNew = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
    padding-left: 18px;
  }
`; // Estilo para la nueva columna de la tarjeta

export const ButtonContainer = styled.div`
  display: flex;
  gap:24px;
  align-items: center;
`; // Estilo para el contenedor de botones

export const ButonTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`; // Estilo para el contenedor del título y botones

export const ColabInfoTitleNew = styled.div`
  /* H3 */
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 38.4px */
  margin: none;
  margin-bottom: 24px;


  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`; // Estilo para el nuevo título de la información del colaborador

export const ButtonDescargar = styled.button`
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #C9F2FF;
    border-style: solid;
    background: var(--secondary-blizzard-blue, #C9F2FF);
    color: var(--neutrals-white, #3B63A2);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Small Body Bold */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
`; // Estilo para el botón de descargar historial

export const ButtonDescargarEvaluacion = styled.button`
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #3B63A2;
    border-style: solid;
    background: var(--primary-true-blue, #3B63A2);
    color: var(--neutrals-white, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Small Body Bold */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
`; // Estilo para el botón de descargar evaluación

export const AlertContainer = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`

  /* Body Bold */
  display: ${(props) => props.showAlert ? "block": "none"};
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #F20505;
  line-height: 120%;
  margin-bottom: 24px;

`; // Estilo para el contenedor de alerta



export const CardsBoxNew = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  padding: 24px;
  width: 100%;
  background: #ffffff;
  border: ${(props) => props.showAlert ? "1px solid red": "none "};
  /* border-radius: 16px; */
`; // Estilo para el nuevo contenedor de tarjetas


//!! ---- New Styles ----


//! ---  Style For KPis table ---


export const AlertContainerKpis = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`

  /* Body Bold */
  /* display: ${(props) => props.showAlert ? "block": "none"}; */
  visibility: ${(props) => props.showAlert ? "visible": "hidden"} ;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #F20505;
  width: max-content;

  /* line-height: 120%;  */
  /* margin-bottom: 24px; */

`; // Estilo para el contenedor de alerta de KPIs

export const TableSumDosKpis = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  background-color: #f8f8f8;
  /* color: #ffffff; */
  border-radius: 0px;
  padding: 14px 8px;
  display: flex;

  margin-bottom: 2px;
  width: 100%;
  justify-content: space-between;
  border: ${(props) => props.showAlert ? " 1px solid red": "none"}
 ;

  .first {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .third {
    width: 30%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    /* border: 1px solid red; */
  }

  .fourth {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sixth {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seventh {
    width: 10%;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1700px) {

  justify-content: space-between;

  .first {
    width: 6%;
    /* border: 1px solid green; */
  }

  .second {
    width: 4%;
    /* border: 1px solid green; */
    margin-left: 20px;
  }


  .third {
    width: 30%;
    /* border: 1px solid green; */
    margin-right: 80px
  }

  .fourth {
    width: 10%;
    /* border: 1px solid green; */
  }

  .fifth {
    width: 10%;
    /* border: 1px solid green; */
  }

  .sixth {
    width: 15%;
    /* border: 1px solid green; */
  }

  .seventh {
    width: 10%;
    /* border: 1px solid green; */
  }
  }


  @media screen and (min-width: 1950px) {
    .second {
    width: 4%;
    /* border: 1px solid green; */
    margin-left: 20px;
  }


  .third {
    width: 30%;
    /* border: 1px solid green; */
    margin-right: 120px
  }
  }

`; // Estilo para la tabla de sumas de KPIs
