import { useEffect, useState } from "react";
import styled from "styled-components";

// Componente principal FooterModalMySelect
// Recibe datos del front, del back y valores de validación como props
const FooterModalMySelect = ({
    dataFront,        // Array de datos ingresados desde el front
    dataBack,         // Array de datos provenientes del back-end
    inputValue,       // Valor ingresado en un input
    validationSuma,   // Validación booleana para verificar si la suma es correcta
    objetivesTypeG,   // Array de valores adicionales
    validationMeta,   // Validación booleana para verificar si la meta es mayor a 0
}: any) => {

    // Calcular la suma total de los valores en dataFront
    const valuesDataToNumber = dataFront
        .map((item: any) => Number(item.valor))     // Convertir valores a número
        .reduce((a: any, b: any) => a + (b || 0), 0); // Sumar los valores (ignorando nulos)

    // Convertir el valor del input a número
    const desempenioValueToNumber = Number(inputValue);

    // Calcular la suma total de los valores en objetivesTypeG
    const objetivesTypeGToNumber = objetivesTypeG
        .map((item: any) => Number(item.valor))     // Convertir valores a número
        .reduce((a: any, b: any) => a + (b || 0), 0); // Sumar los valores

    // Filtrar los valores numéricos específicos del tipo "D" en dataBack y sumarlos
    const dataBackOnlyNumber = dataBack
        .filter((obj: any) => obj.type === "D")     // Filtrar objetos con tipo "D"
        .map((item: any) => Number(item.valor))     // Convertir valores a número
        .reduce((a: any, b: any) => a + (b || 0), 0); // Sumar los valores

    // Renderizado del componente
    return (
        <FooterWrapper colorBorber={validationSuma}>
            {/* Mostrar la suma total calculada */}
            <div className="second">
                {valuesDataToNumber +
                    dataBackOnlyNumber +
                    desempenioValueToNumber +
                    objetivesTypeGToNumber}%
            </div>

            {/* Sección de validaciones */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "58%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    gap: "8px",
                }}
            >
                {/* Mostrar mensaje si la suma no es igual a 100% */}
                {validationSuma && <div className="third">La suma debe ser igual a 100%</div>}
                {/* Mostrar mensaje si la meta es menor o igual a 0% */}
                {!validationMeta && <div className="third">La meta debe ser mayor a 0%</div>}
            </div>
        </FooterWrapper>
    );
};

export default FooterModalMySelect;



//* Este componente recibe 3 prop:
//* PRIMERA: Data que nosotros seteamos en el front
//* SEGUNDA: Data que recibimos en el back
//* TERCERA: Data única, es un input


type PropTypeBgTres = {
    colorBorber?: boolean; // Prop para aplicar estilos condicionales según la validación
};

// Contenedor principal con estilos dinámicos
const FooterWrapper = styled.div.attrs((props: PropTypeBgTres) => ({}))<PropTypeBgTres>`
    display: flex;                    // Alinear contenido en fila
    align-items: center;              // Centrar verticalmente
    height: 68px;                     // Altura fija
    font-weight: 700;                 // Texto en negrita
    width: 100%;                      // Ancho completo
    margin: 3px 0;                    // Márgenes superior e inferior
    border-radius: 2px;               // Bordes ligeramente redondeados
    font-family: "Plus Jakarta Sans"; // Fuente personalizada
    font-size: 14px;                  // Tamaño de texto
    color: ${(props) => (props.colorBorber ? "red" : "black")}; // Color de texto dinámico
    letter-spacing: 0.75px;           // Espaciado entre letras
    border: ${(props) => (props.colorBorber ? "1px solid red" : "none")}; // Borde condicional

    // Estilos para el contenedor de la suma total
    .second {
        width: 30%;                  // Ocupa el 30% del ancho total
        display: flex;               // Flexbox
        justify-content: end;        // Alineación a la derecha
    }

    // Estilos para los mensajes de validación
    .third {
        width: 100%;                 // Ocupa todo el ancho disponible
        display: flex;               // Flexbox
        justify-content: center;     // Centrar contenido horizontalmente
    }
`;
