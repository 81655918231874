import styled from "styled-components"; // Importa la librería styled-components para crear componentes con estilos CSS.

// Exporta un objeto llamado boxCard que puede utilizarse para aplicar estilos.
// Actualmente, las propiedades están comentadas.
export const boxCard = {
  // width: 'fit-content', // Define el ancho del contenedor al contenido.
  // padding: '8px 40px', // Agrega espaciado interno superior/inferior y lateral.
  // borderRight: '0.548503px solid #E5E5EF', // Aplica un borde derecho con un color definido.
};

// Exporta un objeto llamado cardBody que define el estilo del cuerpo de una tarjeta.
export const cardBody = {
  padding: "0", // Elimina cualquier relleno interno.
  display: "flex", // Activa el modelo de caja flexible.
  alignItems: "center", // Centra los elementos hijos verticalmente.
  // gap: "1%", // Espacio entre elementos hijos (comentado).
};

// Exporta un objeto llamado underlineTitle que puede ser utilizado para estilos de título.
export const underlineTitle = {
  // borderBottom: "0.548503px solid #E5E5EF", // Agrega un borde inferior (comentado).
  display: "flex", // Activa el modelo de caja flexible.
  justifyContent: "center", // Centra el contenido horizontalmente.
  fontFamily: "Plus Jakarta Sans", // Define la fuente a utilizar.
  fontSize: "18px", // Define el tamaño de fuente.
  fontWeight: "700" // Aplica negrita al texto.
};

// Define un componente DashboarCardItem usando styled-components.
// Este componente es un contenedor con estilos predefinidos.
export const DashboarCardItem = styled.div`
  /* width: 28rem; */ // Define un ancho fijo (comentado).
  display: flex; // Activa el modelo de caja flexible.
  width: 362px; // Define el ancho del contenedor.
  padding: 16px; // Aplica relleno interno en todas las direcciones.
  flex-direction: column; // Coloca los elementos hijos en columna.
  align-items: flex-start; // Alinea los elementos hijos al inicio del eje transversal.
  gap: 8px; // Define un espacio entre elementos.
  border-radius: 16px; // Aplica bordes redondeados.
  background: var(--background-light-gray, #f8f8f8); // Define un color de fondo con variable CSS.
  box-shadow: 0px 4px 8px 0px rgba(6, 17, 46, 0.18); // Aplica una sombra al contenedor.
`;

// Define un componente DataBox como un contenedor flexible.
export const DataBox = styled.div`
  display: flex; // Activa el modelo de caja flexible.
  flex-direction: column; // Alinea los elementos en columna.
  /* width: 100%; */ // Define el ancho al 100% (comentado).
  gap: 1rem; // Espaciado entre elementos.
`;

// Define un componente ItemBox como un contenedor flexible para elementos.
export const ItemBox = styled.div`
  display: flex; // Activa el modelo de caja flexible.
  align-items: center; // Alinea los elementos al centro verticalmente.
  width: 100%; // Define el ancho al 100%.
  gap: 4.39px; // Espacio entre los elementos hijos.
`;

// Define un párrafo estilizado para el estado "Completed".
export const Completed = styled.p`
  color: #F27405; // Define el color del texto.

  /* Label */
  font-family: Plus Jakarta Sans; // Define la fuente del texto.
  font-size: 14px; // Define el tamaño de la fuente.
  font-style: normal; // Aplica el estilo normal a la fuente.
  font-weight: 400; // Define un peso normal de la fuente.
  margin: 0px; // Elimina cualquier margen por defecto.
`;

// Define un párrafo estilizado para el estado "Missing".
export const Missing = styled.p`
  margin: 0; // Elimina cualquier margen por defecto.
  color: #939396; // Define el color del texto.
`;

// Define un componente GoalTitleCard para títulos de tarjeta.
export const GoalTitleCard = styled.p`
  font-weight: 700; // Aplica negrita al texto.
  font-size: 18px; // Define el tamaño de la fuente.
  /* line-height: 120%; */ // Define el interlineado (comentado).
  color: #3b63a2; // Define el color del texto.
`;
