import styled from "styled-components"; // Importa la librería styled-components para crear componentes con estilos CSS.

// Define un contenedor con estilos para la caja de descarga de reportes.
export const DownLoadReportBox = styled.div`
  padding: 0px; // Elimina el relleno predeterminado.
  gap: 24px; // Define el espacio entre elementos.
  background: #ffffff; // Aplica un color de fondo blanco.
  /* border-radius: 16px; */ // Bordes redondeados (comentado).
  font-family: "Plus Jakarta Sans"; // Aplica una fuente personalizada.
  margin-top: 48px; // Margen superior de 48px.
  margin-bottom: 48px; // Margen inferior de 48px.
  /* border: 1px solid red; */ // Borde rojo (comentado).
  padding-bottom: 40px; // Aplica relleno inferior.
  border-bottom: 2px solid #F0F0F0; // Borde inferior con color gris claro.
`;

// Define un título estilizado para la sección de descarga de reportes.
export const DownloadReportTitle = styled.h3`
  color: var(--primary-true-blue, #3b63a2); // Aplica un color azul primario.
  font-feature-settings: "clig" off, "liga" off; // Configuraciones de fuente (ligaduras desactivadas).

  /* H4 */
  font-family: Plus Jakarta Sans; // Fuente personalizada.
  font-size: 28px; // Tamaño de fuente de 28px.
  font-style: normal; // Estilo normal.
  font-weight: 700; // Fuente en negrita.
  line-height: 120%; /* 33.6px */ // Altura de línea del 120%.
  margin-bottom: 20px; // Margen inferior de 20px.
`;

// Define una sección de búsqueda con un formulario flexible.
export const SearchSection = styled.form`
  display: flex; // Activa el modelo de caja flexible.
  align-items: center; // Alinea los elementos verticalmente al centro.
  gap: 0.5rem; // Define un espacio entre elementos.
  background: #ffffff; // Fondo blanco.
  width: 100%; // Ocupar el 100% del ancho.
  @media screen and (max-width: 1024px) {
    flex-direction: column; // Cambia a una columna en pantallas pequeñas.
  }
`;

// Define una etiqueta estilizada para la sección de búsqueda.
export const SearchLabel = styled.label`
  width: 51px; // Ancho fijo de 51px.
  height: 36px; // Altura fija de 36px.
  padding: 8px 12px; // Espaciado interno.
  background: #f0f0f0; // Color de fondo gris claro.
  border-radius: 0px 4px 4px 0px; // Bordes redondeados solo en la esquina derecha.
`;

// Objeto de estilo adicional para ajustes específicos de la etiqueta de búsqueda.
export const FixSearchLabel = {
  width: "auto", // Ancho automático.
  height: "38px", // Altura fija.
  padding: " 8px 12px", // Espaciado interno.
  background: "#f0f0f0", // Color de fondo gris claro.
};

// Define opciones estilizadas para un elemento select.
export const SearchSelectOption = styled.option`
  font-weight: 400; // Fuente normal.
  font-size: 14px; // Tamaño de fuente de 14px.
  line-height: 140%; // Altura de línea del 140%.
  display: flex; // Activa el modelo de caja flexible.
  align-items: center; // Alinea el texto verticalmente al centro.
  color: #939396; // Color de texto gris.
`;

// Define un contenedor flexible para botones.
export const WrapperButtons = styled.div`
  display: flex; // Activa el modelo de caja flexible.
  /* align-items: center; */ // Comentado: Alineación vertical al centro.
  justify-content: flex-end; // Alinea los elementos al final del eje principal.
  gap: 8px; // Espaciado entre botones.
  width: 100%; // Ocupar el 100% del ancho.

  /* border: 1px solid red; */ // Borde rojo (comentado).
  padding-top: 16px; // Relleno superior de 16px.
  align-items: flex-end; // Alinea elementos al final del eje transversal.
  align-content: flex-end; // Ajusta contenido al final.
  @media screen and (max-width: 1024px) {
    flex-direction: column; // Columna en pantallas pequeñas.
    width: 100%; // Ancho del 100%.
  }
`;

// Define un botón estilizado para limpiar filtros o formularios.
export const CleanButton = styled.button`
  /* width: 100px; */ // Comentado: Ancho fijo.
  padding: 8px 24px; // Espaciado interno.
  border: none; // Sin borde.
  background: #c9f2ff; // Color de fondo celeste.
  color: #3b63a2; // Color de texto azul.
  font-size: 14px; // Tamaño de fuente de 14px.
  font-weight: 700; // Fuente en negrita.
  border-radius: 8px; // Bordes redondeados.
  line-height: 16px; // Altura de línea.
  @media screen and (max-width: 1024px) {
    width: 100%; // Ocupar el ancho completo en pantallas pequeñas.
  }
`;

// Define un botón estilizado para acciones generales (botón principal).
export const StyledButton = styled.button`
  /* width: 100px; */ // Comentado: Ancho fijo.
  padding: 8px 24px; // Espaciado interno.
  border: none; // Sin borde.
  background: #3b63a2; // Color de fondo azul.
  color: #ffffff; // Color de texto blanco.
  font-size: 14px; // Tamaño de fuente.
  font-weight: 700; // Fuente en negrita.
  border-radius: 8px; // Bordes redondeados.
  line-height: 16px; // Altura de línea.

  @media screen and (max-width: 1024px) {
    width: 100%; // Ancho completo en pantallas pequeñas.
  }
`;

// Define un elemento select estilizado para opciones de descarga.
export const SelectDonwload = styled.select`
  display: flex; // Activa el modelo de caja flexible.
  width: 100%; // Ancho completo.
  height: 36px; // Altura fija.
  /* padding: 0px 12px; */ // Comentado: Espaciado interno.
  /* align-items: center; */ // Comentado: Alineación.
  /* gap: 16px; */ // Comentado: Espaciado entre elementos.
  border: 1px solid #f0f0f0; // Borde gris claro.
  border-radius: 0px 8px 8px 0px; // Bordes redondeados en las esquinas derechas.
  background: #f0f0f0; // Fondo gris claro.
  /* margin-right: 2rem; */ // Comentado: Margen derecho.
`;

// Define un contenedor estilizado para el tipo de reporte.
export const TextTypeReport = styled.div`
  display: flex; // Activa el modelo de caja flexible.
  height: 36px; // Altura fija.
  align-items: center; // Alinea elementos al centro verticalmente.
  border-radius: 8px 0px 0px 8px; // Bordes redondeados solo en la izquierda.
  background: #f0f0f0; // Color de fondo gris claro.
  height: 36px; // Altura fija (duplicado).
  background-color: #f0f0f0; // Fondo gris claro (duplicado).
  padding: 8px 12px; // Espaciado interno.
  display: flex; // Flexbox (duplicado).
  align-items: center; // Alineación vertical.
  justify-content: center; // Centra el contenido horizontalmente.
  width: 100%; // Ancho completo.
`;

// Define un botón de descarga reutilizable como componente funcional.
export const DownloadButton = ({ onClick, children, disabled }) => {
  return (
    <StyledButton type="button" onClick={onClick} disabled={disabled}>
      {children} {/* Contenido del botón */}
    </StyledButton>
  );
};
