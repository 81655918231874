// Importación del componente TextField y tipos relacionados desde Material-UI
import { TextField } from "@mui/material";

// Importación de React y sus funcionalidades
import * as React from "react";

// Importación de NumericFormat y sus tipos desde react-number-format para formatear números en inputs
import { NumericFormat, NumericFormatProps } from "react-number-format";

// Importación de styled-components para crear componentes estilizados
import styled from "styled-components";

// Importación de una función de validación personalizada desde un archivo de utilidades
import { regexValidateNumber } from "../utils";

// Definición de una interfaz para las propiedades personalizadas del componente NumericFormatCustom
interface CustomProps {
  // Función para manejar cambios en el input, recibe un evento con nombre y valor
  onChange: (event: { target: { name: string; value: string } }) => void;

  // Nombre del input
  name: string;
}

/**
 * Componente personalizado que formatea la entrada numérica.
 * Utiliza react-number-format para aplicar formatos y validaciones específicas.
 */
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    // Desestructuración de las propiedades recibidas
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}                       // Propiedades adicionales pasadas al NumericFormat
        getInputRef={ref}                // Referencia al input para controlar el enfoque
        onValueChange={(values) => {      // Maneja el cambio de valor formateado
          onChange({
            target: {
              name: props.name,           // Nombre del input
              value: values.value,        // Valor formateado
            },
          });
        }}
        decimalScale={2}                  // Limita a dos decimales
        allowNegative={false}             // No permite valores negativos
        thousandSeparator                 // Añade separadores de miles
        valueIsNumericString             // El valor es una cadena numérica
        prefix=""                         // Sin prefijo
        suffix={"%"}                      // Añade un sufijo de porcentaje
        isAllowed={(values) => {          // Función para validar si el valor es permitido
          const { floatValue } = values;
          if (floatValue) {
            return floatValue <= 100;     // Permite solo valores hasta 100
          }
          return true;                     // Permite valores sin flotantes
        }}
      />
    );
  }
);

/**
 * Componente principal que utiliza los inputs formateados.
 * Permite ingresar valores numéricos con formato personalizado y validaciones.
 *
 * @param props - Propiedades recibidas por el componente
 * @returns Un contenedor con un TextField personalizado
 */
export default function FormattedInputsFutureDesempenio({
  handleChange, // Función para manejar cambios en el input
  value,        // Valor actual del input
  disable,      // Booleano para deshabilitar el input
  index,        // Índice del elemento en una lista (si aplica)
  name,         // Nombre del input
}: any) {
  return (
    <ContainerInput>
      {/* Campo de texto con formato numérico personalizado */}
      <TextField
        value={value} // Valor actual del input
        onChange={(e) => {
          // Valida el valor ingresado utilizando una expresión regular
          if (
            regexValidateNumber.test(e.target.value) || // Verifica si el valor coincide con la regex
            e.target.value === ""                       // Permite campos vacíos
          ) {
            handleChange(e.target.value);               // Llama a la función de manejo de cambio si la validación pasa
          }
        }}
        name={name} // Nombre del input
        InputProps={{
          inputComponent: NumericFormatCustom as any, // Asigna el componente de formato personalizado
        }}
        disabled={disable} // Deshabilita el input si es necesario
      />
    </ContainerInput>
  );
}

/**
 * Contenedor estilizado para el input.
 * Aplica estilos específicos a los elementos internos de Material-UI para una apariencia personalizada.
 */
const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  /* Estilos para la raíz del input de Material-UI */
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;                              // Ancho del 85%
    margin: 0 auto;                          // Centra el input horizontalmente
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos para el control de formulario de Material-UI */
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;                           // Utiliza flexbox para la disposición de los elementos hijos
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
    align-items: center;                     // Alinea verticalmente al centro
    justify-content: center;                 // Alinea horizontalmente al centro
    outline: none;                           // Sin contorno al enfocar
    align-content: center;                   // Alinea el contenido al centro
  }

  /* Elimina el contorno al enfocar el input */
  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos base para la raíz del input de Material-UI */
  .MuiInputBase-root {
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }
`;
