/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
import { saveAs } from "file-saver";
import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { evaluationNullPolitics } from "../../../common/utils";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { validateIdPeriod, validateSemestre } from "../../../context/validacionesFiltros";
import { useUrlPermisos } from "../../../hooks/Evaluation";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { getFile } from "../../../services/downLoadFileEvaluation";
import { getLogsB } from "../../../services/logs.service";
import ModalLoader from "../../organisms/ConfirmationModals/ModalLoader";
import { useLoader } from "../../../context/LoaderContext";
import {
  AlertContainer,
  ButonTitleContainer,
  ButtonContainer,
  ButtonDescargar,
  ButtonDescargarEvaluacion,
  CardColBorderR,
  CardColNew,
  CardFlexBox,
  CardsBoxNew,
  ColabInfoTitleNew,
  NewCardContainerLeft,
  NewCardContainerRight,
} from "./CardEvaluatorStyles";
// import {evaluationNullPolitics} from ''

const CardEvaluatorNewDesing = ({ politicsAlert }: any) => {
  //**  States
  const [error, setError] = useState<string | null>(null); // Estado para manejar errores
  const [loader, setLoader] = useState<boolean>(false); // Estado para manejar el loader

  //**  Redux
  const { Collaborator, evaluationAttribute, User } = useCollaboratorRedux(); // Obtener datos del colaborador desde Redux
  const { Evaluation } = useEvaluationRedux(); // Obtener datos de la evaluación desde Redux

  //**  Context
  const { yearId, periodPeticion, periodId } = useContext(
    EvaluationContext
  ) as EvaluationContextType; // Obtener datos del contexto de evaluación

  //** Context Cambio de periodo
  const { selectedOptionPeriod, changePeriodEvaluation, selectedOptionYear } =
    useContext(ChangePeriodContext) as ChangePeriodContextType; // Obtener datos del contexto de cambio de periodo

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos(); // Hook personalizado para obtener permisos de URL

  const { isLoading, incrementRequests, decrementRequests } = useLoader(); // Hook personalizado para manejar el estado de carga

  // Obtener filtros principales de localStorage
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const { year } = filtersParsed;
  const periodLocal = localStorage.getItem("periodId");
  const periodParsed = JSON.parse(periodLocal ?? "{}");
  const { valuePeriod } = periodParsed;

  const periodo = validateIdPeriod(valuePeriod); // Validar el periodo
  const semestreLocal = validateSemestre(valuePeriod); // Validar el semestre

  // Función para descargar historial
  const fetchData = async () => {
    const periodoSeleccionado = validateIdPeriod(selectedOptionPeriod);
    try {
      incrementRequests(); // Incrementar el contador de solicitudes
      const response = await getLogsB(Evaluation.employeeEvaluationId); // Obtener logs de evaluación
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(
        blob,
        `Historial_${Collaborator.employeeEvaluation.employeeEvaluationEvaluatedUserId
        }-${urlPermisos ? selectedOptionYear : yearId}-${urlPermisos ? periodoSeleccionado : periodo
        }`
      );
    } catch (error) {
      setError("Error al obtener el porcentaje"); // Configurar mensaje de error
    } finally {
      decrementRequests(); // Decrementar el contador de solicitudes
    }
  };

  // Función para descargar evaluación
  const fetchDataEvaluation = async () => {
    const periodoSeleccionado = validateIdPeriod(selectedOptionPeriod);
    const semestreSeleccionado = validateSemestre(selectedOptionPeriod);

    try {
      incrementRequests(); // Incrementar el contador de solicitudes
      const response = await getFile(
        User?.claTrab,
        Collaborator?.claTrab,
        urlPermisos ? periodoSeleccionado : periodo
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(
        blob,
        `Evaluacion_${Collaborator.employeeEvaluation.employeeEvaluationEvaluatedUserId
        }-${urlPermisos ? selectedOptionYear : year}-${urlPermisos ? semestreSeleccionado : semestreLocal
        } (${Evaluation.evaluationPeriodId})`
      );
    } catch (error) {
      setError("Error al obtener el porcentaje"); // Configurar mensaje de error
    } finally {
      decrementRequests(); // Decrementar el contador de solicitudes
    }
  };

  const {
    changePage,
  } = useContext(EvaluationContext) as EvaluationContextType; // Obtener función para cambiar de página desde el contexto de evaluación
  const [esNuevoIngreso, setEsNuevoIngreso] = useState<boolean>(false); // Estado para manejar si es nuevo ingreso

  useEffect(() => {
    // Verificar si el usuario es nuevo ingreso
    if (Evaluation.esNuevoIngreso) {
      setEsNuevoIngreso(false);
    } else {
      // Verificar si la alerta de políticas es igual a null
      if (Evaluation.employeeEvaluationPolitics === evaluationNullPolitics) {
        setEsNuevoIngreso(true);
      } else {
        setEsNuevoIngreso(false);
      }
    }
  }, [Evaluation.employeeEvaluationPolitics, Evaluation.esNuevoIngreso, changePage]);

  return (
    <Container style={{ padding: "0px" }}>
      <>
        {/* {loader && <ModalLoader />} */}
        {isLoading && <ModalLoader />} {/* Mostrar loader si está cargando */}
        <CardsBoxNew showAlert={esNuevoIngreso}>
          {/* <CardsBoxNew showAlert={politicsAlert === evaluationNullPolitics}> */}
          <ButonTitleContainer>
            <ColabInfoTitleNew>Información del colaborador</ColabInfoTitleNew>

            <ButtonContainer>
              {evaluationAttribute?.btn_evaluation_logs === 1 && (
                <ButtonDescargar onClick={fetchData}>
                  Descargar historial
                </ButtonDescargar>
              )}

              {evaluationAttribute?.btn_download_evaluation === 1 && (
                <ButtonDescargarEvaluacion onClick={fetchDataEvaluation}>
                  Descargar evaluación
                </ButtonDescargarEvaluacion>
              )}
            </ButtonContainer>
          </ButonTitleContainer>

          <AlertContainer showAlert={esNuevoIngreso}>
            Alerta evaluación aún no tiene calificación de políticas
          </AlertContainer>
          <CardFlexBox>
            <CardColBorderR>
              <NewCardContainerRight>
                <div className="bodyCard">
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "0",
                      lineHeight: "1.7",
                    }}
                  >
                    <li style={{ fontSize: "14px" }}>
                      <strong>Nombre:</strong>{" "}
                      <span>{Collaborator?.nombre}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong>Puesto:</strong>{" "}
                      <span>{Collaborator?.puesto}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong># de empleado: </strong>{" "}
                      <span>{Collaborator?.claTrab}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong>% Alineación: </strong>{" "}
                      <span>{Collaborator?.porcentajeAlineacion}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong># de Grupo:</strong>{" "}
                      <span>{Collaborator?.jerarquia}</span>
                    </li>
                  </ul>
                </div>
              </NewCardContainerRight>
            </CardColBorderR>
            {/*  */}
            <CardColNew>
              <NewCardContainerLeft>
                <div className="bodyCard">
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "0",
                      lineHeight: "1.7",
                    }}
                  >
                    <li style={{ fontSize: "14px" }}>
                      <strong>Jefe directo / Evaluador:</strong>{" "}
                      <span>{Collaborator?.jefeDirecto}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong>Jefe del Jefe Inmediato:</strong>{" "}
                      <span>{Collaborator?.jefeDeJefes}</span>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <strong>Fecha de Ingreso: </strong>{" "}
                      <span>{Collaborator?.fechaIng}</span>
                    </li>
                  </ul>
                </div>
              </NewCardContainerLeft>
            </CardColNew>
          </CardFlexBox>
        </CardsBoxNew>
      </>
    </Container>
  );
};

export { CardEvaluatorNewDesing };
