/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
// Desactiva las reglas de ESLint para evitar advertencias sobre expresiones no utilizadas y dependencias de hooks

// Importación de hooks y componentes necesarios
import { useContext, useEffect, useState } from "react"; // Hooks de React para manejar estado y efectos secundarios
import Image from "react-bootstrap/Image"; // Componente de imagen de React Bootstrap
import { Link, useLocation } from "react-router-dom"; // Componentes para navegación y ubicación de rutas
import { IViews } from "../../../Interface/ApiInterface"; // Importación de interfaces para tipado
import logout from "../../../assets/icons/cerrarsession.svg"; // Importación de icono de cerrar sesión
import CuestionarioIcon from "../../../assets/icons/cuestionario.svg"; // Importación de icono de cuestionario
import DashboardIcon from "../../../assets/icons/dashboard.svg"; // Importación de icono de dashboard
import EvaluationIcon from "../../../assets/icons/evaluation.svg"; // Importación de icono de evaluación
import PeriodoIcon from "../../../assets/icons/iconPeriodo.svg"; // Importación de icono de periodo
import ObjectiveIcon from "../../../assets/icons/objetive.svg"; // Importación de icono de objetivos
import PoliticasIcon from "../../../assets/icons/politicas.svg"; // Importación de icono de políticas
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext"; // Importación de contexto para evaluaciones
import { SocketContext } from "../../../context/SocketContext"; // Importación de contexto para sockets
import { useCollaboratorRedux } from "../../../redux/Collaborators"; // Importación de hook personalizado para Redux de colaboradores
import { getCollaboratorServices } from "../../../services/collaborator.service"; // Importación de servicios para colaboradores
import { Divider } from "../../../styles/AppStyles"; // Importación de componente de divisor estilizado
import {
  EvaluationTitle,
  EvaluationTitleContainer,
  SideBarStyles,
} from "./SideBarStyles"; // Importación de estilos personalizados para el sidebar

/**
 * Componente SideBar que renderiza la barra lateral de navegación
 */
const SideBar = () => {
  const location = useLocation(); // Hook para obtener la ubicación actual de la ruta
  const { socket, idRoom, setIdRoom } = useContext(SocketContext); // Uso del contexto de sockets

  const [view, setView] = useState(""); // Estado para manejar la vista actual seleccionada

  // ** Redux **
  const {
    Meta,
    User,
    UserPrev,
    handleGetCollaborators,
    errorloading,
    setErrorLoading,
    Filters,
    evaluationAttribute,
    handleGetCollaborator,
    Collaborator,
  } = useCollaboratorRedux(); // Uso de hook personalizado para acceder al estado de Redux relacionado con colaboradores

  // ** Context **
  const {
    setEvaluatedOrEvaluation,
    setIsReduxOrContext,
    setChangePage,
    changePage,
  } = useContext(EvaluationContext) as EvaluationContextType; // Uso del contexto de evaluaciones

  /**
   * Función para manejar el cierre de sesión del usuario
   * @param {any} e - Evento de clic
   */
  const handleLogout = (e?: any) => {
    e.preventDefault(); // Previene el comportamiento por defecto del evento

    // Eliminación de tokens y filtros almacenados en sesión y localStorage
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("LDBCOLLADO");
    localStorage.removeItem("principalFilters");
    localStorage.removeItem("tableFilters");
    localStorage.removeItem("periodId");
    localStorage.removeItem("pageLocalStorage");

    // Si hay una sala de socket activa, emite el evento para salir de la sala
    if (idRoom) {
      socket?.emit("leave-room", idRoom);
      setIdRoom(undefined); // Resetea el ID de la sala
    }

    // Redirecciona al usuario después de un breve retraso
    setTimeout(() => {
      window.location.href = "https://collado.com.mx/";
    }, 1000);
  };

  /**
   * Función para desplazar la ventana hasta el final de la página
   */
  function scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Desplaza hasta la altura total del documento
      behavior: "smooth", // Animación suave
    });
  }

  // useEffect para manejar el tiempo de inactividad del usuario
  useEffect(() => {
    let inactivityTimer: any; // Timer para inactividad

    /**
     * Función para reiniciar el temporizador de inactividad
     * @param {any} event - Evento de interacción del usuario
     */
    const resetTimer = (event?: any) => {
      clearTimeout(inactivityTimer); // Limpia el temporizador anterior
      inactivityTimer = setTimeout(() => {
        handleLogout(event); // Cierra sesión tras inactividad
        window.location.replace("https://collado.com.mx/"); // Redirecciona al usuario
      }, 600000); // 10 minutos de inactividad (600,000 ms)
    };

    /**
     * Función para manejar el movimiento del ratón y reiniciar el temporizador
     * @param {any} event - Evento de movimiento del ratón
     */
    const handleMouseMove = (event?: any) => {
      resetTimer(event); // Reinicia el temporizador al detectar movimiento
    };

    // Añade el listener para el movimiento del ratón
    document.addEventListener("mousemove", handleMouseMove);

    resetTimer(); // Inicia el temporizador al montar el componente

    // Limpia el temporizador y el listener al desmontar el componente
    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // Dependencias vacías para que solo se ejecute una vez al montar

  /**
   * Función para manejar la selección de vistas en el sidebar
   * @param {string} name - Nombre de la vista seleccionada
   */
  const handelView = (name: string) => {
    setView(name); // Actualiza el estado de la vista actual
    switch (name) {
      case "Dashboard":
        window.scrollTo(0, 0); // Desplaza al inicio de la página
        break;
      case "Evaluaciones":
        window.scrollTo(0, 0);
        break;
      case "Objetivos":
        window.scrollTo(0, 0);
        break;
      case "Periodo":
        window.scrollTo(0, 0);
        break;
      case "Politicas":
        window.scrollTo(0, 0);
        break;
      case "Cuestionario":
        window.scrollTo(0, 0);
        break;
      case "Mi evaluación":
        setIsReduxOrContext(false); // Configura el contexto para evaluación
        setEvaluatedOrEvaluation(false);
        window.scrollTo(0, 100); // Desplaza un poco hacia abajo
        setChangePage(!changePage); // Cambia la página para forzar re-render
        break;
      case "Mis objetivos":
        setIsReduxOrContext(false);
        setEvaluatedOrEvaluation(true);
        setChangePage(!changePage);
        scrollToBottom(); // Desplaza hasta el final de la página
        break;
      default:
        break;
    }
  };

  // useEffect para establecer la vista inicial basada en el rol del usuario
  useEffect(() => {
    if (User) { // Verifica si hay un usuario autenticado
      switch (User.role) { // Cambia la vista según el rol del usuario
        case "Evaluado":
          setView("Evaluacion");
          break;
        case "Evaluador":
          setView("Dashboard");
          break;
        case "Director":
          setView("Dashboard");
          break;
        case "Admin":
          setView("Dashboard");
          break;
        default:
          setView("Dashboard");
          break;
      }
    }
  }, []); // Dependencias vacías para que solo se ejecute una vez al montar

  // useEffect para actualizar la vista basada en la ruta actual
  useEffect(() => {
    const viewLocation = location.pathname.replace("/", ""); // Obtiene el nombre de la vista desde la ruta
    setView(viewLocation); // Actualiza el estado de la vista
  }, [location]); // Se ejecuta cada vez que cambia la ubicación

  // useEffect para manejar errores de autenticación y limpiar el localStorage
  useEffect(() => {
    /**
     * Función para borrar múltiples elementos de localStorage y redireccionar
     * @param {any} claves - Arreglo de claves a borrar de localStorage
     * @param {any} urlRedireccion - URL a la que redireccionar después de borrar
     */
    async function borrarVariosLocalStorageYRedireccionar(
      claves: any,
      urlRedireccion: any
    ) {
      try {
        // Itera sobre el arreglo de claves, borrando cada elemento de localStorage
        claves.forEach((clave: string) => {
          localStorage.removeItem(clave); // Elimina cada clave
        });
        localStorage.clear(); // Limpia todo el localStorage

        // Verifica si el localStorage está vacío
        if (Object.keys(window.localStorage).length === 0) {
          // Opcional: Puedes realizar alguna acción si el localStorage está vacío
        } else {
          // Opcional: Puedes realizar alguna acción si el localStorage no está vacío
        }

        // Redirecciona a la URL deseada
        // window.location.href = urlRedireccion;
      } catch (error) {
        // Maneja cualquier error que pueda ocurrir al intentar borrar los elementos de localStorage
        // console.error("Error al borrar los elementos de localStorage:", error);
      }
    }

    // Realiza la petición para obtener los datos del colaborador
    getCollaboratorServices(User?.claTrab)
      .then((res) => {
        // Maneja la respuesta exitosa de la petición
        // console.log("Envío de datos front  ->", res.data);
      })
      .catch((err) => {
        // Maneja errores de la petición
        // console.log("Envío de datos front  ->", err, err.response.status);
        if (err.response.status === 401) { // Verifica si el error es de autenticación
          borrarVariosLocalStorageYRedireccionar(
            [
              "token",
              "principalFilters",
              "tableFilters",
              "periodId",
              "pageLocalStorage",
            ],
            "https://www.ejemplo.com" // URL de redireccionamiento en caso de error
          );
        }
      });
  }, []); // Dependencias vacías para que solo se ejecute una vez al montar

  return (
    <SideBarStyles> {/* Contenedor estilizado para la barra lateral */}
      {User?.views?.map((item: IViews, index: any) => (
        <Link
          to={`/${item.name === "Mi evaluación"
            ? "Evaluacion"
            : item.name === "Mis objetivos"
              ? "Evaluacion"
              : item.name
            }`} // Define la ruta a la que navega el enlace
          style={{ textDecoration: "none" }} // Elimina la decoración del texto
          onClick={() => handelView(item.name)} // Maneja el clic para cambiar la vista
          key={`${item.name}${index}`} // Clave única para cada elemento del mapeo
        >
          <EvaluationTitleContainer
            style={{ background: view === item.name ? "#FFFFFF" : "#F0F0F0" }} // Cambia el fondo según la vista actual
          >
            <Image
              src={
                item.name === "Dashboard"
                  ? DashboardIcon
                  : item.name === "Evaluaciones"
                    ? EvaluationIcon
                    : item.name === "Objetivos"
                      ? ObjectiveIcon
                      : item.name === "Periodo"
                        ? PeriodoIcon
                        : item.name === "Politicas"
                          ? PoliticasIcon
                          : item.name === "Cuestionario"
                            ? CuestionarioIcon
                            : item.name === "Mi evaluación"
                              ? CuestionarioIcon
                              : item.name === "Mis objetivos"
                                ? ObjectiveIcon
                                : undefined
              } // Selecciona el icono adecuado según el nombre de la vista
              alt={item.name} // Texto alternativo para la imagen
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <EvaluationTitle
                style={{
                  color: view === item.name ? "#3B63A2" : "#333335", // Cambia el color del texto según la vista actual
                  fontWeight: view === item.name ? "700" : "400", // Cambia el peso de la fuente según la vista actual
                }}
              >
                {item.name === "Politicas" ? "Políticas" : item.name} {/* Muestra el nombre de la vista, corrigiendo "Politicas" */}
              </EvaluationTitle>
            </div>
          </EvaluationTitleContainer>
        </Link>
      ))}
      <Divider /> {/* Componente de divisor estilizado */}
      <Link
        to="/Default.aspx" // Ruta para cerrar sesión
        style={{ textDecoration: "none" }} // Elimina la decoración del texto
        onClick={(e) => handleLogout(e)} // Maneja el clic para cerrar sesión
        key={`logout`} // Clave única para el enlace de cerrar sesión
      >
        <EvaluationTitleContainer style={{ background: "#F0F0F0" }}> {/* Contenedor con fondo gris claro */}
          <Image src={logout} /> {/* Icono de cerrar sesión */}
          <EvaluationTitle style={{ color: "#333335" }}> {/* Título con color oscuro */}
            {`Cerrar sesión`} {/* Texto del botón */}
          </EvaluationTitle>
        </EvaluationTitleContainer>
      </Link>
    </SideBarStyles>
  ); // Finaliza el renderizado del componente
};

export default SideBar; // Exporta el componente SideBar para su uso en otras partes de la aplicación
