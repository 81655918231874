/* eslint-disable no-unused-vars */
// Desactiva la regla de ESLint que advierte sobre variables no utilizadas

// Importación de styled-components para crear componentes estilizados en React
import styled from "styled-components";

// Objeto de estilos para el carrusel
export const carrusel = {
  padding: "32px 10px", // Padding de 32px arriba y abajo, 10px a los lados
};

// Objeto de estilos para los botones del carrusel en pantallas grandes
export const ButtonsCarrusel = {
  justifyContent: "center", // Centra horizontalmente los botones
};

// Objeto de estilos para los botones del carrusel en dispositivos móviles
export const ButtonsCarruselMobile = {
  justifyContent: "center", // Centra horizontalmente los botones
  display: "contents", // Permite que los hijos se comporten como si fueran hijos directos del contenedor padre
  marginBottom: "17px", // Margen inferior de 17px
};

// Objeto que define los tamaños de pantalla para uso en media queries
const size = {
  mobile: "320px",    // Ancho mínimo para dispositivos móviles
  tablet: "768px",    // Ancho mínimo para tabletas
  laptop: "1024px",   // Ancho mínimo para laptops
  desktop: "2560px",  // Ancho mínimo para escritorios grandes
};

// Objeto de estilos para un botón personalizado (StyleButonM)
export const StyleButonM = {
  background: "#3B63A2",               // Color de fondo azul
  borderRadius: "8px",                 // Bordes redondeados con radio de 8px
  fontWeight: "500",                   // Peso de fuente medio
  fontSize: "16px",                    // Tamaño de fuente de 16px
  lineHeight: "120%",                  // Altura de línea del 120%
  color: "#FFFFF",                     // Color de texto blanco (Nota: "#FFFFF" debería ser "#FFFFFF" para blanco completo)
  cursor: "pointer",                   // Cambia el cursor a una mano al pasar sobre el botón
  width: '-webkit-fill-available',      // Ancho disponible en navegadores WebKit
  margin: '0.5rem',                     // Margen de 0.5 rem alrededor del botón
};

// Objeto de estilos para un botón primario personalizado (StyleButon)
export const StyleButon = {
  padding: "8px 24px",                  // Padding de 8px arriba/abajo y 24px a los lados
  background: "#C9F2FF",                // Color de fondo azul claro
  borderRadius: "8px",                  // Bordes redondeados con radio de 8px
  fontWeight: "500",                    // Peso de fuente medio
  fontSize: "16px",                     // Tamaño de fuente de 16px
  lineHeight: "120%",                   // Altura de línea del 120%
  color: "#3B63A2",                     // Color de texto azul
  borderStyle: "none",                  // Sin borde
  cursor: "pointer",                    // Cambia el cursor a una mano al pasar sobre el botón
};

// Objeto de estilos para un botón secundario personalizado (StyleButonB)
export const StyleButonB = {
  padding: "7.1px 24px",                // Padding de 7.1px arriba/abajo y 24px a los lados
  background: "#3B63A2",                // Color de fondo azul
  borderRadius: "8px",                  // Bordes redondeados con radio de 8px
  fontWeight: "500",                    // Peso de fuente medio
  fontSize: "16px",                     // Tamaño de fuente de 16px
  lineHeight: "120%",                   // Altura de línea del 120%
  color: "#FFF",                        // Color de texto blanco
  cursor: "pointer",                    // Cambia el cursor a una mano al pasar sobre el botón
};

// Objeto de estilos para un botón adicional (StyleButonBM)
export const StyleButonBM = {
  padding: "8px 24px",                  // Padding de 8px arriba/abajo y 24px a los lados
  gap: "8px",                           // Espacio de 8px entre elementos hijos
  background: "#C1C1C3",                // Color de fondo gris
  borderRadius: "8px",                  // Bordes redondeados con radio de 8px
  fontWeight: "500",                    // Peso de fuente medio
  fontSize: "14px",                     // Tamaño de fuente de 14px
  lineHeight: "120%",                   // Altura de línea del 120%
  color: "#F0F0F0",                     // Color de texto gris claro
  borderStyle: "none",                  // Sin borde
  cursor: "pointer",                    // Cambia el cursor a una mano al pasar sobre el botón
  width: '-webkit-fill-available',      // Ancho disponible en navegadores WebKit
  margin: '0.5rem',                     // Margen de 0.5 rem alrededor del botón
};

// Objeto de estilos para un botón de cerrar personalizado (StyleButonCerrar)
export const StyleButonCerrar = {
  background: "#C9F2FF",                // Color de fondo azul claro
  borderRadius: "8px",                  // Bordes redondeados con radio de 8px
  color: "#3B63A2",                     // Color de texto azul
  fontWeight: "500",                    // Peso de fuente medio
  fontSize: "16px",                     // Tamaño de fuente de 16px
  lineHeight: "120%",                   // Altura de línea del 120%
  borderStyle: "none",                  // Sin borde
  cursor: "pointer",                    // Cambia el cursor a una mano al pasar sobre el botón
};

// Componente estilizado que aplica una fuente global específica al elemento <h1>
export const GlobalFont = styled.h1`
  font-family: 'Plus Jakarta Sans', sans-serif; // Define la familia de fuentes
`;

// Componente estilizado que aplica una fuente global específica al contenedor <div>
export const GlobalFontDos = styled.div`
  /* height: 20px; */
  /* width: 20px;/ */
  display: flex;                        // Utiliza flexbox para la disposición de los elementos hijos
  align-content: center;                // Alinea el contenido verticalmente al centro
  justify-content: center;              // Centra horizontalmente los elementos
  align-items: center;                  // Centra verticalmente los elementos
  /* margin-top: 6px; */
  font-family: 'Plus Jakarta Sans', sans-serif; // Define la familia de fuentes
`;

// Componente estilizado para el título del modal
export const TitleModal = styled.h1`
  font-weight: 600;      // Peso de fuente semi-negrita
  font-size: 16px;       // Tamaño de fuente de 16px
  line-height: 22px;     // Altura de línea de 22px
  color: #1f2a40;        // Color de texto azul oscuro
`;

// Componente estilizado para el encabezado de una sección dentro del modal
export const HeaderSlice = styled.h2`
  font-weight: 900;         // Peso de fuente negrita
  font-size: 23.1523px;     // Tamaño de fuente específico
  line-height: 110%;        // Altura de línea del 110%
  text-align: center;       // Centra el texto
  color: #3b63a2;           // Color de texto azul
  margin-bottom: 3%;        // Margen inferior del 3%
`;

// Componente estilizado para un segundo tipo de encabezado con ligeras diferencias
export const HeaderSlice2 = styled.h2`
  font-weight: 900;         // Peso de fuente negrita
  font-size: 23.1523px;     // Tamaño de fuente específico
  line-height: 110%;        // Altura de línea del 110%
  text-align: center;       // Centra el texto
  color: #3b63a2;           // Color de texto azul
  margin-bottom: 2%;        // Margen inferior del 2%
`;

// Componente estilizado para subtítulos dentro del modal
export const SubtitleSlice = styled.p`
  font-weight: 700;         // Peso de fuente semi-negrita
  font-size: 14px;          // Tamaño de fuente de 14px
  line-height: 130%;        // Altura de línea del 130%
  text-align: center;       // Centra el texto
  color: #3b63a2;           // Color de texto azul
`;

// Componente estilizado para textos generales dentro del modal
export const Text = styled.p`
  font-weight: 700;         // Peso de fuente semi-negrita
  font-size: 14px;          // Tamaño de fuente de 14px
  line-height: 130%;        // Altura de línea del 130%
  color: #3b63a2;           // Color de texto azul
`;

// Componente estilizado para secciones de contenido dentro del modal
export const ContentSlice = styled.div`
  display: block;               // Muestra el elemento como un bloque
  width: 100%;                  // Ancho completo del contenedor
  align-content: center;        // Alinea el contenido verticalmente al centro
  padding: 32px 24px;           // Padding de 32px arriba/abajo y 24px a los lados
  gap: 24px;                    // Espacio de 24px entre elementos hijos

  // Media query para pantallas entre 320px y 1023px de ancho
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 2px 4px;           // Reduce el padding a 2px arriba/abajo y 4px a los lados
  }
`;

// Componente estilizado para secciones de texto dentro del modal con estilos adicionales
export const ContentTextSlice2 = styled.div`
  left: 0px;                              // Posición izquierda
  top: 0px;                               // Posición superior
  box-sizing: border-box;                 // Incluye el padding y el borde en el cálculo del ancho y alto
  width: 100%;                            // Ancho completo del contenedor
  /* height: auto; */
  padding: 32px 24px 32px;                // Padding: 32px arriba, 24px a los lados y 32px abajo
  gap: 24px;                              // Espacio de 24px entre elementos hijos
  background: #107e6f;                    // Color de fondo verde oscuro
  border: 3.22456px solid #3b63a2;        // Borde sólido azul de aproximadamente 3.22px
  border-radius: 12.8982px;               // Bordes redondeados con radio específico
  position: relative;                     // Posición relativa para posicionar elementos hijos absolutamente

  // Media query para pantallas entre 320px y 1023px de ancho
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 32px 32px 22px;              // Ajusta el padding
    margin-bottom: 8%;                    // Margen inferior del 8%
    margin-top: 6%;                       // Margen superior del 6%
  }
`;

// Componente estilizado para un texto específico dentro del modal
export const TextSlice2 = styled.p`
  /* font-weight: 400;
  font-size: 14px; */
  /* line-height: 110%; */
  text-align: center;       // Centra el texto
  color: #ffffff;           // Color de texto blanco
  padding: 0 4rem;          // Padding de 0 arriba/abajo y 4rem a los lados
  position: relative;       // Posición relativa

  // Media query para pantallas entre 320px y 1023px de ancho
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 0 0rem;        // Elimina el padding lateral
  }
`;

// Componente estilizado para imágenes dentro del modal con posiciones y responsividad
export const Image = styled.img`
  left: -20px;                        // Posición izquierda
  height: auto;                       // Altura automática
  top: 60px;                          // Posición superior
  width: 10%;                         // Ancho del 10%
  position: absolute;                 // Posición absoluta dentro del contenedor padre
  padding: 2px 4px;                   // Padding de 2px arriba/abajo y 4px a los lados
  background: #3b63a2;                // Color de fondo azul
  border-radius: 9.85795px;           // Bordes redondeados con radio específico

  /* Media queries para responsividad */

  // Media query para pantallas entre 320px y 768px de ancho
  @media (min-width: 320px) and (max-width: 768px) {
    width: 15%;                       // Ancho del 15%
    margin-top: 6%;                   // Margen superior del 6%
    margin-left: 5%;                  // Margen izquierdo del 5%
  }

  // Media query para pantallas entre 768px y 1023px de ancho
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10%;                       // Ancho del 10%
    margin-top: 1%;                   // Margen superior del 1%
    margin-right: 6%;                 // Margen derecho del 6%
  }
`;

// Componente estilizado para la sección SMART dentro del modal
export const ContentSmart = styled.div`
  display: flex;                      // Utiliza flexbox para la disposición de los elementos
  justify-content: space-around;      // Distribuye los elementos con espacio alrededor
  padding: 0px;                       // Sin padding
  gap: 8px;                           // Espacio de 8px entre elementos hijos

  // Media query para pantallas entre 320px y 1023px de ancho
  @media (min-width: 320px) and (max-width: 1023px) {
    width: 100%;                      // Ancho completo del contenedor
  }
`;

export const SubtitleSlice3 = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  text-align: center;
  color: #3b63a2;
  z-index: 1000;
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 5.4533px;
  }
  @media (min-width: 377px) and (max-width: 769px) {
    font-size: 7.3533px;
  }
  @media (min-width: 770px) and (max-width: 1023px) {
    font-size: 15.3533px;
  }
`;
export const ContentGrid = styled.div`
  display: flex;                      // Utiliza flexbox
  width: 100%;                        // Ancho completo del contenedor
  flex-direction: column;             // Disposición en columna
  align-items: center;                // Centra los elementos horizontalmente
  padding: 0px;                       // Sin padding

  // Media query para pantallas pequeñas
  @media (min-width: 320px) and (max-width: 376px) {
    width: 10%;                       // Ancho del 10%
  }

  // Media query para pantallas medianas
  @media (min-width: 377px) and (max-width: 769px) {
    width: 15%;                       // Ancho del 15%
  }
`;

// Componente estilizado para las letras representativas de SMART
export const Smart = styled.p`
  font-weight: 700;                   // Peso de fuente negrita
  font-size: 107.682px;               // Tamaño de fuente grande
  line-height: 110%;                  // Altura de línea del 110%
  text-align: center;                 // Centra el texto
  color: #3b63a2;                     // Color de texto azul

  /* Media queries para responsividad */

  // Pantallas muy pequeñas
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 20.682px;              // Tamaño de fuente reducido
  }

  // Pantallas pequeñas a medianas
  @media (min-width: 377px) and (max-width: 424px) {
    font-size: 30.682px;              // Tamaño de fuente intermedio
  }

  // Pantallas medianas a grandes
  @media (min-width: 425px) and (max-width: 1023px) {
    font-size: 50.682px;              // Tamaño de fuente grande
  }
`;

// Componente estilizado para textos dentro de SMART con estructura de filas
export const ContentTextSlice3 = styled.p`
  display: grid;                      // Utiliza CSS Grid para la disposición de los elementos
  grid-template-rows: 1fr 4fr 1fr;    // Define tres filas con proporciones específicas
  left: 0px;                          // Posición izquierda
  top: 0px;                           // Posición superior
  background: #3b63a2;                // Color de fondo azul
  padding: 10px;                      // Padding de 10px en todos los lados
  height: 55%;                        // Altura del 55%

  /* Media queries para responsividad */

  // Pantallas muy pequeñas
  @media (min-width: 320px) and (max-width: 376px) {
    height: 78%;                       // Altura del 78%
    padding: 5px;                      // Padding reducido a 5px
  }

  // Pantallas pequeñas a medianas
  @media (min-width: 377px) and (max-width: 769px) {
    height: 77%;                       // Altura del 77%
  }

  // Pantallas medianas a grandes
  @media (min-width: 770px) and (max-width: 1023px) {
    height: 60%;                       // Altura del 60%
  }
`;

// Componente estilizado para textos dentro de ContentTextSlice3
export const TextSlice3 = styled.p`
  font-weight: 400;                    // Peso de fuente normal
  font-size: 10px;                     // Tamaño de fuente de 10px
  line-height: 110%;                   // Altura de línea del 110%
  color: #ffffff;                      // Color de texto blanco

  /* Media queries para responsividad */

  // Pantallas muy pequeñas
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 7.5px;                  // Tamaño de fuente reducido
  }

  // Pantallas pequeñas a medianas
  @media (min-width: 377px) and (max-width: 769px) {
    font-size: 6px;                    // Tamaño de fuente aún más reducido
  }
`;

// Componente estilizado para títulos dentro de ContentTextSlice3
export const TitleTextSlice3 = styled.p`
  font-weight: 400;                    // Peso de fuente normal
  font-size: 12px;                     // Tamaño de fuente de 12px
  line-height: 100%;                   // Altura de línea del 100%
  text-align: center;                  // Centra el texto
  color: #ffffff;                      // Color de texto blanco

  /* Media queries para responsividad */

  // Pantallas muy pequeñas
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 6px;                    // Tamaño de fuente reducido
  }
`;

// Componente estilizado para preguntas guía dentro de SMART
export const Question = styled.p`
  font-weight: 700;                    // Peso de fuente negrita
  font-size: 12px;                     // Tamaño de fuente de 12px
  line-height: 110%;                   // Altura de línea del 110%
  text-align: center;                  // Centra el texto
  color: #ffffff;                      // Color de texto blanco
`;

// Componente estilizado para el contenedor del título en el modal
export const ContenTitle = styled.p`
  display: flex;                       // Utiliza flexbox para la disposición de los elementos
  gap: 14px;                           // Espacio de 14px entre elementos hijos
`;

// Componente estilizado adicional para el título del modal (parece redundante)
export const ContentTitleModal = styled.div`
  display: flex;                       // Utiliza flexbox
  gap: 14px;                           // Espacio de 14px entre elementos hijos
`;

// Componente estilizado para una imagen dentro del modal
export const ImageModal = styled.img`
  left: 4.33%;                         // Posición izquierda
  right: 88.67%;                       // Posición derecha
  top: 17%;                            // Posición superior
  bottom: 64%;                         // Posición inferior
`;

// Objeto de estilos para un título de precaución
export const TitleCaution = {
  width: '158px',                      // Ancho de 158px
  height: '38px',                      // Altura de 38px
  left: '100.19px',                    // Posición izquierda
  top: '39.61px',                      // Posición superior
  fontStyle: ' normal',                // Estilo de fuente normal
  fontWeight: '700',                   // Peso de fuente negrita
  fontSize: '32px',                    // Tamaño de fuente de 32px
  lineHeight: '120%',                  // Altura de línea del 120%
  color: '#F27405',                    // Color de texto naranja
};

// Objeto de estilos para un título de éxito
export const TitleListo = {
  width: '158px',                      // Ancho de 158px
  height: '38px',                      // Altura de 38px
  left: '100.19px',                    // Posición izquierda
  top: '39.61px',                      // Posición superior
  fontStyle: ' normal',                // Estilo de fuente normal
  fontWeight: '700',                   // Peso de fuente negrita
  fontSize: '32px',                    // Tamaño de fuente de 32px
  lineHeight: '120%',                  // Altura de línea del 120%
  color: '#22BB55',                    // Color de texto verde
};

// Objeto de estilos para un título de error
export const TitleError = {
  width: '158px',                      // Ancho de 158px
  height: '38px',                      // Altura de 38px
  left: '100.19px',                    // Posición izquierda
  top: '39.61px',                      // Posición superior
  fontStyle: ' normal',                // Estilo de fuente normal
  fontWeight: '700',                   // Peso de fuente negrita
  fontSize: '28px',                    // Tamaño de fuente de 28px
  lineHeight: '120%',                  // Altura de línea del 120%
  color: '#EE4444',                    // Color de texto rojo
};

// Objeto de estilos para un título de error con longitud máxima
export const TitleErrorLenghtMax = {
  width: 'auto',                       // Ancho automático
  height: '38px',                      // Altura de 38px
  left: '100.19px',                    // Posición izquierda
  top: '39.61px',                      // Posición superior
  fontStyle: ' normal',                // Estilo de fuente normal
  fontWeight: '700',                   // Peso de fuente negrita
  fontSize: '28px',                    // Tamaño de fuente de 28px
  lineHeight: '120%',                  // Altura de línea del 120%
  color: '#EE4444',                    // Color de texto rojo
};

// Objeto de estilos para eliminar el borde inferior de una línea horizontal
export const DeleteHr = {
  borderBottom: 'none',                // Sin borde inferior
};

// Objeto de estilos para eliminar el borde superior de una línea horizontal
export const DeleteHrTop = {
  borderTop: 'none',                   // Sin borde superior
};

// Componente estilizado para contener botones de información en dispositivos móviles
export const ContainMobileInfoButtons = styled.div`
  display: flex;                        // Utiliza flexbox para la disposición de los elementos
  @media (min-width: 320px) and (max-width: 758px) {
    flex-direction: column;             // Cambia la dirección de los elementos a columna en dispositivos móviles
  }
`;

// Componente estilizado para contener un botón en dispositivos móviles con márgenes ajustados
export const ContainMobileButon = styled.div`
  margin-left: 1%;                       // Margen izquierdo del 1%

  // Media query para pantallas entre 320px y 758px de ancho
  @media (min-width: 320px) and (max-width: 758px) {
    margin-top: 1%;                      // Margen superior del 1% en dispositivos móviles
  }
`;
