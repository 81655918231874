// Importación del componente InfoModal desde la carpeta Modals
import InfoModal from "../Modals/InfoModal";

// Importación de componentes estilizados EvaluatedHeaderContainer y HeaderTitle desde EvaluationHeaderStyles
import {
  EvaluatedHeaderContainer,
  HeaderTitle,
} from "./EvaluationHeaderStyles";

// Definición del componente funcional EvaluationHeader
const EvaluationHeader = () => {
  return (
    <div>
      {/* Contenedor estilizado para el encabezado evaluado */}
      <EvaluatedHeaderContainer>
        {/* Título del encabezado */}
        <HeaderTitle>Evaluación de colaboradores</HeaderTitle>
        {/* Componente modal de información */}
        <InfoModal/>
      </EvaluatedHeaderContainer>

      {/*
        Comentario: Contenedor adicional que ha sido deshabilitado.
        Probablemente utilizado para breadcrumbs o navegación previa.
      */}
      {/* <EvaluatedHeaderContainer> */}
        {/*
          Comentario: Componente Breadcrumb para la navegación.
          - Breadcrumb.Item con enlace a "Colaborador"
          - Breadcrumb.Item activo para "Revisar evaluación"
        */}
        {/* <Breadcrumb>
          <Breadcrumb.Item href="#">Colaborador</Breadcrumb.Item>
          <Breadcrumb.Item active>Revisar evaluación</Breadcrumb.Item>
        </Breadcrumb> */}
      {/* </EvaluatedHeaderContainer> */}
    </div>
  );
};

// Exportación por defecto del componente EvaluationHeader
export default EvaluationHeader;
