/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "styled-components";
// import Close from "../../../assets/icons/CloseModal.svg";
import { useContext, useEffect, useState } from "react";
import AlertModalIcon from "../../../assets/infoYellow.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { postSetAdminStaff } from "../../../services/dataTable.service";
import { ModalAlertWrapper } from "../ModalActions/ModalEditStyles";

// Componente principal para el modal que cambia la clasificación de un colaborador
const ModalChangeClasification = ({
  item,                 // Información del colaborador a actualizar
  isModalVisible,       // Estado que controla la visibilidad del modal
  setIsModalVisible,    // Función para cerrar o mostrar el modal
  setItemClasification, // Función para actualizar la clasificación del colaborador
  itemClasification,    // Estado actual de la clasificación
  setModal,             // Función para controlar el modal de feedback
  setMessageModal       // Función para establecer un mensaje en el modal
}: any) => {

  //** Redux: Se obtienen funciones y datos del estado global
  const { User, handleGetCollaborators } = useCollaboratorRedux();

  //** Contexto: Función para establecer el estado de paginación como "cargando"
  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  // Recuperar filtros de búsqueda y paginación desde el localStorage
  const filtrosAlmacenados = localStorage.getItem("principalFilters");
  const pageStorage = localStorage.getItem("pageLocalStorage");
  const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

  // Estado para almacenar filtros aplicados a la tabla
  const [filters, setFilters] = useState<any | null>({
    claTrab: "",
    nombre: "",
    puesto: "",
    Localidad: "",
    area: "",
    departamento: "",
    fechaNac: "",
    IdEstatusEvaluacion: "",
    Clasificacion: "",
    gerente: "",
    director: "",
    compania: "",
    JerarquiaColaborador: "",
    EstatusAutoEvaluacion: "",
  });

  // Hook useEffect: Recupera filtros previos al montar el componente
  useEffect(() => {
    const filtrosAlmacenados = localStorage.getItem("tableFilters");
    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados);
      setFilters(filtros);
    }
  }, []);

  // Función para actualizar la clasificación del colaborador
  const updateClasification = async (collaborator: any) => {
    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados);
      const { year, periodo, propLocation, propDirector } = filtros;

      await postSetAdminStaff(
        collaborator.claTrab,   // Clave del trabajador
        undefined,              // Parámetro no utilizado
        !itemClasification,     // Nuevo valor de clasificación (booleano)
        periodo                 // Periodo actual de evaluación
      )
        .then((res) => {
          // Acción exitosa: Mostrar modal de confirmación
          setModal(true);
          setMessageModal("La acción se ha realizado correctamente.");
        })
        .catch((error) => {
          // Acción fallida: Mostrar modal de error
          setModal(true);
          setMessageModal("Ocurrió un error al realizar la acción.");
        });
    }
  };

  // Función para manejar el envío y cerrar el modal
  const handleSent = () => {
    updateClasification(item); // Actualiza la clasificación
    setItemClasification(!item.clasificacion); // Cambia el estado local de clasificación
    setIsModalVisible(false); // Cierra el modal

    const { page: pageStore } = pageStorageParsed;

    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados);
      const { year, periodo } = filtros;

      // Construcción de la query string basada en los filtros aplicados
      let query = `?`;
      Object.keys(filters).forEach((key) => {
        if (
          filters[key] !== undefined &&
          filters[key] !== "" &&
          filters[key] !== null &&
          filters[key] !== "Elige una opción"
        ) {
          query += `&${key}=${filters[key]}`;
        }
      });

      // Llamada a la función de Redux para obtener colaboradores actualizados
      handleGetCollaborators(
        `${query}&NombreColaborador=${User.nombre}&RolColaborador=${
          User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageNumber=${pageStore}&PageSize=10`
      );
      setLoadingPagination(true); // Activar estado de carga
    }
  };

  return (
    // Wrapper principal del modal
    <ModalAlertWrapper display={isModalVisible}>
      <div className="modalWrapper">
        {/* Cabecera del modal */}
        <TitleContainer>
          <img src={AlertModalIcon} className="image" alt="AlertModalIcon" />
          <TitleText className="titleText">Cambiar estatus </TitleText>
        </TitleContainer>

        {/* Cuerpo del modal */}
        <ContainerModalText>
          <div className="textContainer">
            {item.employeeEvaluation.catStatusEvaluationId > 3 ? (
              // Mensaje si la evaluación ya está terminada
              "No es posible realizar la acción porque la evaluación ya está terminada, favor de realizar el cambio en el siguiente periodo."
            ) : (
              // Mensaje si se permite la acción
              <>
                <p>
                  El cambio solicitado reestablecerá la información introducida
                  en
                  <span style={{ fontWeight: "700" }}> 'Objetivos futuros'</span>.
                </p>
                Nota: Los cambios no afectarán a evaluaciones futuras ya creadas.
              </>
            )}
          </div>
          {/* Pregunta de confirmación si la acción es válida */}
          {item.employeeEvaluation.catStatusEvaluationId < 4 && (
            <div className="textContainer">¿Deseas continuar?</div>
          )}
        </ContainerModalText>

        {/* Footer con botones de acción */}
        <FooterModal>
          {item.employeeEvaluation.catStatusEvaluationId > 3 ? (
            <ButtonModalCancel onClick={() => setIsModalVisible(false)}>
              Cerrar
            </ButtonModalCancel>
          ) : (
            <>
              <ButtonModalCancel onClick={() => setIsModalVisible(false)}>
                Cancelar
              </ButtonModalCancel>
              <ButtonModalSave onClick={handleSent}>Continuar</ButtonModalSave>
            </>
          )}
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default ModalChangeClasification;

// Estilo para un área de texto personalizada
export const InputObj = styled.textarea`
  width: 80%;            // Ancho del área de texto
  height: 80%;           // Altura del área de texto
  border: none;          // Sin borde
  outline: none;         // Eliminar contorno al enfocar
  resize: none;          // Evitar que el usuario redimensione el área
`;

// Contenedor para el título del modal
export const TitleContainer = styled.div`
  display: flex;               // Alinear elementos en fila
  width: 100%;                 // Ocupar todo el ancho disponible
  align-items: center;         // Centrar elementos verticalmente
  justify-content: flex-start; // Alinear elementos a la izquierda
  gap: 8px;                    // Espacio entre los elementos
`;

// Estilo para el texto del título
export const TitleText = styled.p`
  color: var(--primary-true-blue, #3b63a2); // Color principal (azul)
  font-feature-settings: "clig" off, "liga" off; // Configuración de ligaduras tipográficas
  margin: 0;                      // Eliminar márgenes por defecto
  font-family: Plus Jakarta Sans; // Fuente personalizada
  font-size: 20px;                // Tamaño del texto
  font-style: normal;             // Estilo normal
  font-weight: 700;               // Texto en negrita
  line-height: 110%;              // Altura de línea ajustada
`;

// Contenedor principal para el texto del modal
export const ContainerModalText = styled.div`
  width: 100%;                 // Ancho completo
  display: flex;               // Flexbox
  flex-direction: column;      // Alinear elementos en columna
  gap: 32px;                   // Espacio entre elementos verticales

  .textContainer {
    font-size: 16px;           // Tamaño del texto
    color: #333335;            // Color de texto oscuro
    font-weight: 400;          // Grosor del texto normal
  }

  .containerModal {
    width: 100%;               // Ancho completo
    background-color: #f8f8f8; // Color de fondo gris claro
    border-radius: 4px;        // Bordes redondeados
    min-height: 100px;         // Altura mínima
    height: auto;              // Altura automática
    display: flex;             // Flexbox
    justify-content: end;      // Alinear contenido a la derecha
    font-size: 14px;           // Tamaño de fuente más pequeño

    .txt {
      margin-top: 8px;         // Margen superior
      padding: 8px;            // Relleno interno
    }

    .containerTxt {
      background-color: white; // Fondo blanco
      border-radius: 8px;      // Bordes redondeados
      font-weight: 700;        // Texto en negrita
      color: black;            // Color de texto negro
      font-size: 14px;         // Tamaño del texto
      width: 83%;              // Ocupar 83% del ancho disponible
    }
  }
`;

// Contenedor del pie del modal
export const FooterModal = styled.div`
  display: flex;               // Flexbox
  width: 100%;                 // Ancho completo
  justify-content: center;     // Centrar elementos horizontalmente
  gap: 32px;                   // Espacio entre botones
`;

// Botón para "Guardar" o "Continuar"
export const ButtonModalSave = styled.div`
  background-color: #c9f2ff;   // Color de fondo azul claro
  border-radius: 8px;          // Bordes redondeados
  font-size: 14px;             // Tamaño de texto
  font-family: "Plus Jakarta Sans"; // Fuente personalizada
  font-weight: 700;            // Texto en negrita
  color: #3b63a2;              // Color de texto azul oscuro
  padding: 8px 24px;           // Relleno interno
  cursor: pointer;             // Mostrar cursor de puntero al pasar
  width: 100%;                 // Ancho completo
  display: flex;               // Flexbox
  justify-content: center;     // Centrar texto horizontalmente
  height: 40px;                // Altura fija
  align-items: center;         // Centrar contenido verticalmente
  align-content: center;
`;

// Botón para "Cancelar" o "Cerrar"
export const ButtonModalCancel = styled.div`
  background-color: #3b63a2;   // Color de fondo azul oscuro
  border-radius: 8px;          // Bordes redondeados
  font-size: 14px;             // Tamaño de texto
  font-family: "Plus Jakarta Sans"; // Fuente personalizada
  font-weight: 700;            // Texto en negrita
  color: #fff;                 // Texto blanco
  padding: 8px 24px;           // Relleno interno
  cursor: pointer;             // Mostrar cursor de puntero
  width: 100%;                 // Ancho completo
  display: flex;               // Flexbox
  height: 40px;                // Altura fija
  align-items: center;         // Centrar contenido verticalmente
  align-content: center;
  justify-content: center;     // Centrar texto horizontalmente
`;
