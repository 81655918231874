// Importaciones de hooks y componentes necesarios
import { useEffect, useState } from "react";
import { ICurrentsObjetives } from "../../../Interface/ApiInterface";
import { evaluationNullPolitics } from "../../../common/utils";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { AlertContainerKpis, TableSumDosKpis } from "../Cards/CardEvaluatorStyles";
import { suma } from "./utils";

// Componente FooterTableKpis que muestra el footer de la tabla de KPIs
const FooterTableKpis = ({ politicsValidation, evaluacion, objetivesCurrents, sumTotal }: any) => {
    // Estado local para almacenar la suma total calculada
    const [sumatoria, setSumTotal] = useState<number>(0);

    // useEffect que se ejecuta cada vez que cambia `sumTotal`
    useEffect(() => {
        setSumTotal(() => {
            // Verifica si `sumTotal` es un arreglo válido y no está vacío
            const result = (sumTotal && Array.isArray(sumTotal) && sumTotal.length > 0)
                ? Math.round(sumTotal.reduce((acc: number, item: any) => {
                    // Convierte el valor a número flotante
                    const valor = parseFloat(item.valor);
                    // Suma el valor si es un número válido, de lo contrario suma 0
                    return acc + (isNaN(valor) ? 0 : valor);
                }, 0))
                : 0;

            return result; // Retorna el resultado calculado
        });
    }, [sumTotal]);

    return (
        // Componente estilizado que muestra una alerta si `politicsValidation` coincide con `evaluationNullPolitics`
        <TableSumDosKpis showAlert={politicsValidation === evaluationNullPolitics}>
            {/* Primera columna vacía */}
            <div className="first"></div>

            {/* Segunda columna que muestra la suma de los porcentajes de los objetivos */}
            <div
                className="second"
                style={{
                    // Cambia el color del texto a rojo si la suma no es 100%, de lo contrario negro
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {/* Muestra la suma de los porcentajes seguido del símbolo % */}
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                )}
                %
            </div>

            {/* Tercera columna que muestra un mensaje basado en si la suma es 100% */}
            <div
                className="third"
                style={{
                    // Cambia el color del texto a rojo si la suma no es 100%, de lo contrario negro
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {/* Muestra un mensaje diferente según si la suma es 100% o no */}
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                ) !== 100
                    ? `No suma 100%`
                    : `Suma`}
            </div>

            {/* Contenedor de alerta que se muestra si `politicsValidation` coincide con `evaluationNullPolitics` */}
            <AlertContainerKpis showAlert={politicsValidation === evaluationNullPolitics} >
                Alerta evaluación aún no tiene calificación de políticas
            </AlertContainerKpis>

            {/* Séptima columna que muestra la suma total o 0 basado en la validación de políticas */}
            <div className="seventh">
                {politicsValidation !== evaluationNullPolitics ?
                    // Si la validación de políticas es correcta, muestra `sumatoria`
                    sumatoria
                    : 0
                }
            </div>
        </TableSumDosKpis>
    );
};

export default FooterTableKpis;

// Componente FooterTableKpisChangePeriod que se utiliza cuando se cambia de periodo
export const FooterTableKpisChangePeriod = ({ evaluacion }: any) => {
    return (
        // Componente estilizado para la tabla de suma de KPIs
        <TableSumDosKpis>
            {/* Primera columna vacía */}
            <div className="first"></div>

            {/* Segunda columna que muestra la suma de los porcentajes de los objetivos */}
            <div
                className="second"
                style={{
                    // Cambia el color del texto a rojo si la suma no es 100%, de lo contrario negro
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {/* Muestra la suma de los porcentajes seguido del símbolo % */}
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                )}
                %
            </div>

            {/* Tercera columna que muestra un mensaje basado en si la suma es 100% */}
            <div
                className="third"
                style={{
                    // Cambia el color del texto a rojo si la suma no es 100%, de lo contrario negro
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {/* Muestra un mensaje diferente según si la suma es 100% o no */}
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                ) !== 100
                    ? `No suma 100%`
                    : `Suma`}
            </div>

            {/* Contenedor de alerta que se muestra siempre (no depende de validación de políticas) */}
            <AlertContainerKpis  >
                Alerta evaluación aún no tiene calificación de políticas
            </AlertContainerKpis>

            {/* Séptima columna que muestra la suma total de los resultados de las métricas */}
            <div className="seventh">
                {Math.round(suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) => +item.employeeEvaluationObjetivesResult
                    ) || []
                ))}
            </div>
        </TableSumDosKpis>
    );
};
