// Importación de styled-components para crear componentes estilizados
import styled from "styled-components";

/**
 * Contenedor para el encabezado evaluado.
 * Utiliza flexbox para alinear elementos horizontalmente con un espacio de 24px entre ellos.
 * Aplica un margen superior de 20px.
 */
export const EvaluatedHeaderContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

/**
 * Título principal del encabezado.
 * Estilo de encabezado h4 con fuente de tamaño 32px, negrita y sin margen.
 * Línea de altura del 100% para un espaciado compacto.
 */
export const HeaderTitle = styled.h4`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 38.4px */
  margin: 0;
`;

/**
 * Segundo título del encabezado.
 * Similar a HeaderTitle pero con una fuente ligeramente más pequeña de 28px.
 * Añade un padding inferior de 48px para espaciar elementos debajo.
 */
export const HeaderTitleDos = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 38.4px */
  margin: 0;
  padding: 0px 0px 48px 0px;
`;
