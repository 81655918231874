// Importación de styled-components para estilizar componentes de React
import styled from "styled-components";

// Componente estilizado para el contenedor de botones
export const ButtonBox = styled.div`
  display: flex; /* Utiliza flexbox para la disposición de los elementos */
  align-items: center; /* Alinea verticalmente los elementos al centro */
  justify-content: center; /* Alinea horizontalmente los elementos al centro */
  margin-top: 1rem; /* Margen superior de 1 rem */
  width: 100%; /* Ancho completo del contenedor */
  justify-content: flex-end; /* Alinea los elementos al final del contenedor */

  /* Media query para pantallas entre 320px y 1023px de ancho */
  @media (min-width: 320px) and (max-width: 1023px) {
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    gap: 14px; /* Espacio de 14px entre los elementos */
  }
`;

// Objeto de estilos para el botón de firmar
export const ButtonFirmar = {
  padding: "8px 32px", // Padding de 8px arriba/abajo y 32px izquierda/derecha
  width: "114px", // Ancho fijo de 114px
  height: "40px", // Altura fija de 40px
  background: "#3B63A2", // Color de fondo azul
  borderRadius: "8px", // Bordes redondeados con radio de 8px
  margin: "0 auto", // Margen automático a los lados para centrar horizontalmente
  borderColor: "transparent", // Color del borde transparente
  marginTop: "1.3rem", // Margen superior de 1.3 rem
};

// Componente estilizado para el encabezado de la tabla
export const TableHeading = styled.thead`
  background: #3b63a2; /* Color de fondo azul */
  color: #ffffff; /* Color de texto blanco */
`;

// Componente estilizado para el segundo encabezado de la tabla
export const TableHeadingDos = styled.div`
  background: #656569; /* Color de fondo gris */
  color: #ffffff; /* Color de texto blanco */
  border-radius: 8px 8px 0px 0px; /* Bordes redondeados en la parte superior */
  padding: 14px 8px; /* Padding de 14px arriba/abajo y 8px izquierda/derecha */
  display: flex; /* Utiliza flexbox para la disposición de los elementos */

  /* Estilos para la primera columna del encabezado */
  .first {
    width: 8%; /* Ancho del 8% */
    display: flex; /* Utiliza flexbox */
    align-items: center; /* Alinea verticalmente al centro */
    justify-content: center; /* Alinea horizontalmente al centro */
    /* border: 1px solid red; */ /* Borde comentado para depuración */
  }

  /* Estilos para la segunda columna del encabezado */
  .second {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la tercera columna del encabezado */
  .third {
    width: 37%; /* Ancho del 37% */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px; /* Padding horizontal de 20px */
    /* border: 1px solid white; */
  }

  /* Estilos para la cuarta columna del encabezado */
  .fourth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la quinta columna del encabezado */
  .fifth {
    width: 12%; /* Ancho del 12% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la sexta columna del encabezado */
  .sixth {
    width: 12%; /* Ancho del 12% */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Estilos para la séptima columna del encabezado */
  .seventh {
    width: 12%; /* Ancho del 12% */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Componente estilizado para el cuerpo de la segunda tabla
export const TableBodyDos = styled.div`
  background-color: #f8f8f8; /* Color de fondo gris claro */
  /* color: #ffffff; */ /* Color de texto comentado */
  border-radius: 4px; /* Bordes redondeados con radio de 4px */
  padding: 14px 8px; /* Padding de 14px arriba/abajo y 8px izquierda/derecha */
  display: flex; /* Utiliza flexbox para la disposición de los elementos */
  margin-bottom: 2px; /* Margen inferior de 2px */
  align-items: center; /* Alinea verticalmente al centro */
  justify-content: center; /* Alinea horizontalmente al centro */

  /* Estilos para la primera columna del cuerpo */
  .first {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  /* Estilos para la segunda columna del cuerpo */
  .second {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la tercera columna del cuerpo */
  .third {
    width: 37%; /* Ancho del 37% */
    display: flex;
    align-items: center;
    margin-left: 12px; /* Margen izquierdo de 12px */
    /* justify-content: center; */
    padding: 0px 10px; /* Padding horizontal de 10px */
    /* border: 1px solid white; */
  }

  /* Estilos para la cuarta columna del cuerpo */
  .fourth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la quinta columna del cuerpo */
  .fifth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la sexta columna del cuerpo */
  .sixth {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Estilos para la séptima columna del cuerpo */
  .seventh {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Componente estilizado para la tabla dos con scroll horizontal
export const TableDos = styled.div`
  overflow-x: scroll; /* Permite el desplazamiento horizontal si el contenido excede el ancho */
`;

// Contenedor para la evaluación de la segunda tabla
export const EvaluationContainerDos = styled.div`
  /* width: 90vw; */ /* Ancho comentado */
  display: flex; /* Utiliza flexbox */
  width: 100%; /* Ancho completo del contenedor */
`;

// Componente estilizado para un botón azul libre
export const ButtonBlueFree = styled.button`
  color: white; /* Color de texto blanco */
  background-color: #3b63a2; /* Color de fondo azul */
  width: auto; /* Ancho automático */
  font-weight: 700; /* Peso de fuente negrita */
  font-size: 14px; /* Tamaño de fuente de 14px */
  font-family: "Plus Jakarta Sans"; /* Familia de fuente personalizada */
  border: none; /* Sin borde */
  padding: 8px 24px; /* Padding de 8px arriba/abajo y 24px izquierda/derecha */
  border-radius: 8px; /* Bordes redondeados con radio de 8px */
  line-height: 16px; /* Altura de línea de 16px */

  /* Media query para pantallas con ancho máximo de 1023px */
  @media (max-width: 1023px) {
    width: 100%; /* Ancho completo del contenedor */
  }
`;

// Componente estilizado para el contenedor de la segunda tabla con un ancho mínimo
export const TableDosWrapper = styled.div`
  min-width: 1240px; /* Ancho mínimo de 1240px */
  /* overflow: auto; */ /* Overflow comentado */
  /* overflow-x: scroll; */ /* Overflow horizontal comentado */
`;

// Componente estilizado para la suma de la segunda tabla
export const TableSumDos = styled.div`
  background-color: #f8f8f8; /* Color de fondo gris claro */
  /* color: #ffffff; */ /* Color de texto comentado */
  border-radius: 4px; /* Bordes redondeados con radio de 4px */
  padding: 14px 8px; /* Padding de 14px arriba/abajo y 8px izquierda/derecha */
  display: flex; /* Utiliza flexbox para la disposición de los elementos */
  margin-bottom: 2px; /* Margen inferior de 2px */
  width: 100%; /* Ancho completo del contenedor */
  /* border: 1px solid red; */ /* Borde comentado para depuración */

  /* Estilos para la primera columna de la suma */
  .first {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  /* Estilos para la segunda columna de la suma */
  .second {
    width: 8%; /* Ancho del 8% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la tercera columna de la suma */
  .third {
    width: 37%; /* Ancho del 37% */
    display: flex;
    align-items: center;
    margin-left: 12px; /* Margen izquierdo de 12px */
    /* justify-content: center; */
    padding: 0px 10px; /* Padding horizontal de 10px */
    /* border: 1px solid white; */
  }

  /* Estilos para la cuarta columna de la suma */
  .fourth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la quinta columna de la suma */
  .fifth {
    width: 11%; /* Ancho del 11% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  /* Estilos para la sexta columna de la suma */
  .sixth {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Estilos para la séptima columna de la suma */
  .seventh {
    width: 12.5%; /* Ancho del 12.5% */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Componente estilizado para el contenedor de las firmas
export const FirmsBox = styled.div`
  display: flex; /* Utiliza flexbox */
  align-items: center; /* Alinea verticalmente al centro */
  justify-content: center; /* Alinea horizontalmente al centro */
  gap: 2rem; /* Espacio de 2 rem entre los elementos */
  margin-bottom: 3.5rem; /* Margen inferior de 3.5 rem */
`;

// Componente estilizado para la caja de firma del evaluador
export const EvaluatorFirmBox = styled.div`
  display: flex; /* Utiliza flexbox */
  flex-direction: row; /* Dirección de los elementos en fila */
  margin-top: 1.2rem; /* Margen superior de 1.2 rem */

  /* Estilos para el elemento <p> dentro de EvaluatorFirmBox */
  p {
    font-weight: 700; /* Peso de fuente negrita */
    font-size: 18px; /* Tamaño de fuente de 18px */
    /* color: #3b63a2; */ /* Color de texto comentado */
  }
`;

// Componente estilizado para la caja de firma del director
export const DirectorFirmBox = styled.div`
  display: flex; /* Utiliza flexbox */
  flex-direction: row; /* Dirección de los elementos en fila */
  margin-top: 1.2rem; /* Margen superior de 1.2 rem */

  /* Estilos para el elemento <p> dentro de DirectorFirmBox */
  p {
    font-weight: 700; /* Peso de fuente negrita */
    font-size: 18px; /* Tamaño de fuente de 18px */
    /* color: #3b63a2; */ /* Color de texto comentado */
  }
`;

// Componente estilizado para el contenedor de descripción
export const ContainerDescription = styled.div`
  height: 40px; /* Altura fija de 40px */
  background: #ffffff; /* Color de fondo blanco */
  border: 1px solid #f0f0f0; /* Borde sólido gris claro */
  border-radius: 8px; /* Bordes redondeados con radio de 8px */
`;

// Componente estilizado para el texto del asesor
export const AdvisorText = styled.span`
  font-weight: 400; /* Peso de fuente normal */
  font-size: 14px; /* Tamaño de fuente de 14px */
  line-height: 140%; /* Altura de línea del 140% */
  color: #939396; /* Color de texto gris */
`;

// Objeto de estilos para las celdas de la tabla
export const ContainTd = {
  width: "21%", // Ancho del 21%
};

// Objeto de estilos para el fondo de la tabla
export const BackTbale = {
  padding: "24px", // Padding de 24px en todos los lados
  gap: "16px", // Espacio de 16px entre elementos
  background: "#F8F8F8", // Color de fondo gris claro
  borderRadius: "8px", // Bordes redondeados con radio de 8px
  marginBottom: "3.5rem", // Margen inferior de 3.5 rem
};

// Objeto de estilos para alinear el botón de firmar
export const AlignButtonFirm = {
  marginRight: "0.5rem", // Margen derecho de 0.5 rem
  alignSelf: "flex-start", // Alinea el botón al inicio del contenedor flex
};
