/**
 * ChangePeriodAutoEvaluation.tsx (o ChangePeriodProvider.tsx)
 *
 * Este contexto define todo lo referente a “cambio de periodo” para la Evaluación,
 * incluyendo props y funciones que tus componentes necesitan (selectedOptionPeriod, etc.).
 */

import React, { ReactNode, useState, useEffect, ChangeEvent } from "react";
import { getCurrentYearAndSemesterObj } from "../../hooks/Evaluation/useSemesterYearNow";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import {
  getPeriodYearsSemesters,
  getPeriodsByYear,
} from "../../services/dataSelects.service";
import { validateIdPeriod } from "../validacionesFiltros";

/** -----------------------------------------------------------------------
 * Definimos la interfaz/tipo que describe TODO lo que se extrae en `useContext(ChangePeriodContext)`.
 * Esto incluye todas las propiedades que tu proyecto está usando.
 * ----------------------------------------------------------------------- */
export type ChangePeriodContextType = {
  // Años / periodos
  years: any[];                                   // Lista de años
  evaluationPeriodName: any[];                    // Lista de periodos para un año

  // Estados de "selectedOption"
  selectedOptionYear: number;
  setSelectedOptionYear: React.Dispatch<React.SetStateAction<number>>;
  selectedOptionPeriod: string;
  setSelectedOptionPeriod: React.Dispatch<React.SetStateAction<string>>;

  // Lógicas para cambio de año / semestre
  onChangeOptionYear: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeOptionSemestre: (e: ChangeEvent<HTMLSelectElement>) => void;
  getYearsOptions: () => Promise<void>;
  getPeriods: (yearParam: number, action: boolean) => Promise<void>;

  // Período de “Auto-evaluación”
  periodAutoEvaluation: string;
  setPeriodAutoEvaluation: React.Dispatch<React.SetStateAction<string>>;
  handleValidatePeriod: (selectedPeriod: string) => void;
  idActualPeriod: number;

  // Flag booleana
  changePeriodEvaluation: boolean;
  setChangePeriodEvaluation: React.Dispatch<React.SetStateAction<boolean>>;

  // Contadores / sumas
  realizedSearch: number;
  setRealizedSearch: React.Dispatch<React.SetStateAction<number>>;

  totalConocimientoEmpresa: any;
  setTotalConocimientoEmpresa: React.Dispatch<React.SetStateAction<any>>;
  totalSumaMetricas: any;
  setTotalSumaMetricas: React.Dispatch<React.SetStateAction<any>>;

  // Info adicional de periodo
  periodoDataInfo: string;
  setPeriodoDataInfo: React.Dispatch<React.SetStateAction<string>>;

  // Auxiliares
  setYears: React.Dispatch<React.SetStateAction<any[]>>;
  setEvaluationPeriodName: React.Dispatch<React.SetStateAction<any[]>>;

  // (Opcional) si lo usas en algún lado
  handleSearchPeriod?: () => void;
};

interface MyComponentProps {
  children: ReactNode;
}

// Obtenemos el semestre y año actuales
const { semestre, year } = getCurrentYearAndSemesterObj();

/**
 * Creamos el contexto (ChangePeriodContext).
 * Inicialmente es `null` o `ChangePeriodContextType`.
 */
export const ChangePeriodContext = React.createContext<ChangePeriodContextType | null>(null);

const ChangePeriodProvider: React.FC<MyComponentProps> = ({ children }) => {
  // Redux
  const { User } = useCollaboratorRedux();

  // --------------------------------------------------
  // Estados principales:
  // --------------------------------------------------
  // 1) Años y periodos
  const [years, setYears] = useState<any[]>([]);
  const [evaluationPeriodName, setEvaluationPeriodName] = useState<any[]>([]);

  // 2) “selectedOption”
  const [selectedOptionYear, setSelectedOptionYear] = useState<number>(0);
  const [selectedOptionPeriod, setSelectedOptionPeriod] = useState<string>("");

  // 3) Periodo de “Auto-evaluación”
  const [periodAutoEvaluation, setPeriodAutoEvaluation] = useState<string>("");

  // 4) Flag de si es periodo actual
  const [changePeriodEvaluation, setChangePeriodEvaluation] = useState<boolean>(false);

  // 5) Para contadores / sumas
  const [realizedSearch, setRealizedSearch] = useState<number>(1);
  const [totalConocimientoEmpresa, setTotalConocimientoEmpresa] = useState<any>(null);
  const [totalSumaMetricas, setTotalSumaMetricas] = useState<any>(null);

  // 6) Info adicional de periodo
  const [periodoDataInfo, setPeriodoDataInfo] = useState<string>("");

  // 7) Periodo actual
  const [idActualPeriod, setIdActualPeriod] = useState<number>(0);

  // Logs para debug:
  // useEffect(() => {
  //   console.log(
  //     "[ChangePeriodProvider] Render Info =>",
  //     "\n\tUser?.claTrab:", User?.claTrab,
  //     "\n\tselectedOptionYear:", selectedOptionYear,
  //     "\n\tselectedOptionPeriod:", selectedOptionPeriod,
  //     "\n\tperiodAutoEvaluation:", periodAutoEvaluation,
  //     "\n\tchangePeriodEvaluation:", changePeriodEvaluation,
  //     "\n\trealizedSearch:", realizedSearch,
  //     "\n\ttotalConocimientoEmpresa:", totalConocimientoEmpresa,
  //     "\n\ttotalSumaMetricas:", totalSumaMetricas,
  //     "\n\tperiodoDataInfo:", periodoDataInfo,
  //     "\n\tidActualPeriod:", idActualPeriod
  //   );
  // });

  // --------------------------------------------------
  // FUNCIONES
  // --------------------------------------------------

  /**
   * getYearsOptions:
   *   - Carga los años disponibles llamando a getPeriodYearsSemesters
   *   - Luego llama getPeriods(year, true) para setear periodos
   */
  const getYearsOptions = async () => {
    // console.log("[ChangePeriodProvider] getYearsOptions() - INICIO");
    try {
      const { data } = await getPeriodYearsSemesters(String(User?.claTrab));

      // Estructuramos la data en un array
      const yearList = data.evaluationPeriodYear.map((anual: any, idx: number) => ({
        year: anual,
        id: data.evaluationPeriodNumberOfYear[idx],
      }));

      // Ordenamos de menor a mayor
      yearList.sort((a, b) => a.year - b.year);
      setYears(yearList);

      // console.log("[getYearsOptions] yearList =>", yearList);
      // Llamamos a getPeriods con el “año actual”
      // y forzamos “action=true” => asignamos el actualPeriod
      getPeriods(year, true);
    } catch (error) {
      // console.error("[getYearsOptions] ERROR =>", error);
    }
  };

  /**
   * getPeriods:
   *   - Dado un año (yearParam), obtiene sus periodos. Asigna el "periodo actual" si action=true.
   */
  const getPeriods = async (yearParam: number, action: boolean) => {
    // console.log("[ChangePeriodProvider] getPeriods() => yearParam:", yearParam, "action:", action);
    try {
      const { data } = await getPeriodsByYear(yearParam, String(User?.claTrab));
      const array = data.map((item: any) => ({
        evaluationPeriodId: item.evaluationPeriodId,
        evaluationPeriodNumberOfYear: item.evaluationPeriodNumberOfYear,
      }));

      // console.log("[getPeriods] array =>", array);

      // Buscamos el “periodo actual” en base al 'semestre' devuelto por getCurrentYearAndSemesterObj
      const actualPeriod = array.filter(
        (obj: any) => obj.evaluationPeriodNumberOfYear === semestre
      );
      setIdActualPeriod(actualPeriod[0]?.evaluationPeriodId || 0);

      // El primer periodo de la lista
      const firstPeriod = array[0];

      // Seleccionamos ya sea el actualPeriod (si action=true) o el primero de la lista
      const selectedOption = action
        ? `${actualPeriod[0]?.evaluationPeriodId},${actualPeriod[0]?.evaluationPeriodNumberOfYear}`
        : `${firstPeriod?.evaluationPeriodId},${firstPeriod?.evaluationPeriodNumberOfYear}`;

      // console.log("[getPeriods] selectedOption =>", selectedOption);

      setEvaluationPeriodName(array);

      // Asignamos al state: selectedOptionPeriod solo si no había uno
      if (!selectedOptionPeriod) {
        // console.log("[getPeriods] No teníamos selectedOptionPeriod => lo forzamos.");
        setSelectedOptionPeriod(selectedOption);
        // parseamos su ID
        const periodo = validateIdPeriod(selectedOption);
        setPeriodAutoEvaluation(periodo);
      } else {
        // console.log("[getPeriods] YA hay selectedOptionPeriod => no lo cambiamos.");
      }
    } catch (error) {
      console.error("[getPeriods] ERROR =>", error);
    }
  };

  /**
   * onChangeOptionYear: se invoca cuando el usuario cambia el <select> de año
   */
  const onChangeOptionYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    // console.log("[onChangeOptionYear] => ", value);
    if (value) {
      setSelectedOptionYear(Number(value));
      // Llamamos getPeriods con action=false para no forzar “actualPeriod”
      getPeriods(Number(value), false);
    }
  };

  /**
   * onChangeOptionSemestre: se invoca cuando el usuario cambia el <select> de semestre
   */
  const onChangeOptionSemestre = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    // console.log("[onChangeOptionSemestre] => ", value);
    if (value) {
      setSelectedOptionPeriod(value);
      const periodo = validateIdPeriod(value);
      setPeriodAutoEvaluation(periodo);
    }
  };

  /**
   * handleValidatePeriod:
   *   - Comprueba si el selectedPeriod coincide con el idActualPeriod, para togglear `changePeriodEvaluation`.
   */
  const handleValidatePeriod = (selectedPeriod: string) => {
    // console.log("[handleValidatePeriod] =>", selectedPeriod);
    const periodo = validateIdPeriod(selectedPeriod);
    setRealizedSearch((prev) => prev + 1);

    if (idActualPeriod !== Number(periodo)) {
      setChangePeriodEvaluation(false);
    } else {
      setChangePeriodEvaluation(true);
    }
  };

  // (Opcional) Si usas handleSearchPeriod en alguna parte:
  const handleSearchPeriod = () => {
    // console.log("[handleSearchPeriod] (opcional) - no implementado");
  };

  // --------------------------------------------------
  // PROVEEMOS el contexto con todos los valores
  // --------------------------------------------------
  return (
    <ChangePeriodContext.Provider
      value={{
        // Años/periodos
        years,
        evaluationPeriodName,

        // selectedOption
        selectedOptionYear,
        setSelectedOptionYear,
        selectedOptionPeriod,
        setSelectedOptionPeriod,

        // Lógicas
        onChangeOptionYear,
        onChangeOptionSemestre,
        getYearsOptions,
        getPeriods,

        // “AutoEvaluación”
        periodAutoEvaluation,
        setPeriodAutoEvaluation,
        handleValidatePeriod,
        idActualPeriod,

        // Flag booleana
        changePeriodEvaluation,
        setChangePeriodEvaluation,

        // Contadores / sumas
        realizedSearch,
        setRealizedSearch,
        totalConocimientoEmpresa,
        setTotalConocimientoEmpresa,
        totalSumaMetricas,
        setTotalSumaMetricas,

        // Info extra
        periodoDataInfo,
        setPeriodoDataInfo,

        // Aux
        setYears,
        setEvaluationPeriodName,

        // (Opcional) handleSearchPeriod si lo necesitas en tu app
        handleSearchPeriod,
      }}
    >
      {children}
    </ChangePeriodContext.Provider>
  );
};

export default ChangePeriodProvider;
