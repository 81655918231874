import styled from "styled-components";
import ModalInputDefault from "../../../components/atoms/KpisTables/inputMask/ModalInputDefault";

// Componente ColumnsDataG
// Mapea y renderiza filas de datos condicionalmente con diferentes estructuras
export const ColumnsDataG = ({
  dataMap,         // Array de datos a mapear
  semestre,        // Información sobre el semestre
  inputValue,      // Valor del input controlado
  setInputValue,   // Función para actualizar el valor del input
}: any) => {
  return (
    <>
      {/* Verificar si dataMap existe y recorrerlo */}
      {dataMap &&
        dataMap?.map((item: any) => {
          // Primera condición: Si el tipo es "D" y no es una descripción específica
          if (
            item.type === "D" &&
            item.description !==
              "Desempeño laboral, humano, actitud y habilidades"
          ) {
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div> {/* Mostrar semestre */}
                <div className="second">{item.valor}%</div> {/* Mostrar valor */}
                <div className="third">{item.description}</div> {/* Mostrar descripción */}
                <div className="fourth">{item.meta}%</div> {/* Mostrar meta */}
              </ModalBodyTableDos>
            );
          }

          // Segunda condición: Si el tipo es "D" y coincide con una descripción específica
          if (
            item.type === "D" &&
            item.description ===
              "Desempeño laboral, humano, actitud y habilidades"
          ) {
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div> {/* Mostrar semestre */}
                <div className="second">
                  {/* Input controlado para modificar el valor */}
                  <ModalInputDefault
                    handleChange={setInputValue} // Función para manejar cambios en el input
                    value={inputValue}           // Valor actual del input
                    disable={false}              // Input habilitado
                    index={0}                    // Índice para referencia
                    name="valor"                 // Nombre del campo
                  />
                </div>
                <div className="third">{item.description}</div> {/* Mostrar descripción */}
                <div className="fourth">{item.meta}%</div> {/* Mostrar meta */}
              </ModalBodyTableDos>
            );
          }

          // Si no se cumple ninguna condición, retornar null
          return null;
        })}
    </>
  );
};

// Tipos de propiedades utilizados en los estilos
type PropTypeBgTres = {
  marginBtm?: any; // Propiedad para margen inferior (no usada aquí)
  disabled?: any;  // Propiedad para deshabilitar (no usada aquí)
  colorBtn?: any;  // Propiedad para color del botón (no usada aquí)
};

// Estilos para cada fila de la tabla
const ModalBodyTableDos = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;               // Flexbox para alinear elementos en fila
  align-items: center;         // Centrar verticalmente el contenido
  background-color: #f8f8f8;   // Color de fondo gris claro
  height: auto;                // Altura automática
  color: black;                // Color del texto
  width: 100%;                 // Ancho completo
  padding: 8px;                // Espaciado interno
  margin: 2px 0;               // Márgenes verticales
  font-family: "Plus Jakarta Sans"; // Fuente personalizada
  font-size: 14px;             // Tamaño del texto

  // Estilos para la primera columna (semestre)
  .first {
    width: 15%;                // Ocupa el 15% del ancho
    display: flex;
    align-items: center;
    justify-content: center;   // Centrar el texto
  }

  // Estilos para la segunda columna (valor o input)
  .second {
    width: 25%;                // Ocupa el 25% del ancho
    display: flex;
    align-items: center;
    justify-content: center;

    // Estilos para el input interno
    .inputSecond {
      padding: 5px 10px;       // Espaciado interno
      width: 40%;              // Ancho del input
      border-radius: 8px;      // Bordes redondeados
      border: 1px solid #d9d9d9; // Borde gris claro
      display: flex;
      align-items: center;
      text-align: center;      // Alinear texto al centro
    }
  }

  // Estilos para la tercera columna (descripción)
  .third {
    width: 35%;                // Ocupa el 35% del ancho
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;        // Centrar texto
  }

  // Estilos para la cuarta columna (meta)
  .fourth {
    width: 25%;                // Ocupa el 25% del ancho
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
