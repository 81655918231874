import { useContext, useEffect, useState } from "react";
import closeMoreActions from "../../../assets/icons/closeMoreActions.svg";
import openMoreActions from "../../../assets/icons/openMoreActions.svg";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../../context/EvaluacionContext/NewEvaluationContext";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { validateSemestre } from "../../../context/validacionesFiltros";
import {
  useAbleFirmsBtnSecond,
  useUrlPermisos,
} from "../../../hooks/Evaluation";
import { useAbleFirmsBtn } from "../../../hooks/Evaluation/useAbleFirmsBtn";
import { Firms } from "../../../pages/Admin/Firms/Firms";
import {
  BtnActions,
  ContainerButtonFloat,
  MoreActions,
} from "../../../pages/Admin/TableGoalStyles";
import TableGoalValue from "../../../pages/Admin/TableGoalValue";
import {
  InfoModal,
  InfoModalEvaluado,
} from "../../../pages/Admin/TableGoalValueComponents/FooterTable";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { ValueAndReach } from "../../atoms/EvaluationForms/EvaluationFormsStyles";
import { TableOne, TableTrhee } from "../../atoms/KpisTables/KpisTables";
import { TableOneChangePeriod } from "../../atoms/KpisTables/KpisTablesChangePeriod/KpisTablesChangePeriod";
import { TableDos } from "../../atoms/KpisTables/KpisTablesStyles";
import CommentSection from "./ComentariosSection/CommentSection";
import { KpisContainerStyles } from "./KpisContainerStyles";
import {
  TableKpisContainer,
  TablesSection,
  TitleTextGoalValueSection,
} from "./KpisStylesNew";
import {
  ModalAddObjetive,
  ModalPersonalObjectivesWrapperModal,
  ObjetivosIndicadoresModalFour,
} from "./Modales";
import ModalStatusTargetError from "../ConfirmationModals/ModalStatusError";

//** COMENTARIOS DEL EVALUADOR Y EVALUADO
const KpisContainer = ({
  addTotal,
  setEvaluation,
  evaluation,
  oportunidad,
  fortalezas,
  setReload,
  reload,
}: any) => {
  //** Context Validations
  const { setValidateComments } = useContext(
    NewEvaluationContext
  ) as NewEvaluationContextType;

  //** Context Cambio de periodo
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  //** Context
  const {
    changePage,
    futureObjetives,
    isReduxOrContext,
    evaluatedOrEvaluation,
  } = useContext(EvaluationContext) as EvaluationContextType;

  //** Redux
  const { Evaluation } = useEvaluationRedux();
  const { Collaborator, User } = useCollaboratorRedux();

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos();
  const { ableFirms } = useAbleFirmsBtn();
  const { ableFirmsSecond } = useAbleFirmsBtnSecond();

  //** States
  const [ableBtnFutures, setAbleBtnFutures] = useState(false);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowE, setModalShowE] = useState(false);
  const [evaluationKpis, setEvaluationKpis] = useState({ ...evaluation });
  const [mensajeError, setMensajeError] = useState(false);
  const [firmarEval, setFirmarEval] = useState(false);

  const [commentEvaluated, setCommentEvaluated] = useState(
    Collaborator?.employeeEvaluation?.employeeEvaluationCommetOfTheEvaluated ??
      ""
  );
  const [commentEvaluator, setCommentEvaluator] = useState(
    Collaborator?.employeeEvaluation?.employeeEvaluationEvaluatorCommet ?? ""
  );

  const handleSetCommentEvaluated = (e: any) => {
    setEvaluation({
      ...evaluation,
      employeeEvaluationCommetOfTheEvaluated: e.target.value,
    });
    setCommentEvaluated(e.target.value);
  };

  const handleSetCommentEvaluator = (e: any) => {
    setEvaluation({
      ...evaluation,
      employeeEvaluationEvaluatorCommet: e.target.value,
    });
    setCommentEvaluator(e.target.value);
  };

  //! Es necesario forzar un reload del componente
  //! porque al reutilizar el componente no limpia
  //! la vieja data que este poseía
  const [loadInfo, setLoadInfo] = useState(false);

  useEffect(() => {
    setLoadInfo(true);
    setTimeout(() => {
      setLoadInfo(false);
    }, 3000);
  }, [changePage]);

  const handleClose = () => setShow(false);
  const showInfoModal = () => setModalShow(true);
  const showInfoModalE = () => setModalShowE(true);

  const { periodoDataInfo } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  const periodLocal = localStorage.getItem("periodId");
  const periodParsed = JSON.parse(periodLocal ?? "{}");
  const { valuePeriod } = periodParsed;
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const { year, periodo } = filtersParsed;
  const semestre = validateSemestre(valuePeriod);
  const [futuresObjetivesId, setFutureObjetivesId] = useState<any[]>([]);
  const [valuesFuturesG, setValuesFuturesG] = useState<any[]>([]);
  const [desempenioValue, setDesempenioValue] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const currentPeriod = periodo !== undefined ? parseInt(periodo.charAt(0)) : 0;

  const [statusModalError, setStatusModalError] = useState({
    title: "Definición de objetivos pendiente",
    showModal: false,
    desPrimary: "Actualmente tu evaluador no ha definido tus objetivos.",
    desSecondary: "Estamos en espera de esta información para avanzar en el proceso, por favor intenta más tarde.",
  });

  useEffect(() => {
    // Verificar si el colaborador no tiene objetivos definidos en el semestre seleccionado
    const hasNoObjectives = !Evaluation?.employeeEvaluationObjetivesCurrents?.length;

    // Mostrar el modal si es necesario
    if (
      !Evaluation?.esNuevoIngreso && // Si no es de nuevo ingreso
      hasNoObjectives && // Si no tiene objetivos definidos
      (User?.role !== "Admin TI" && User?.role !== "Evaluador") // Y no es Admin TI o Evaluador
    ) {
      setStatusModalError({ ...statusModalError, showModal: true });
    } else {
      setStatusModalError({ ...statusModalError, showModal: false });
    }
  }, [Evaluation?.esNuevoIngreso, Evaluation?.employeeEvaluationObjetivesCurrents, User?.role]);

  //! LÓGICA PARA EL BOTÓN DE FIRMAS
  const [footer, setFooter] = useState(false);
  const [data, setData] = useState<any>([]);

  //** --- Este useEffect trae la data de la evaluación ---
  useEffect(() => {
    //!++++++++++++++++++++++++++++++++++++++++++++++++++
    const optionsNew = futureObjetives.filter((item: any) => item.type === "I");

    const optionsRederBack = optionsNew.map((item: any) => {
      const obj = {
        value: item?.id,
        label: item?.description,
        semester: item?.semestre,
      };
      return obj;
    });

      const optiontionsRenderBackEvaluation =
      Evaluation?.employeeEvaluationObjetivesFutures?.map((item: any) => {
        const objetive = {
          description: item.employeeEvaluationObjetivesDescription,
          id: item.employeeEvaluationObjetivesId,
          meta: item.employeeEvaluationObjetivesGoal,
          semestre: item.employeeEvaluationObjetivesSemesterNumber,
          type: item.employeeObjetivesType,
          valor: item.employeeEvaluationObjetivesWorth,
        };
        return objetive;
      });

    const optiontionsRenderBackEvaluationSelect =
      optiontionsRenderBackEvaluation?.map((item: any) => {
        const obj = {
          value: item?.id,
          label: item?.description,
          semester: item?.semestre,
        };
        return obj;
      });

    //* Si el valor es true yo mandaré al select los datos que
    //* llegan desde Redux, esto sólo se ejecutará al crear la evaluación

    if (isReduxOrContext) {
      const optionsNewG = futureObjetives.filter(
        (item: any) => item.type === "G"
      );

      const optionsRederBackG = optionsNewG.map((item: any) => {
        const obj = {
          id: item?.id,
          description: item?.description,
          semester: item?.semestre,
          valor: item?.valor,
          meta: item?.meta,
          type: item?.type,
        };
        return obj;
      });

      setValuesFuturesG(optionsRederBackG);
      setFutureObjetivesId(optiontionsRenderBackEvaluation);

      if (
        optiontionsRenderBackEvaluation &&
        optiontionsRenderBackEvaluationSelect
      ) {
        const newState = optiontionsRenderBackEvaluation.map(
          (option, index) => {
            const correspondingSelectOption =
              optiontionsRenderBackEvaluationSelect[index];
            return {
              semestre: option.semestre,
              valor: option.valor,
              descripcion: correspondingSelectOption,
              meta: option.meta,
            };
          }
        );
        setData(newState);
      }
    } else {
      const optionsNewG = futureObjetives.filter(
        (item: any) => item.type === "G"
      );

      const optionsRederBackG = optionsNewG.map((item: any) => {
        const obj = {
          id: item?.id,
          description: item?.description,
          semester: item?.semestre,
          valor: item?.valor,
          meta: item?.meta,
          type: item?.type,
        };
        return obj;
      });

      setValuesFuturesG(optionsRederBackG);
      setFutureObjetivesId(optionsNew);
      if (optionsNew.length) {
        const newState = optionsNew.map((option: any, index: any) => {
          const correspondingSelectOption = optionsRederBack[index];
          return {
            semestre: option.semestre,
            valor: option.valor,
            descripcion: correspondingSelectOption,
            meta: option.meta,
          };
        });
        setData(newState);
      }
    }
  }, [Evaluation, evaluatedOrEvaluation, futureObjetives, changePage]);

  useEffect(() => {
    setCommentEvaluator(
      Collaborator?.employeeEvaluation?.employeeEvaluationEvaluatorCommet ??
        ""
    );
  }, [Collaborator?.employeeEvaluation?.employeeEvaluationEvaluatorCommet]);

  useEffect(() => {
    setCommentEvaluated(
      Collaborator?.employeeEvaluation
        ?.employeeEvaluationCommetOfTheEvaluated ?? ""
    );
  }, [
    Collaborator?.employeeEvaluation?.employeeEvaluationCommetOfTheEvaluated,
  ]);

  const [sumTotal, setSumTotal] = useState<any>([]);

  const periodYear = Evaluation?.evaluationPeriod?.evaluationPeriodYear;
  const periodSemester = Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear;

  const isValidYear =
    periodYear === currentYear ||
    (periodYear === currentYear - 1 && periodSemester === 2);

  const isCurrentSemestre =
    currentYear === periodYear
      ? ((currentPeriod - 1 !== 0) ? currentPeriod - 1 : currentPeriod) ===
        parseInt(semestre)
      : periodYear === currentYear - 1 && parseInt(semestre) === 2;

  return (
    <>
      <>
        <CommentSection
          showInfoModal={showInfoModal}
          setCommentEvaluator={setCommentEvaluator}
          setCommentEvaluated={setCommentEvaluated}
          evaluation={evaluation}
          showInfoModalE={showInfoModalE}
          commentEvaluator={commentEvaluator}
          commentEvaluated={commentEvaluated}
          handleSetCommentEvaluator={handleSetCommentEvaluator}
          handleSetCommentEvaluated={handleSetCommentEvaluated}
        />
        {/* Tabla de comentarios */}

        <KpisContainerStyles>
          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <InfoModalEvaluado
            show={modalShowE}
            onHide={() => setModalShowE(false)}
          />

          {/* Esta tabla abarca dos componentes -> TablesSection */}
          <TablesSection>
            {/* Tabla de objetivos actuales */}
            {urlPermisos && (
              <div className="containerDate">{periodoDataInfo}</div>
            )}

            {!urlPermisos && (
              <div className="containerDate">{`Semestre ${semestre} / ${year}`}</div>
            )}

            {!Evaluation?.esNuevoIngreso && (
              <>
                <TableKpisContainer>
                  <ObjetivosIndicadoresModalFour />
                  <TitleTextGoalValueSection>
                    Objetivos actuales
                  </TitleTextGoalValueSection>
                  <br />
                  <TableDos>
                    {changePeriodEvaluation && (
                      <TableOne
                        loadInfo={loadInfo}
                        preguntas={evaluation.employeEvaluationQuestions}
                        addTotal={addTotal}
                        evaluacion={evaluationKpis}
                        setEvaluacion={setEvaluationKpis}
                        politicsValidation={
                          evaluation?.employeeEvaluationPolitics
                        }
                        sumTotal={sumTotal}
                        setSumTotal={setSumTotal}
                      />
                    )}
                    {!changePeriodEvaluation && <TableOneChangePeriod />}
                  </TableDos>
                </TableKpisContainer>
              </>
            )}
            {loadInfo ? (
              <> </>
            ) : (
              <ValueAndReach>
                <div className="firstContainer">
                  <ModalPersonalObjectivesWrapperModal />
                  {Evaluation?.employeeEvaluationObjetivesFutures?.length >
                    0 && (
                    <div className="first">
                      <TableGoalValue
                        ableBtnFutures={ableBtnFutures}
                        setAbleBtnFutures={setAbleBtnFutures}
                        mensajeError={mensajeError}
                        evaluation={evaluation}
                        oportunidad={oportunidad}
                        fortalezas={fortalezas}
                        setEvaluation={setEvaluation}
                        firmarEval={firmarEval}
                        setFirmarEval={setFirmarEval}
                        desempenioValue={desempenioValue}
                        setDesempenioValue={setDesempenioValue}
                        data={data}
                        setData={setData}
                        valuesFuturesG={valuesFuturesG}
                        setValuesFuturesG={setValuesFuturesG}
                        reload={reload}
                      />
                    </div>
                  )}
                </div>

                <div className="second">
                  <TableTrhee />
                </div>
              </ValueAndReach>
            )}
          </TablesSection>
        </KpisContainerStyles>

        <ModalAddObjetive show={show} handleClose={handleClose} />
        {statusModalError.showModal ? (

<ModalStatusTargetError
            modalStatus={true}
            handleClick={() =>
              setStatusModalError({
                ...statusModalError,
                showModal: false,
              })
            }
            messageShow={true}
            showData={false}
            message={statusModalError.desPrimary}
            messageDos={statusModalError.desSecondary}
            titleModal={statusModalError.title}

          />
        ) : null}
      </>
      <>
        {isValidYear && isCurrentSemestre && (
          <ContainerButtonFloat>
            <BtnActions onClick={() => setFooter(!footer)}>
              <img
                src={footer ? closeMoreActions : openMoreActions}
                alt="actions-modal"
              />
            </BtnActions>
            {footer && (
              <MoreActions style={{ alignSelf: "flex-end", cursor: "pointer" }}>
                <Firms
                  setFooter={setFooter}
                  footer={footer}
                  futuresObjetivesId={futuresObjetivesId}
                  mensajeError={mensajeError}
                  evaluation={evaluation}
                  setEvaluation={setEvaluation}
                  futures={data}
                  oportunidad={oportunidad}
                  fortalezas={fortalezas}
                  datosFuturosFiltradosDos={futureObjetives?.filter(
                    (item: any) => item.type === "D"
                  )}
                  desempenioValue={desempenioValue}
                  valuesFuturesG={valuesFuturesG}
                  sumaTotal={sumTotal}
                  setReload={setReload}
                  reload={reload}
                />
              </MoreActions>
            )}
          </ContainerButtonFloat>
        )}
        {isValidYear && isCurrentSemestre && evaluation.status !== "Completada" && (
          <ContainerButtonFloat>
            <BtnActions
              onClick={() => setFooter(!footer)}
            >
              <img
                src={footer ? closeMoreActions : openMoreActions}
                alt="actions-modal"
              />
            </BtnActions>
            {footer && (
              <MoreActions style={{ alignSelf: "flex-end", cursor: "pointer" }}>
                <Firms
                  setFooter={setFooter}
                  footer={footer}
                  futuresObjetivesId={futuresObjetivesId}
                  mensajeError={mensajeError}
                  evaluation={evaluation}
                  setEvaluation={setEvaluation}
                  futures={data}
                  oportunidad={oportunidad}
                  fortalezas={fortalezas}
                  datosFuturosFiltradosDos={futureObjetives?.filter(
                    (item: any) => item.type === "D"
                  )}
                  desempenioValue={desempenioValue}
                  valuesFuturesG={valuesFuturesG}
                  sumaTotal={sumTotal}
                  setReload={setReload}
                  reload={reload}
                />
              </MoreActions>
            )}
          </ContainerButtonFloat>
        )}
      </>
    </>
  );
};

export default KpisContainer;
