// Importación del componente TextField y tipos relacionados desde Material-UI
import { TextField } from "@mui/material";

// Importación de React y sus funcionalidades
import * as React from "react";

// Importación de NumericFormat y sus tipos desde react-number-format para formatear números en inputs
import { NumericFormat, NumericFormatProps } from "react-number-format";

// Importación de styled-components para crear componentes estilizados
import styled from "styled-components";

// Importación de un hook personalizado para manejar objetivos futuros
import { useAbleFutureObjetivesDefaults } from "../../../../hooks/ObjetivosFuturos";

// Importación de estilos específicos para el cuerpo del modal de la tabla
import { ModalBodyTableDos } from "../../EvaluationForms/EvaluationFormsStyles";

// Importación de una función de validación personalizada desde un archivo de utilidades
import { regexValidateNumber } from "../utils";

// Definición de una interfaz para las propiedades personalizadas del componente NumericFormatCustom
interface CustomProps {
  // Función para manejar cambios en el input, recibe un evento con nombre y valor
  onChange: (event: { target: { name: string; value: string } }) => void;

  // Nombre del input
  name: string;
}

/**
 * Componente personalizado que formatea la entrada numérica.
 * Utiliza react-number-format para aplicar formatos y validaciones específicas.
 * Permite solo valores numéricos positivos con hasta dos decimales y un sufijo de "%".
 */
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    // Desestructuración de las propiedades recibidas, extrayendo onChange y dejando el resto en 'other'
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}                       // Propiedades adicionales pasadas al NumericFormat
        getInputRef={ref}                // Referencia al input para controlar el enfoque
        onValueChange={(values) => {      // Maneja el cambio de valor formateado
          onChange({
            target: {
              name: props.name,           // Nombre del input
              value: values.value,        // Valor formateado
            },
          });
        }}
        allowNegative={false}             // No permite valores negativos
        decimalScale={2}                  // Limita a dos decimales
        thousandSeparator                 // Añade separadores de miles
        valueIsNumericString             // El valor es una cadena numérica
        prefix=""                         // Sin prefijo
        suffix={"%"}                      // Añade un sufijo de porcentaje
        isAllowed={(values) => {          // Función para validar si el valor es permitido
          const { floatValue } = values;
          //   const MAX_LIMIT = 120;      // Comentado: Límite máximo originalmente establecido en 120
          if (floatValue) {
            return floatValue <= 100;     // Permite solo valores hasta 100
          }
          return true;                     // Permite valores sin flotantes
        }}
      />
    );
  }
);

/**
 * Componente principal que renderiza un input de texto con formato numérico personalizado.
 * Permite ingresar valores numéricos con validaciones y formatos específicos.
 *
 * @param props - Propiedades recibidas por el componente
 * @returns Un contenedor con un TextField personalizado
 */
export default function ModalInputGeneral({
  handleChange, // Función para manejar cambios en el input
  value,        // Valor actual del input
  disable,      // Booleano para deshabilitar el input
  index,        // Índice del elemento en una lista (si aplica)
  name,         // Nombre del input
}: any) {
  return (
    <ContainerInput>
      {/* Campo de texto con formato numérico personalizado */}
      <TextField
        sx={{ maxWidth: "120px" }} // Estilo en línea para establecer el ancho máximo del input
        value={value} // Valor actual del input
        onChange={(e) => {
          // Valida el valor ingresado utilizando una expresión regular
          if (
            regexValidateNumber.test(e.target.value) || // Verifica si el valor coincide con la regex
            e.target.value === ""                       // Permite campos vacíos
          ) {
            handleChange(e.target.value);               // Llama a la función de manejo de cambio si la validación pasa
          }
        }}
        name={name} // Nombre del input
        InputProps={{
          inputComponent: NumericFormatCustom as any, // Asigna el componente de formato personalizado
        }}
        disabled={disable} // Deshabilita el input si es necesario
      />
    </ContainerInput>
  );
}

/**
 * Componente que renderiza las columnas de datos de tipo 'D' en una tabla.
 * Mapea sobre los datos y renderiza un input formateado para cada objetivo de tipo 'G'.
 *
 * @param props - Propiedades recibidas por el componente
 * @returns Fragmento con filas de datos formateados
 */
export const ColumnsDataD = ({
  dataMap,           // Mapa de datos a renderizar
  semestre,          // Semestre actual
  objetivesTypeG,    // Array de objetivos de tipo 'G'
  setObjetivesTypeG, // Función para actualizar los objetivos de tipo 'G'
}: any) => {
  // Hook personalizado para determinar si se puede seleccionar el objetivo por defecto
  const { ableSelectDefaultObj } = useAbleFutureObjetivesDefaults();

  /**
   * Función para manejar el cambio de valor en un objetivo específico.
   *
   * @param value - Nuevo valor ingresado
   * @param index - Índice del objetivo en el array
   */
  const handleChangeValue = (value: number | string, index: number) => {
    // Mapea sobre los objetivos para actualizar el valor en el índice específico
    const changedValue = objetivesTypeG.map((item: any, i: number) => {
      if (i === index) {
        return {
          ...item,
          valor: value, // Actualiza el valor del objetivo
        };
      } else {
        return item; // Retorna el objetivo sin cambios
      }
    });

    setObjetivesTypeG(changedValue); // Actualiza el estado de los objetivos
  };

  return (
    <>
      {dataMap &&
        dataMap?.map((item: any, index: number) => {
          if (item.type === "G") { // Verifica si el tipo del item es 'G'
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div>
                <div className="second">
                  {/*
                    Campo de texto con formato numérico personalizado para cada objetivo.
                    Deshabilitado si no se puede seleccionar el objetivo por defecto.
                  */}
                  <ContainerInput>
                    <TextField
                      sx={{ maxWidth: "120px" }} // Estilo en línea para establecer el ancho máximo del input
                      value={objetivesTypeG[index]?.valor} // Valor actual del input
                      onChange={(e) => {
                        // Valida el valor ingresado utilizando una expresión regular
                        if (
                          regexValidateNumber.test(e.target.value) || // Verifica si el valor coincide con la regex
                          e.target.value === ""                       // Permite campos vacíos
                        ) {
                          handleChangeValue(e.target.value, index); // Llama a la función de manejo de cambio si la validación pasa
                        }
                      }}
                      name="valor" // Nombre del input
                      InputProps={{
                        inputComponent: NumericFormatCustom as any, // Asigna el componente de formato personalizado
                      }}
                      disabled={ableSelectDefaultObj} // Deshabilita el input si no se puede seleccionar el objetivo por defecto
                    />
                  </ContainerInput>
                </div>
                <div className="third">{item.description}</div>
                <div className="fourth">{item.meta}%</div>
              </ModalBodyTableDos>
            );
          }

          return null; // Retorna null si el tipo del item no es 'G'
        })}
    </>
  );
}

/**
 * Contenedor estilizado para el input.
 * Aplica estilos específicos a los elementos internos de Material-UI para una apariencia personalizada.
 */
const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  /* Estilos para la raíz del input de Material-UI */
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;                              // Ancho del 85%
    margin: 0 auto;                          // Centra el input horizontalmente
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos para el control de formulario de Material-UI */
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;                           // Utiliza flexbox para la disposición de los elementos hijos
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
    align-items: center;                     // Alinea verticalmente al centro
    justify-content: center;                 // Alinea horizontalmente al centro
    outline: none;                           // Sin contorno al enfocar
    align-content: center;                   // Alinea el contenido al centro
  }

  /* Elimina el contorno al enfocar el input */
  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }

  /* Estilos base para la raíz del input de Material-UI */
  .MuiInputBase-root {
    outline: none;                           // Sin contorno al enfocar
    font-family: Plus Jakarta Sans;          // Familia de fuente personalizada
  }
`;
