// @ts-nocheck

// Función para obtener el nombre de clase basado en un porcentaje dado
export const getClassNameBasedOnPercentage = (percentage: any) => {
  if (percentage <= 79) {
    return "bajo"; // Retorna "bajo" si el porcentaje es 79 o menor
  } else if (percentage <= 99) {
    return "regular"; // Retorna "regular" si el porcentaje está entre 80 y 99
  } else if (percentage <= 119) {
    return "bueno"; // Retorna "bueno" si el porcentaje está entre 100 y 119
  } else {
    return "ecxelente"; // Retorna "excelente" si el porcentaje es 120 o mayor
  }
};

// Función para sumar todos los elementos de un arreglo de números
export const suma = (array: number[]) => {
  let suma = 0; // Inicializa la suma en 0
  for (let i = 0; i < array?.length; i++) {
    suma += array[i]; // Añade cada elemento al total de la suma
  }
  return suma; // Retorna el resultado de la suma
};

// Función para aplicar reglas de KPI basadas en el tipo de objetivo y porcentaje
export const reglasKPIs = (
  valor: number,
  porcentaje: any,
  objType: string,
  real: any
) => {
  let newNumChanged = porcentaje; // Variable para almacenar el porcentaje procesado

  // Verifica si el porcentaje es una cadena y elimina el símbolo de porcentaje si está presente
  if (typeof porcentaje === "string") {
    if (porcentaje.split("").includes("%")) {
      newNumChanged = porcentaje
        .split("")
        .filter((letra: string) => letra !== "%")
        .join("");
    } else {
      newNumChanged = porcentaje;
    }
  }

  // Si el porcentaje es nulo, retorna 0
  if (porcentaje === null) {
    return 0;
  }

  // Definición de reglas para diferentes tipos de objetivos
  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0; // Si el porcentaje es 79 o menor, retorna 0
      if (p <= 99) return 0.8 * v; // Si está entre 80 y 99, retorna 80% del valor
      if (p <= 119) return 1 * v; // Si está entre 100 y 119, retorna el valor completo
      return 1.2 * v; // Si es 120 o mayor, retorna 120% del valor
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100, // Para tipo "D", retorna (valor * porcentaje) / 100
  };

  const ruleFunction = rules[objType]; // Selecciona la función de regla basada en el tipo de objetivo

  // Si el valor real es nulo, retorna 0
  if (real === null) {
    return 0;
  }

  // Si el valor real es 0 y el tipo de objetivo no es "D", retorna 0
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0; // Aplica la regla correspondiente
    return Math.round(result); // Retorna el resultado redondeado
  }
};

// Función similar a reglasKPIs pero con condiciones adicionales para valores indefinidos
export const reglasKPIsDefault = (
  valor: number,
  porcentaje: any,
  objType: string,
  real: any
) => {
  // console.log(" valor: -> ", valor, "porcentaje ->", porcentaje,"real ->", real, "tipo ->",objType)

  let newNumChanged = porcentaje; // Variable para almacenar el porcentaje procesado

  // Verifica si el porcentaje es una cadena y elimina el símbolo de porcentaje si está presente
  if (typeof porcentaje === "string") {
    if (porcentaje.split("").includes("%")) {
      newNumChanged = porcentaje
        .split("")
        .filter((letra: string) => letra !== "%")
        .join("");
    } else {
      newNumChanged = porcentaje;
    }
  }

  // Si el porcentaje es nulo, retorna 0
  if (porcentaje === null) {
    return 0;
  }

  // Definición de reglas para diferentes tipos de objetivos
  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100,
  };

  const ruleFunction = rules[objType]; // Selecciona la función de regla basada en el tipo de objetivo

  // Si el valor real es nulo o indefinido, retorna 0
  if (real === null && real === undefined) {
    return 0;
  }

  // Si el valor real es 0 y el tipo de objetivo no es "D", retorna 0
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0; // Aplica la regla correspondiente
    return Math.round(result); // Retorna el resultado redondeado
  }
};

// Función para calcular el porcentaje de alcance en objetivos generales
export const porcentajeDeAlcanceGenerales = (meta: any, real: any) => {
  // console.log("Entra Real!!!! - ", real, meta );

  if (real === "") {
    return null; // Retorna null si el valor real está vacío
  }

  if (meta !== 0 || real !== 0) {
    let porcentaje = (real * 100) / meta; // Calcula el porcentaje de alcance
    const res = porcentaje;

    if (res === Infinity) {
      return 0; // Retorna 0 si el resultado es infinito
    }
    if (!Number.isNaN(res)) {
      return res.toFixed(0); // Retorna el porcentaje redondeado a 0 decimales
    }
  }
  return 0; // Retorna 0 si las condiciones anteriores no se cumplen
};

// Expresión regular para validar números, permitiendo números negativos
export const regexValidateNumber = /^-?[0-9]/;

// Función específica para reglas de KPI tipo "D"
export const reglasKPIsD = (
  valor: number,
  meta: any,
  objType: string,
  real: any
) => {
  let porcentaje = (real * 100) / meta; // Calcula el porcentaje basado en real y meta

  // console.log("Valor", porcentaje.toFixed(0), real, meta);

  let newNumChanged = porcentaje; // Variable para almacenar el porcentaje calculado

  // Definición de reglas para diferentes tipos de objetivos
  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100,
  };

  const ruleFunction = rules[objType]; // Selecciona la función de regla basada en el tipo de objetivo

  // Si el valor real es nulo, retorna 0
  if (real === null) {
    return 0;
  }

  // Si el valor real es 0 y el tipo de objetivo no es "D", retorna 0
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0; // Aplica la regla correspondiente
    return Math.round(result); // Retorna el resultado redondeado
  }
};

// Función para calcular el porcentaje de alcance basado en el tipo y valor
export const porcentajeAlcanceDefault = (
  type: string,
  value: any,
  meta?: any
) => {
  if (type === "Empresa") {
    return value * 0.24 * 100; // Calcula 24% del valor y lo multiplica por 100
  }

  if (type === "Desempenio") {
    if (value <= 39) return 0; // Retorna 0 si el valor es 39 o menor
    if (value <= 59) return 80; // Retorna 80 si el valor está entre 40 y 59
    if (value <= 79) return 100; // Retorna 100 si el valor está entre 60 y 79
    if (value > 79) return 120; // Retorna 120 si el valor es mayor que 79
  }

  if (type === "Generales") {
    return (value * 100) / meta; // Calcula el porcentaje basado en valor y meta
  }
};
