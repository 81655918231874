// Importación de styled-components para crear componentes estilizados
import styled from "styled-components";

// Definición de tipos de propiedades para los componentes estilizados
type PropTypeBgTres = {
  marginBtm?: any;
  disabled?: any;
  colorBtn?: any;
};

type PropTypeFutureGoalsCard = {
  borderColor?: boolean;
};

/**
 * Contenedor para el nuevo título.
 * Utiliza flexbox para alinear elementos y aplica estilos de margen y borde.
 */
export const NewTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 16px;
  margin-bottom: 16px;

  /* Comentarios de estilos que han sido deshabilitados */
  /* gap: 10px; */
  /* border: 1px solid red; */

  /* Estilo para el título dentro del contenedor */
  .titleStyle {
    color: var(--primary-true-blue, #3b63a2);
    margin: 0px;
    /* Estilo de fuente */
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    /* border: 1px solid; */
    font-weight: 700;
    line-height: 100%;
  }

  /* Estilo para el contenedor de íconos */
  .iconContainer {
    margin-top: 6px;
    display: flex;
    justify-content: center;
  }
`;

/**
 * Contenedor principal del formulario.
 * Ajusta el tamaño de la caja y aplica márgenes.
 * Elimina el borde en pantallas más pequeñas.
 */
export const FormContainer = styled.div`
  box-sizing: border-box;
  height: fit-content;
  /* width: 100%; */
  margin-left: 20px;
  /* max-width: 500px; */

  @media screen and (max-width: 1287px) {
    border: none !important;
  }
`;

/**
 * Contenedor para los títulos dentro del formulario.
 * Distribuye los elementos con un espacio de 16px entre ellos.
 */
export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0;
`;

/**
 * Título del modal con una fuente importada.
 * Define el tamaño y color del texto.
 */
export const TitleModal = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Bangers&display=PlusJakartaSans");

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #3b63a2;
  width: 245px;
  height: 22px;
`;

/**
 * Wrapper para el título que incluye íconos u otros elementos.
 * Alinea y distribuye los elementos con un espacio de 8px.
 */
export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  font-size: 18px;
  color: #3b63a2;
`;

/**
 * Contenedor para los elementos de selección (select).
 * Distribuye los elementos con un espacio de 8px.
 */
export const SelectContainer = styled.div`
  display: flex;
  gap: 8px;
`;

/**
 * Contenedor para la autoevaluación.
 * Alinea los elementos al final y aplica márgenes y espacios.
 */
export const AutoEvaluacionContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 4.25%;
  gap: 12px;
  margin-top: 6px;

  /* Estilo para elementos con la clase 'data' */
  .data {
    font-size: 14px;
    font-weight: 700;
  }
`;

/**
 * Título del formulario con borde inferior.
 * Distribuye los elementos con un espacio de 3.5px entre ellos.
 */
export const FormTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 3.5px;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 24px;
  width: 96%;
`;

/**
 * Encabezado para las instrucciones de la evaluación.
 * Alinea los elementos con espacio entre ellos.
 */
export const EvaluationInstructionsHeading = styled.div`
  margin-bottom: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Lista de instrucciones para la evaluación.
 * Aplica padding y elimina los estilos de lista.
 * Ajusta el diseño en pantallas grandes.
 */
export const EvaluationInstructionsList = styled.ul`
  padding: 0 4rem;
  list-style: none;
  margin-bottom: 29px;

  @media screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;

/**
 * Lista ordenada para las preguntas de la evaluación.
 * Elimina el padding por defecto.
 */
export const QuestionList = styled.ol`
  padding: 0;
`;

/**
 * Elemento de lista para cada pregunta.
 * Aplica márgenes y alinea los elementos con espacio entre ellos.
 */
export const QuestionListItem = styled.li`
  margin: 1rem;
  height: 40px;
  display: flex;
  line-height: 22px;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #d9d9d9; */

  /* Estilo para los spans dentro del ítem */
  span {
    font-weight: 300;
    font-size: 14px;
  }
`;

/**
 * Texto de cada pregunta.
 * Limita el ancho y aplica margen derecho.
 */
export const TextItem = styled.span`
  max-width: 300px;
  margin-right: 1rem;
  font-weight: 500;
`;

/**
 * Elemento de selección personalizado.
 * Estiliza el select y añade una flecha personalizada.
 */
export const SelectOption = styled.select.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 5px 2px; */
  gap: 8px;
  width: 71px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 8px;
  padding-left: 12px;

  /* Añade una flecha personalizada al select */
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;

  /* Posiciona la flecha personalizada */
  background-position: calc(100% - 0.6rem) center !important;

  /* Elimina la apariencia por defecto del navegador */
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
`;

/**
 * Contenedor para el select que ajusta la opacidad según la propiedad 'colorBtn'.
 */
export const ContainerSelect = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  opacity: ${(props) => (props.colorBtn ? ".45" : ".9")};
`;

/**
 * Contenedor para mostrar la suma de resultados.
 * Aplica estilos de fondo, margen y alineación.
 */
export const PlusResult = styled.div`
  /* border: 1px solid red; */
  padding: 10px;
  background-color: #f4fcff;
  font-weight: 700;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* Espacio entre los spans */
  span {
    margin-right: 2rem;
  }

  /* Estilo para la clase 'sumaClass' */
  .sumaClass {
    color: #c1c1c3;
  }

  /* Espaciado para la clase 'totalRightSpacing' */
  .totalRightSpacing {
    margin-right: 1.5rem;
  }

  /* Estilos para la clase 'finalQualification' */
  .finalQualification {
    width: 61px;
    padding: 5px 16px;
    border-radius: 2px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }
`;

/**
 * Contenedor para los resultados de la evaluación.
 * Organiza los elementos en una columna con espacios entre ellos.
 */
export const ResultsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  column-gap: 1.75rem;
  height: fit-content;
  /* border: 1px solid #d9d9d9; */
`;

/**
 * Contenedor individual para cada resultado.
 * Alinea los elementos al final y aplica márgenes.
 */
export const Results = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* Espacio entre los spans */
  span {
    margin-right: 2rem;
  }

  /* Espaciado para la clase 'totalRightSpacing' */
  .totalRightSpacing {
    margin-right: 1.5rem;
  }

  /* Estilos para la clase 'finalQualification' */
  .finalQualification {
    width: 61px;
    padding: 5px 16px;
    border-radius: 2px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }
`;

/**
 * Contenedor para los comentarios evaluados.
 * Organiza los elementos en una columna.
 */
export const EvaluatedCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: start; */
  /* justify-content: flex-start; */

  /* margin: 1rem 0; */
`;

/**
 * Contenedor para cada comentario.
 * Utiliza flexbox para alinear elementos y aplica padding inferior.
 */
export const CommentContainer = styled.form`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 12px;
`;

/**
 * Fila de comentarios evaluados.
 * Aplica padding, fondo blanco y bordes redondeados.
 * Ajusta el diseño en pantallas grandes.
 */
export const EvaluatedCommentsRow = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;

  @media screen and (min-width: 1024px) {
    width: 99%;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

/**
 * Número del ítem en los comentarios.
 * Aplica estilos de fuente y color.
 */
export const NumberItem = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #3b63a2;
`;

/**
 * Contenedor para el título de una sección.
 * Aplica márgenes y organiza los elementos en una columna.
 */
export const TitleBox = styled.div`
  margin: 40px 0 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

/**
 * Texto del título principal.
 * Define estilos de fuente y color.
 */
export const TitleText = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #3b63a2;
  margin: 0;
`;

/**
 * Texto previo o subtítulo.
 * Aplica estilos de fuente y color más suave.
 */
export const PrevText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
`;

/**
 * Divisor para separar contenido.
 * Ajusta el padding y el ancho.
 * Elimina el borde en pantallas pequeñas.
 */
export const Divisor = styled.div`
  /* border-right: 0.5px solid #939396; */
  /* width: 50%; */
  padding-right: 0%;
  width: 100%;

  @media (min-width: 320px) and (max-width: 1026px) {
    border-right: none;
    padding-right: 0%;
  }
`;

// Comentario de código comentado (no modificado)
/* export const AlignForm = {
  width: "50%",
}; */

/**
 * Objeto para alinear contenido con margen izquierdo negativo.
 */
export const AlignContent = {
  marginLeft: "-0.2rem",
};

/**
 * Contenedor del cuerpo del modal para los objetivos.
 * Aplica ancho completo y define la fuente.
 */
export const ModalContainerBody = styled.div`
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  font-family: "Plus Jakarta Sans";
`;

/**
 * Contenedor del cuerpo del modal que incluye un selector.
 * Aplica padding y define la fuente.
 */
export const ModalContainerBodySelect = styled.div`
  width: 100%;
  padding: 14px;
  font-family: "Plus Jakarta Sans";
`;

/**
 * Encabezado de la tabla dentro del modal.
 * Aplica fondo azul, altura fija y bordes redondeados en la parte superior.
 * Organiza las columnas con ancho específico.
 */
export const ModalHeadTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #3b63a2;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;

  /* Definición de columnas dentro del encabezado */
  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Wrapper para el cuerpo de la tabla que permite el desplazamiento horizontal.
 */
export const BodyTableWrapper = styled.div`
  overflow-x: auto;
`;

/**
 * Wrapper adicional para el cuerpo de la tabla con un ancho mínimo.
 * Asegura que la tabla no se reduzca demasiado en pantallas pequeñas.
 */
export const BodyTableWrapperWrap = styled.div`
  overflow-x: auto;
  min-width: 1000px;
`;

/**
 * Cuerpo de la tabla dentro del modal.
 * Aplica bordes condicionales y organiza las columnas con estilos específicos.
 */
export const ModalBodyTable = styled.div.attrs(
  (props: PropTypeFutureGoalsCard) => ({})
)<PropTypeFutureGoalsCard>`
  border: ${(props) => (props.borderColor ? "none" : "1px solid red")};
  display: flex;
  align-items: center;
  height: auto;
  color: #ffffff;
  width: 100%;
  padding: 8px;
  margin: 2px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  /* Definición de columnas dentro del cuerpo de la tabla */
  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */

    /* Estilo para el input dentro de la segunda columna */
    .inputSecond {
      padding: 5px 10px;
      width: 65%;
      min-width: 130px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
  .third {
    width: 35%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 25%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la cuarta columna */
    .inputFourth {
      padding: 5px 10px;
      width: 65%;
      min-width: 130px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

/**
 * Segundo cuerpo de la tabla dentro del modal con estilos alternativos.
 */
export const ModalBodyTableDos = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: auto;
  color: #ffffff;
  width: 100%;
  padding: 8px;
  margin: 2px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  /* Definición de columnas dentro del cuerpo de la tabla */
  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la segunda columna */
    .inputSecond {
      padding: 5px 10px;
      width: 40%;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
    }
  }
  .third {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .fourth {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Footer de la tabla dentro del modal.
 * Aplica estilos de fuente y alineación condicional.
 */
export const ModalFooterTable = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  margin: 2px 0;
  border-radius: 2px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 1px;

  /* border: ${(props) => (props.colorBtn ? "1px solid red" : "none")}; */

  /* Definición de columnas dentro del footer */
  .second {
    width: 44%;
    display: flex;
    justify-content: end;
  }
  .first {
    width: 27%;
    display: flex;
    justify-content: end;
  }
`;

/**
 * Footer de la tabla con un selector personalizado.
 * Ajusta el borde según la propiedad 'colorBtn' y organiza las columnas.
 */
export const ModalFooterTableMySelect = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  margin: 3px 0;
  border-radius: 2px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 0.75px;
  border: ${(props) => (props.colorBtn ? "1px solid red" : "none")};

  /* Definición de columnas dentro del footer */
  .first {
    width: 30%;
    display: flex;
    justify-content: end;
  }

  .second {
    width: 30%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: end;
  }
  .third {
    width: 54%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
  }
`;

/**
 * Contenedor para valores y metas alcanzadas.
 * Utiliza flexbox para organizar los elementos y aplica estilos responsivos.
 */
export const ValueAndReach = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  /* border: 1px solid red; */
  margin-top: 40px;

  /* Contenedor para la primera sección */
  .firstContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .first {
    width: 100%;
  }

  /* Contenedor para la segunda sección */
  .second {
    width: 30%;
    /* padding-top: 43px; */
  }

  /* Estilos responsivos para pantallas con ancho máximo de 1300px */
  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    .firstContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .first {
      width: 100%;
    }

    .second {
      width: 100%;
    }
  }
`;

/**
 * Wrapper para la tabla de metas futuras.
 * Ajusta estilos de inputs específicos de Material-UI.
 */
export const WrapperGoalsTable = styled.div`
  width: 100%;
  /* border: 1px solid #d9d9d9; */

  /* Estilos para inputs de Material-UI dentro del wrapper */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  /* Elimina el outline al enfocar el input */
  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  /* Estilos base para los inputs */
  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;

/**
 * Encabezado de la tabla de metas.
 * Aplica fondo gris, altura fija y bordes redondeados en la parte superior.
 * Organiza las columnas con ancho específico.
 */
export const GoalsHeadTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  /* justify-content: space-between; */

  /* Definición de columnas dentro del encabezado */
  .first {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

//!! ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

/**
 * Cuerpo de la tabla de metas.
 * Aplica estilos de fondo y organiza las columnas con estilos específicos.
 */
export const GoalsBodyTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  margin: 2px 0;
  /* text-align: center; */
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  /* Definición de columnas dentro del cuerpo de la tabla */
  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la primera columna */
    .inputFirst {
      padding: 5px 2px;
      width: 40%;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
    }
  }

  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Estilo para el input dentro de la segunda columna */
    .inputSecond {
      padding: 6px 0px 6px 4px;
      border-radius: 8px;
      /* width: 60%; */
      min-width: 110px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
      /* border: 12px solid; */
    }
  }
  .third {
    width: 57%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la cuarta columna */
    .inputFourth {
      padding: 6px 0px 6px 4px;
      min-width: 110px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;
//!! ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

/**
 * Card para metas futuras con estilos condicionales de borde.
 */
export const FutureGoalsCard = styled.div.attrs(
  (props: PropTypeFutureGoalsCard) => ({})
)<PropTypeFutureGoalsCard>`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  margin: 2px 0;
  /* text-align: center; */
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  border: ${(props) => (props.borderColor ? "none" : "1px solid red")};

  /* Definición de columnas dentro del card */
  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la primera columna */
    .inputFirst {
      padding: 5px 2px;
      width: 40%;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
    }
  }

  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Estilo para el input dentro de la segunda columna */
    .inputSecond {
      padding: 6px 0px 6px 4px;
      border-radius: 8px;
      /* width: 60%; */
      min-width: 110px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
      /* border: 12px solid; */
    }
  }
  .third {
    width: 57%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Estilo para el input dentro de la cuarta columna */
    .inputFourth {
      padding: 6px 0px 6px 4px;
      min-width: 110px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

/**
 * Footer de la tabla de metas.
 * Aplica estilos de fondo, alineación y espaciado entre columnas.
 */
export const GoalFooterTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  /* border-radius: 8px 8px 0px 0px; */
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  /* padding: 24px; */
  margin: 2px 0;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #f8f8f8;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 1px;
  /* border: 1px solid #d9d9d9; */
  gap: 15px;

  /* Definición de columnas dentro del footer */
  .first {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .second {
    width: 62%;
  }
`;

//! Estilos de Políticas

/**
 * Encabezado de la tabla de políticas.
 * Aplica fondo gris, bordes redondeados y organiza las columnas.
 */
export const GoalsHeadTablePoliticas = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  /* height: 48px; */
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 16px 8px;
  font-family: "Plus Jakarta Sans";
  /* justify-content: space-between; */
  margin-bottom: 5px;

  /* Definición de columnas dentro del encabezado */
  .first {
    width: 10%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 12%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 23%;
    /* border: 1px solid; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sixth {
    width: 24%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Cuerpo de la tabla de políticas.
 * Aplica estilos de fondo y organiza las columnas con espacios.
 */
export const GoalsBodyTablePoliticas = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  /* justify-content: space-between; */

  margin-bottom: 4px;

  /* Definición de columnas dentro del cuerpo de la tabla */
  .first {
    width: 10%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 12%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 23%;
    /* border: 1px solid; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sixth {
    width: 24%;
    /* border: 1px solid; */
    padding: 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`;

/**
 * Estilo para el texto dentro del cuerpo del modal.
 * Aplica font-weight y color específico.
 */
export const StlyeTextBodyModal = styled.p`
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: #939396;
`;
