import styled from "styled-components"; // Importa la librería styled-components para crear componentes con estilos CSS.
import Close from "../../../assets/icons/CloseModal.svg"; // Importa el icono de cerrar modal.
import AlertModalIcon from "../../../assets/icons/caution.svg"; // Importa el icono de alerta para el modal.
import { ModalAlertWrapper } from "../ModalActions/ModalEditStyles"; // Importa estilos predefinidos para el contenedor del modal.
import { useCollaboratorRedux } from "../../../redux/Collaborators"; // Importa el hook para obtener datos de colaboradores desde Redux.
import { useLocation } from "react-router-dom"; // Importa el hook useLocation para acceder a la ruta actual.

// Define el componente AlertModal para mostrar una ventana modal de alerta.
const AlertModal = ({ show, onHide, actionModal, message }: any) => {
  const { User } = useCollaboratorRedux(); // Obtiene los datos del usuario desde el estado de Redux.
  const location = useLocation(); // Obtiene la información de la ubicación actual del usuario.

  const onClose = () => {
    onHide(false); // Cierra el modal al cambiar el estado de show a false.
  };

  // console.log("User --->", User); // Comentado: imprime los datos del usuario (debugging).

  return (
    <ModalAlertWrapper display={show}> {/* Contenedor principal del modal, controlado por el prop 'show'. */}
      <div className="modalWrapper"> {/* Contenedor del contenido del modal. */}
        <div className="close"> {/* Sección del encabezado con título e icono de cerrar. */}
          <div className="title"> {/* Título con icono de alerta. */}
            <img src={AlertModalIcon} className="image" alt="AlertModalIcon" /> {/* Icono de alerta. */}
            ¡Importante! {/* Texto del título. */}
          </div>
          <div className="closeModal" onClick={(e) => onClose()}> {/* Botón para cerrar el modal. */}
            <img src={Close} alt="close" style={{ height: "16px" }} /> {/* Icono de cerrar modal. */}
          </div>
        </div>
        <ContainerModalText> {/* Contenedor del texto principal del modal. */}
          <div className="textContainer"> {/* Sección con el mensaje de confirmación. */}
            Estás a punto de {message} la descripción de
            {/* Condicional para mostrar el texto adecuado según la ruta y el rol del usuario. */}
            {(!location.pathname.includes('Evaluacion')) && (User.role === 'Evaluador' || User.role === 'Admin RH' || User.role === 'Admin TI')
              ? ' objetivos para tu colaborador'
              : ' tu objetivo'}
            .
            <br />
            <br />
            ¿Deseas continuar con esta acción? {/* Pregunta de confirmación. */}
          </div>
        </ContainerModalText>
        <FooterModal> {/* Pie del modal con botones de acción. */}
          <ButtonModalSave
            onClick={() => { // Al hacer clic en "Continuar" ejecuta la acción y cierra el modal.
              actionModal();
              onClose();
            }}
          >
            Continuar {/* Texto del botón guardar. */}
          </ButtonModalSave>
          <ButtonModalCancel
            onClick={() => { // Al hacer clic en "Cancelar", solo cierra el modal.
              onClose();
            }}
          >
            Cancelar {/* Texto del botón cancelar. */}
          </ButtonModalCancel>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default AlertModal; // Exporta el componente AlertModal.

// Define un área de texto estilizada para ingresar o mostrar texto sin bordes y sin opción de redimensionar.
export const InputObj = styled.textarea`
  width: 100%; // Ocupar el 100% del ancho disponible.
  height: 100%; // Ocupar el 100% de la altura disponible.
  border: none; // Elimina el borde predeterminado.
  outline: none; // Elimina el contorno al enfocar.
  resize: none; // Evita que el textarea sea redimensionado.
`;

// Contenedor de texto para el modal con estilos.
export const ContainerModalText = styled.div`
  width: 100%; // Ocupar el 100% del ancho disponible.
  padding: 14px 21px; // Espaciado interno.
  margin-top: 10px; // Margen superior.

  .textContainer {
    font-size: 16px; // Tamaño de la fuente.
    color: #333335; // Color del texto.
    font-weight: 400; // Peso de la fuente normal.
    padding-bottom: 5px; // Espaciado inferior.
  }

  .containerModal {
    width: 100%; // Ancho completo.
    background-color: #f8f8f8; // Color de fondo gris claro.
    border-radius: 4px; // Bordes redondeados.
    min-height: 100px; // Altura mínima de 100px.
    display: flex; // Caja flexible.
    justify-content: end; // Alinea el contenido al final del eje principal.
    font-size: 14px; // Tamaño de la fuente.

    .txt {
      margin-top: 8px; // Margen superior.
      padding: 8px; // Espaciado interno.
    }

    .containerTxt {
      background-color: white; // Fondo blanco.
      border-radius: 8px; // Bordes redondeados.
      font-weight: 700; // Texto en negrita.
      color: black; // Color del texto.
      font-size: 14px; // Tamaño de fuente.
      padding: 8px; // Espaciado interno.
      margin: 8px; // Margen general.
      margin-right: 24px; // Margen derecho adicional.
      width: 83%; // Ancho del 83%.
    }
  }
`;

// Define un pie del modal con botones centrados y espacio entre ellos.
export const FooterModal = styled.div`
  display: flex; // Caja flexible.
  width: 100%; // Ancho completo.
  justify-content: center; // Centra los elementos horizontalmente.
  gap: 32px; // Espaciado entre elementos.
  padding: 14px 21px; // Espaciado interno.
`;

// Botón estilizado para guardar o confirmar una acción.
export const ButtonModalSave = styled.div`
  background-color: #C9F2FF; // Color de fondo celeste.
  border-radius: 8px; // Bordes redondeados.
  font-size: 14px; // Tamaño de la fuente.
  font-family: "Plus Jakarta Sans"; // Fuente personalizada.
  font-weight: 700; // Texto en negrita.
  color: #3B63A2; // Color del texto azul.
  padding: 8px 24px; // Espaciado interno.
  cursor: pointer; // Cursor de puntero.
  width: 100%; // Ancho completo.
  display: flex; // Caja flexible.
  justify-content: center; // Centra el texto horizontalmente.
  height: 40px; // Altura fija de 40px.
  align-items: center; // Alinea el texto al centro verticalmente.
  align-content: center; // Alinea contenido adicionalmente.
`;

// Botón estilizado para cancelar una acción.
export const ButtonModalCancel = styled.div`
  background-color: #3B63A2; // Color de fondo azul oscuro.
  border-radius: 8px; // Bordes redondeados.
  font-size: 14px; // Tamaño de fuente.
  font-family: "Plus Jakarta Sans"; // Fuente personalizada.
  font-weight: 700; // Texto en negrita.
  color: #FFFFFF; // Color de texto blanco.
  padding: 8px 24px; // Espaciado interno.
  cursor: pointer; // Cursor de puntero.
  width: 100%; // Ancho completo.
  display: flex; // Caja flexible.
  height: 40px; // Altura fija de 40px.
  align-items: center; // Alinea el texto verticalmente.
  align-content: center; // Alinea contenido adicionalmente.
  justify-content: center; // Centra el texto horizontalmente.
`;
