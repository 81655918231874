/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
// Importaciones necesarias desde React y otras librerías
import { useEffect, useState } from "react";
import { http } from "../../../api";
import colladoLoader from '../../../assets/colladoLoader.gif';
import { validateIdPeriod } from "../../../context/validacionesFiltros";
// Importación de estilos personalizados para el componente
import { SearchSectionDownloadReportDos, SelectContainerDownloadReport, SelectDonwloadReport, SelectDonwloadReportDos, TextTypeReportDownloadReport } from "../../../pages/Admin/Dashboard/NewComponentsDashboardRefactor/ReporteColaboradores/DownloadReportCollaboratorsStyles";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { getPeriodsByYear } from "../../../services/dataSelects.service";
import { getPeriodYearsSemesters } from "../../../services/objetives.services";
// Importación de estilos específicos para botones y contenedores
import {
  CleanButton,
  DownLoadReportBox,
  DownloadButton,
  DownloadReportTitle,
  WrapperButtons
} from "./DownLoadReportStyles";

// Definición del componente DownLoadReport que recibe setSemestre como prop
const DownLoadReport = ({ setSemestre }: any) => {
  //** Uso de Redux para acceder al estado del usuario
  const {
    User
  } = useCollaboratorRedux();

  // Desestructuración de propiedades del usuario
  const {
    nombre: name,
    role,
    claTrab
  } = User;

  // Estados locales para manejar la carga, tipo de reporte y otras informaciones
  const [isLoading, setIsLoading] = useState(false);
  const [typeReport, setTypeReport] = useState("Completo");
  const [loadInfo, setLoadInfo] = useState(false)

  // Estados para manejar los años disponibles para descargar el reporte
  const [yearsDownloadReport, setYearsDownloadReport] = useState<any>([]);
  const [selectedOptionYearDownloadReport, setSelectedOptionYearDownloadReport] = useState(0);
  const [selectedPeriodDownloadReport, setSelectedPeriodDownloadReport] = useState("")

  // Estado para las opciones de semestres disponibles
  const [optionsOfSemester, setOptionsOfSemester] = useState<any>([]);

  /**
   * Función para obtener los periodos disponibles según el año seleccionado
   * @param year - Año seleccionado
   * @param action - Acción que determina el periodo a seleccionar
   */
  const getPeriodsDownloadReport = async (year: any, action: any) => {
    const { data } = await getPeriodsByYear(year);

    // Mapeo de los datos obtenidos para estructurarlos adecuadamente
    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));

    const firstPeriod = array[0];

    // Determinación del periodo seleccionado basado en la acción
    const selectedOption = action
      ? `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
      : `${firstPeriod.evaluationPeriodId},${firstPeriod.evaluationPeriodNumberOfYear}`;

    // Actualización del estado con el periodo seleccionado y las opciones disponibles
    setSelectedPeriodDownloadReport(selectedOption);
    setOptionsOfSemester(array);
  };

  /**
   * Función para obtener las opciones de años disponibles para descargar el reporte
   */
  const getYearsOptionsDownloadReport = async () => {
    const { data } = await getPeriodYearsSemesters();

    // Creación de una lista de años y sus IDs correspondientes
    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    // Ordenamiento de los años de forma ascendente
    yearList.sort((a, b) => a.year - b.year);

    //! Configuración de las opciones de descarga del reporte
    setYearsDownloadReport(yearList);
    getPeriodsDownloadReport(User.periodInfo.year, true);
  };

  /**
   * Maneja el cambio de opciones en los select de año y semestre
   * @param e - Evento del cambio
   * @param type - Tipo de selección ("Year" o "Semester")
   */
  const onChangeOptionSemestreYearDownloadReport = async (e: any, type: string) => {
    const { value } = e.target;

    if (value !== "") {
      if (type === "Year") {
        setSelectedOptionYearDownloadReport(Number(value));
        getPeriodsDownloadReport(Number(value), false);
      }

      if (type === "Semester") {
        setSelectedPeriodDownloadReport(value);
      }
    }
  }

  /**
   * Maneja la descarga del reporte basado en las opciones seleccionadas
   */
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const periodo = validateIdPeriod(selectedPeriodDownloadReport);
      const clave = claTrab.toString();

      // Determinación del endpoint basado en el tipo de reporte seleccionado
      const endpoint = typeReport === 'Resumido'
        ? `/DashboardContoller/generate-report?NombreColaborador=${name}&RolColaborador=${role === "Auditor" ? "Admin Ti" : role}&IdPeriod=${periodo}`
        : `/Evaluations/get-full-evaluations-report?IdPeriod=${periodo}&ClaTrab=${clave}`

      // Realización de la solicitud HTTP para obtener el reporte
      const response = await http.get(endpoint, {
        responseType: 'blob',
      });

      // Creación de una URL para el archivo binario recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Creación de un enlace temporal para iniciar la descarga
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report${year}${month}${day}.xlsx`);
      document.body.appendChild(link);

      // Simulación de un click en el enlace para iniciar la descarga
      link.click();

      // Eliminación del enlace temporal después de la descarga
      link.parentNode?.removeChild(link);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // Manejo de errores en caso de falla en la descarga
      // console.error('Error al descargar el archivo:', error);
    }
  };

  /**
   * Maneja el cambio en el tipo de reporte seleccionado
   * @param e - Evento del cambio
   */
  const handleChangeTypeReport = (e: any) => {
    const { value } = e.target;
    if (value) {
      setTypeReport(e.target.value);
    }
  }

  // Hook de efecto para cargar las opciones de años al montar el componente
  useEffect(() => {
    getYearsOptionsDownloadReport();
    setSelectedOptionYearDownloadReport(User?.periodInfo?.year);
    // setTimeout(() => {
    //   setLoadInfo(true)
    // }, 2000)
  }, []);

  // console.log(selectedOptionYearDownloadReport, selectedPeriodDownloadReport);

  /**
   * Maneja la limpieza de los valores seleccionados y restablece las opciones a los valores por defecto
   * @param e - Evento del formulario
   */
  const handleCleanValues = (e: any) => {
    e.preventDefault();
    // setLoadInfo(false)
    getPeriodsDownloadReport(User.periodInfo.year, true);
    setSelectedOptionYearDownloadReport(User?.periodInfo?.year);
  }

  return (
    <>
      {/* Contenedor principal para la descarga del reporte */}
      <DownLoadReportBox>
        {/* Título del apartado de descarga */}
        <DownloadReportTitle>
          Descargar reporte
        </DownloadReportTitle>
        {/* Sección de búsqueda y selección de opciones para el reporte */}
        <SearchSectionDownloadReportDos>
          <div className="selectContainer">
            {/* Contenedor para la selección del año */}
            <SelectContainerDownloadReport>
              <TextTypeReportDownloadReport>Año</TextTypeReportDownloadReport>
              <SelectDonwloadReport
                value={selectedOptionYearDownloadReport}
                name="semester"
                onChange={(e) => onChangeOptionSemestreYearDownloadReport(e, "Year")}
              >
                {/* Mapeo de los años disponibles como opciones en el select */}
                {yearsDownloadReport.map(({ year }: any) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectDonwloadReport>
            </SelectContainerDownloadReport>

            {/* Contenedor para la selección del semestre */}
            <SelectContainerDownloadReport>
              <TextTypeReportDownloadReport>Semestre</TextTypeReportDownloadReport>
              <SelectDonwloadReport
                value={selectedPeriodDownloadReport}
                name="semester"
                onChange={(e) => onChangeOptionSemestreYearDownloadReport(e, "Semester")}
              >
                {/* Mapeo de los semestres disponibles como opciones en el select */}
                {optionsOfSemester.map((periodName: any) => (
                  <option
                    key={periodName?.evaluationPeriodId}
                    value={`${periodName?.evaluationPeriodId},${periodName?.evaluationPeriodNumberOfYear}`}
                  >
                    {periodName?.evaluationPeriodNumberOfYear}
                  </option>
                ))}
              </SelectDonwloadReport>
            </SelectContainerDownloadReport>

            {/* Componente para seleccionar el tipo de reporte */}
            <SelecTypeOfReport
              valueSelect={typeReport}
              handleChange={handleChangeTypeReport}
            />
          </div>
        </SearchSectionDownloadReportDos>

        {/* Contenedor para los botones de limpiar y descargar */}
        <WrapperButtons>
          {/* Mostrar el loader si está en estado de carga */}
          {isLoading &&
            <img
              style={{ width: "42px" }}
              src={colladoLoader}
              alt=""
            />
          }
          {/* Botón para limpiar las selecciones */}
          <CleanButton onClick={handleCleanValues} >
            Limpiar
          </CleanButton>
          {/* Botón para iniciar la descarga del reporte */}
          <DownloadButton onClick={handleDownload} disabled={isLoading}>
            {isLoading ? "Descargando" : "Descargar"}
          </DownloadButton>
        </WrapperButtons>
      </DownLoadReportBox>
    </>
  );
};

// Exportación del componente DownLoadReport para su uso en otras partes de la aplicación
export { DownLoadReport };

// Definición del componente SelecTypeOfReport para seleccionar el tipo de reporte
const SelecTypeOfReport = ({
  valueSelect,
  handleChange,
}: any) => {

  return (
    <SelectContainerDownloadReport>
      <TextTypeReportDownloadReport>
        Tipo de reporte
      </TextTypeReportDownloadReport>

      <SelectDonwloadReportDos
        value={valueSelect}
        onChange={handleChange}
      >
        {/* Opciones disponibles para el tipo de reporte */}
        <option value="Completo">Completo</option>
        <option value="Resumido">Resumido</option>
      </SelectDonwloadReportDos>
    </SelectContainerDownloadReport>
  )
}
