import { useEffect, useState } from "react"; // Importa hooks de React para gestionar estados y efectos.
import styled from "styled-components"; // Importa la librería styled-components para crear estilos CSS en componentes.
import Close from "../../../assets/icons/CloseModal.svg"; // Importa el icono de cerrar modal.
import { ModalWrapperDos } from "../ModalActions/ModalEditStyles"; // Importa los estilos del contenedor del modal.
import AlertModal from "./AlertModal"; // Importa el componente AlertModal para mostrar alertas.

// Define un valor por defecto para la selección del objetivo.
const value = {
  label: "Selecciona un objetivo",
  semester: 2,
  value: null,
};

// Componente ModalObjetive para la edición y validación de descripciones de objetivos.
const ModalObjetive = ({
  show, // Indica si el modal está visible.
  onHide, // Función para cerrar el modal.
  handleChangeDos, // Maneja cambios en los datos.
  indexEdit, // Índice del objetivo a editar.
  data, // Datos de los objetivos.
}: any) => {
  const [alertModal, setAlertModal] = useState(false); // Estado para mostrar el modal de alerta.
  const [alertModalAction, setAlertModalAction] = useState(1); // Estado para definir la acción del modal.
  const [inputModal, setInputModal] = useState<any>(); // Estado para el valor del input modal.
  const [duplicateAlert, setDuplicateAlert] = useState<boolean>(false); // Estado para validar duplicados.

  // Función para cerrar el modal y resetear el estado de alerta.
  const onClose = () => {
    setDuplicateAlert(false); // Resetea la alerta de duplicados.
    const desc = data[indexEdit].descripcion.label; // Obtiene la descripción actual.
    setInputModal(desc || ""); // Establece el input con la descripción.
    onHide(false); // Cierra el modal.
  };

  // Maneja cambios en el campo de texto.
  const onChangeTextField = (e: any) => {
    setInputModal(e.target.value); // Actualiza el valor del input.

    // Validación de duplicados: verifica si el valor ya existe en otra descripción.
    setDuplicateAlert(
      data.some(
        (item: any, index: number) =>
          index !== indexEdit &&
          normalizeText(item.descripcion.label) === normalizeText(e.target.value)
      )
    );
  };

  // Función para normalizar texto eliminando espacios, diacríticos y caracteres especiales.
  const normalizeText = (text: string): string => {
    return text
      .toLowerCase()
      .normalize("NFD") // Normaliza el texto a Unicode NFD.
      .replace(/[\u0300-\u036f]/g, "") // Elimina diacríticos.
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\u00bf\u00a1]/g, "") // Remueve caracteres especiales.
      .replace(/\s+/g, " ") // Reemplaza múltiples espacios por uno.
      .trim(); // Elimina espacios al inicio y al final.
  };

  // Función para cancelar la edición y restaurar el valor original.
  const cancelObjective = () => {
    handleChangeDos(indexEdit, "descripcion", value, true); // Restaura el valor original.
    onClose(); // Cierra el modal.
  };

  // Función para guardar los cambios realizados.
  const onSubmit = () => {
    const newData = [...data]; // Crea una copia de los datos actuales.
    newData[indexEdit].semestre = data[indexEdit].semestre; // Mantiene el semestre actual.
    newData[indexEdit].descripcion = {
      label: inputModal, // Actualiza la descripción con el nuevo valor.
      semester: data[indexEdit].descripcion.semester,
      value: data[indexEdit].descripcion.value,
    };

    onHide(false); // Cierra el modal.
  };

  // Hook useEffect: inicializa el valor del input modal cuando cambian los datos.
  useEffect(() => {
    if (data[indexEdit]) {
      setInputModal(data[indexEdit].descripcion.label || "");
    }
  }, [data, indexEdit]);

  return (
    <ModalWrapperDos display={show} activeAlert={duplicateAlert}> {/* Contenedor principal del modal. */}
      <div className="modalWrapper"> {/* Contenedor del contenido del modal. */}
        <div className="close"> {/* Encabezado del modal. */}
          <div className="title">Edición de descripción de objetivos</div> {/* Título del modal. */}
          <div className="closeModal" onClick={(e) => onClose()}> {/* Botón para cerrar el modal. */}
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <ContainerModalText> {/* Contenedor del texto y el input. */}
          <div className="textContainer">
            A continuación, podrás editar o borrar el mensaje escrito en la descripción de objetivos.
          </div>
          <div className="containerModal"> {/* Contenedor para el campo de texto. */}
            <div className="txt">Oración</div>
            <div className="containerTxt">
              <InputObj onChange={onChangeTextField} value={inputModal} /> {/* Input para editar el objetivo. */}
            </div>
          </div>
        </ContainerModalText>
        {duplicateAlert && (
          <div className="active-alert-modal"> {/* Mensaje de alerta para duplicados. */}
            No se permite guardar objetivos duplicados.
          </div>
        )}
        <FooterModal> {/* Pie del modal con botones. */}
          <ButtonModalSave
            color={!duplicateAlert ? "3b63a2" : ""} // Estilo condicionado según la alerta de duplicado.
            onClick={() => {
              if (!duplicateAlert) {
                setAlertModalAction(1);
                setAlertModal(true); // Muestra el modal de confirmación para guardar.
              }
            }}
          >
            Guardar
          </ButtonModalSave>
          <ButtonModalErase
            onClick={(e) => {
              setAlertModalAction(2);
              setAlertModal(true); // Muestra el modal de confirmación para borrar.
            }}
          >
            Borrar
          </ButtonModalErase>
          <ButtonModalCancel onClick={() => onClose()}> {/* Botón para cancelar la edición. */}
            Cancelar
          </ButtonModalCancel>
        </FooterModal>
      </div>

      <AlertModal
        show={alertModal} // Estado del modal de alerta.
        onHide={setAlertModal} // Función para cerrar el modal de alerta.
        actionModal={alertModalAction === 1 ? onSubmit : cancelObjective} // Define la acción según el tipo de alerta.
        message={alertModalAction === 1 ? 'editar' : 'borrar'} // Mensaje de confirmación.
      />
    </ModalWrapperDos>
  );
};

export default ModalObjetive; // Exporta el componente ModalObjetive.

// Estilo del input de texto.
export const InputObj = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
`;

// Contenedor del contenido del modal.
export const ContainerModalText = styled.div`
  width: 100%;
  padding: 14px 21px;
  margin-top: 60px;

  .textContainer {
    font-size: 16px;
    color: #939396;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
      margin-right: 24px;
      width: 83%;
      border: 1px solid #f0f0f0;
    }
  }
`;

// Pie del modal con botones.
export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
  gap: 8px;
  padding: 14px 21px;
`;

// Botón para guardar cambios.
export const ButtonModalSave = styled.div`
  background-color: ${(props) => (props.color ? "#3b63a2" : "#C1C1C3")};
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: ${(props) => (props.color ? "#fff" : "#f0f0f0")};
  padding: 8px 24px;
  cursor: pointer;
`;

// Botón para borrar el objetivo.
export const ButtonModalErase = styled.div`
  background-color: #c9f2ff;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3b63a2;
  padding: 8px 24px;
  cursor: pointer;
`;

// Botón para cancelar.
export const ButtonModalCancel = styled.div`
  background-color: #3B63A2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #f0f0f0;
  padding: 8px 24px;
  cursor: pointer;
`;
