import editObj from "../../../assets/icons/EditObj.svg"; // Icono de edición
import { useAbleFortalezasDebilidades } from "../../../hooks/FortalezasDebilidades"; // Hook para verificar permisos
import {
  EditAreasContainer,
  EditContainer,
} from "../Dashboard/stylesDashboard/AdminStyles"; // Estilos importados

// Componente EditStrengthsAndOpportunities
// Se encarga de mostrar y permitir la edición de fortalezas y oportunidades de un colaborador
const EditStrengthsAndOpportunities = ({
  setEditObjective, // Función para habilitar el modo de edición del objetivo
  index,            // Índice del elemento actual en el array de datos
  data,             // Array de datos que contienen fortalezas y oportunidades
  setIndexEdit,     // Función para establecer el índice del elemento en edición
  setIdSelect,      // Función para establecer el ID del elemento seleccionado
  inputModal,       // Estado del valor del modal (no usado directamente aquí)
  textModal,        // Texto actual del modal
  setTextModal,     // Función para actualizar el texto del modal
  setValue,         // Función para actualizar el valor del input modal
}: any) => {

  // Hook personalizado para verificar permisos de edición
  const { enablePermissionStrAndOp } = useAbleFortalezasDebilidades();

  // Obtener filtros principales del localStorage
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");

  // Obtener el año actual y el año de los filtros
  const currentYear: number = new Date().getFullYear() -1;
  const { year } = filtersParsed;

  // Función para manejar el clic en el botón de edición
  const handleClick = () => {
    if (year != currentYear.toString()){ return; }  // Evita la edición si el año no coincide con el actual

    // Extraer el texto correspondiente del elemento seleccionado
    setTextModal(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );

    // Actualizar el valor en el input del modal
    setValue(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );

    // Activar el modo de edición y establecer índices e ID
    setEditObjective(true);
    setIndexEdit(index);
    setIdSelect(index);
  };

  return (
    <>
      <EditContainer>
      <EditAreasContainer>
        {
          (data[index] &&
            data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
            data[index]?.employeeEvaluationWorkersOpportunitieAreaText ||
            "Selecciona una opción"
        }
      </EditAreasContainer>

        {!enablePermissionStrAndOp && (
          <div style={{  cursor: year == currentYear.toString() ? 'pointer' : 'default' }} onClick={() => handleClick()}>
            <img
              src={editObj}
              alt="EditStrengthsAndOpportunities"
              style={{ height: "12px", width: "12px" }}
            />
          </div>
        )}
      </EditContainer>
    </>
  );
};

export default EditStrengthsAndOpportunities;
